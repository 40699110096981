export default [
  {
    path: '/prices-management',
    name: 'pricesManagement',
    component: () => import('@/views/price-management/PricesManagement.vue'),
    meta: {
      title: 'price_management.title',
      pln: 0,
      action: 'PRICE_MANAGEMENT_VIEW',
      breadcrumb: [
        {
          text: 'action.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/price-management',
    name: 'priceManagement',
    component: () => import('@/views/price-management/PriceManagement.vue'),
    children: [
      {
        path: 'add',
        name: 'priceManagementAdd',
        meta: {
          title: 'price_management.title',
          pln: 1,
          action: 'PRICE_MANAGEMENT_ADD',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/prices-management',
            },
            {
              text: 'action.add',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: ':id/edit',
        name: 'priceManagementEdit',
        meta: {
          title: 'price_management.title',
          pln: 1,
          action: 'PRICE_MANAGEMENT_EDIT',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/prices-management',
            },
            {
              text: 'action.edit',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: ':id/duplicate',
        name: 'priceManagementDuplicate',
        meta: {
          title: 'price_management.title',
          pln: 1,
          action: 'PRICE_MANAGEMENT_ADD',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/prices-management',
            },
            {
              text: 'action.duplicate',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
    ],
  },
]
