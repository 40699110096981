export default {
  namespaced: true,
  state: {
    airportsFuel: [],
    quantityUnitOptions: [],
  },
  mutations: {
    SET_AIRPORTS_FUEL(state, airportsFuel) {
      state.airportsFuel = airportsFuel
    },
    SET_QUANTITY_UNIT_OPTIONS(state, quantityUnitOptions) {
      state.quantityUnitOptions = quantityUnitOptions
    },
    ADD_AIRPORT_FUEL: (state, airportFuel) => {
      const index = state.airportsFuel.length + 1
      state.airportsFuel.push({ ...airportFuel, id: index })
    },
    UPDATE_AIRPORT_FUEL: (state, airportFuel) => {
      const index = state.airportsFuel.findIndex((item) => item.id === airportFuel.id)
      state.airportsFuel[index] = airportFuel
    },
    REMOVE_AIRPORT_FUEL: (state, airportFuelId) => {
      const index = state.airportsFuel.findIndex((item) => item.id === airportFuelId)
      state.airportsFuel.splice(index, 1)
    },
  },
  actions: {
    fetchAirportsFuel({ commit }) {
      const response = [
        {
          id: '1',
          country: 'France',
          icao: 'UTY',
          iata: 'UTY',
          supplier: 'Total',
          startAt: '2021-10-12',
          endAt: '2021-10-13',
          vat: '10',
          quantityUnit: 'L',
          public: true,
          commercialFlights: '2.50',
          privateFlights: '5.00',
          markeUp: '0',
          airportConcession: '0',
          storage: '0',
          otherHydrantFee: '0',
          upliftCostPerUnit: '0',
          hookUpFee: '0',
        },
        {
          id: '2',
          country: 'United Kingdom',
          icao: 'UKM',
          iata: 'UKM',
          supplier: 'Shell',
          startAt: '2021-10-12',
          endAt: '2021-10-13',
          vat: '0',
          quantityUnit: 'Gal',
          public: false,
          commercialFlights: '3.00',
          privateFlights: '7.00',
          markeUp: '0',
          airportConcession: '0',
          storage: '0',
          otherHydrantFee: '0',
          upliftCostPerUnit: '0',
          hookUpFee: '0',
        },
      ]
      commit('SET_AIRPORTS_FUEL', response)
    },

    fetchQuantityUnitOptions({ commit }) {
      const response = [
        { value: 'L', text: 'L' },
        { value: 'Gal', text: 'Gal' },
      ]
      commit('SET_QUANTITY_UNIT_OPTIONS', response)
    },

    addImportationByUrl({ commit }, url) {},

    addImportationByCsv({ commit }, csv) {},

    addAirportFuel({ commit }, airportFuel) {
      commit('ADD_AIRPORT_FUEL', airportFuel)
    },
    updateAirportFuel({ commit }, airportFuel) {
      commit('UPDATE_AIRPORT_FUEL', airportFuel)
    },

    removeAirportFuel({ commit }, airportFuelId) {
      commit('REMOVE_AIRPORT_FUEL', airportFuelId)
    },
  },
}
