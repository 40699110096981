import { postClientRegisterRequest, postSellerRegisterRequest } from '@/request/authApi/requests/apiRequests'

export default {
  namespaced: true,
  state: {
    typology: null,
    user: {
      legalName: null,
      businessStructure: null,
      intracommunityVatNumber: null,
      address: {
        addressName: null,
        address: null,
        complementaryAddress: null,
        postalCode: null,
        city: null,
        contactName: null,
        contactPhone: null,
        contactEmail: null,
        additionalInformations: null,
        buildingCode: null,
        countryCode: null,
      },
      organizationAirportsIds: [],
      webSiteUrl: null,
      emergencyContactFirstName: null,
      emergencyContactLastName: null,
      emergencyContactEmail: null,
      emergencyContactPhone: null,
      emergencyContactJobTitle: null,
      recaptchaToken: null,
      clientTypologyId: null,
      numberOfAircrafts: null,
      numberOfAoc: null,
      aocCountriesCodes: [],
      mainTypologyId: null,
      otherTypologiesIds: [],
      otherSubsidiariesInterested: false,
      subsidiariesCountriesCodes: [],
      acceptPolicy: false,
    },
  },
  getters: {
    getTypology(state) {
      return state.typology
    },
    getUserLegalName(state) {
      return state.user.legalName
    },
    getUserBusinessStructure(state) {
      return state.user.businessStructure
    },
    getUserIntracommunityVatNumber(state) {
      return state.user.intracommunityVatNumber
    },
    getUserAddressName(state) {
      return state.user.addressName
    },
    getUserAddress(state) {
      return state.user.address
    },
    getUserComplementaryAddress(state) {
      return state.user.complementaryAddress
    },
    getUserPostalCode(state) {
      return state.user.postalCode
    },
    getUserCity(state) {
      return state.user.city
    },
    getUserContactName(state) {
      return state.user.contactName
    },
    getUserContactPhone(state) {
      return state.user.contactPhone
    },
    getUserContactEmail(state) {
      return state.user.contactEmail
    },
    getUserAdditionalInformations(state) {
      return state.user.additionalInformations
    },
    getUserBuildingCode(state) {
      return state.user.buildingCode
    },
    getUserCountryCode(state) {
      return state.user.countryCode
    },
    getUserOrganizationAirportsIds(state) {
      return state.user.organizationAirportsIds
    },
    getUserWebSiteUrl(state) {
      return state.user.webSiteUrl
    },
    getUserEmergencyContactFirstName(state) {
      return state.user.emergencyContactFirstName
    },
    getUserEmergencyContactLastName(state) {
      return state.user.emergencyContactLastName
    },
    getUserEmergencyContactEmail(state) {
      return state.user.emergencyContactEmail
    },
    getUserEmergencyContactPhone(state) {
      return state.user.emergencyContactPhone
    },
    getUserEmergencyContactJobTitle(state) {
      return state.user.emergencyContactJobTitle
    },
    getUserClientTypologyId(state) {
      return state.user.clientTypologyId
    },
    getRecaptchaToken(state) {
      return state.user.recaptchaToken
    },
    getUserNumberOfAircrafts(state) {
      return state.user.numberOfAircrafts
    },
    getUserNumberOfAoc(state) {
      return state.user.numberOfAoc
    },
    getUserAocCountriesCodes(state) {
      return state.user.aocCountriesCodes
    },
    getUserMainTypologyId(state) {
      return state.user.mainTypologyId
    },
    getUserOtherTypologiesIds(state) {
      return state.user.otherTypologiesIds
    },
    getUserOtherSubsidiariesInterested(state) {
      return state.user.otherSubsidiariesInterested
    },
    getUserSubsidiariesCountriesCodes(state) {
      return state.user.subsidiariesCountriesCodes
    },
    getUserAcceptPolicy(state) {
      return state.user.acceptPolicy
    },
    getUserInformations(state) {
      if (state.typology === 'seller') {
        return {
          redirectUrl: `${window.location.origin}/register-validate`,
          legalName: state.user.legalName,
          intracommunityVatNumber: state.user.intracommunityVatNumber,
          address: {
            addressName: state.user.address.addressName,
            address: state.user.address.address,
            complementaryAddress: state.user.address.complementaryAddress,
            postalCode: state.user.address.postalCode,
            city: state.user.address.city,
            contactName: state.user.address.contactName,
            contactPhone: state.user.address.contactPhone,
            contactEmail: state.user.address.contactEmail,
            additionalInformations: state.user.address.additionalInformations,
            buildingCode: state.user.address.buildingCode,
            countryCode: state.user.address.countryCode,
          },
          organizationAirportsIds: state.user.organizationAirportsIds,
          webSiteUrl: state.user.webSiteUrl,
          emergencyContactFirstName: state.user.emergencyContactFirstName,
          emergencyContactLastName: state.user.emergencyContactLastName,
          emergencyContactEmail: state.user.emergencyContactEmail,
          emergencyContactPhone: state.user.emergencyContactPhone,
          emergencyContactJobTitle: state.user.emergencyContactJobTitle,
          mainTypologyId: state.user.mainTypologyId,
          otherTypologiesIds: state.user.otherTypologiesIds,
          otherSubsidiariesInterested: state.user.otherSubsidiariesInterested,
          subsidiariesCountriesCodes: state.user.subsidiariesCountriesCodes,
          businessStructure: state.user.businessStructure,
          recaptchaToken: state.user.recaptchaToken,
        }
      }
      if (state.typology === 'buyer') {
        return {
          redirectUrl: `${window.location.origin}/register-validate`,
          legalName: state.user.legalName,
          intracommunityVatNumber: state.user.intracommunityVatNumber,
          address: {
            addressName: state.user.address.addressName,
            address: state.user.address.address,
            complementaryAddress: state.user.address.complementaryAddress,
            postalCode: state.user.address.postalCode,
            city: state.user.address.city,
            contactName: state.user.address.contactName,
            contactPhone: state.user.address.contactPhone,
            contactEmail: state.user.address.contactEmail,
            additionalInformations: state.user.address.additionalInformations,
            buildingCode: state.user.address.buildingCode,
            countryCode: state.user.address.countryCode,
          },
          organizationAirportsIds: state.user.organizationAirportsIds,
          webSiteUrl: state.user.webSiteUrl,
          emergencyContactFirstName: state.user.emergencyContactFirstName,
          emergencyContactLastName: state.user.emergencyContactLastName,
          emergencyContactEmail: state.user.emergencyContactEmail,
          emergencyContactPhone: state.user.emergencyContactPhone,
          emergencyContactJobTitle: state.user.emergencyContactJobTitle,
          clientTypologyId: state.user.clientTypologyId,
          numberOfAircrafts: state.user.numberOfAircrafts,
          numberOfAoc: state.user.numberOfAoc,
          aocCountriesCodes: state.user.aocCountriesCodes,
          recaptchaToken: state.user.recaptchaToken,
        }
      }
      return 'error'
    },
  },
  mutations: {
    SET_TYPOLOGY(state, data) {
      state.typology = data
    },
    SET_USER_LEGAL_NAME(state, data) {
      state.user.legalName = data
    },
    SET_USER_BUSINESS_STRUCTURE(state, data) {
      state.user.businessStructure = data
    },
    SET_USER_INTRACOMMUNITY_VAT_NUMBER(state, data) {
      state.user.intracommunityVatNumber = data
    },
    SET_USER_ADDRESS_NAME(state, data) {
      state.user.address.addressName = data
    },
    SET_USER_ADDRESS(state, data) {
      state.user.address.address = data
    },
    SET_USER_COMPLEMENTARY_ADDRESS(state, data) {
      state.user.address.complementaryAddress = data
    },
    SET_USER_POSTAL_CODE(state, data) {
      state.user.address.postalCode = data
    },
    SET_USER_CITY(state, data) {
      state.user.address.city = data
    },
    SET_USER_CONTACT_NAME(state, data) {
      state.user.address.contactName = data
    },
    SET_USER_CONTACT_PHONE(state, data) {
      state.user.address.contactPhone = data
    },
    SET_USER_CONTACT_EMAIL(state, data) {
      state.user.address.contactEmail = data
    },
    SET_USER_ADDITIONAL_INFORMATIONS(state, data) {
      state.user.address.additionalInformations = data
    },
    SET_USER_BUILDING_CODE(state, data) {
      state.user.address.buildingCode = data
    },
    SET_USER_COUNTRY_CODE(state, data) {
      state.user.address.countryCode = data
    },
    SET_USER_ORGANIZATION_AIRPORTS_IDS(state, data) {
      state.user.organizationAirportsIds = data
    },
    SET_USER_WEBSITE_URL(state, data) {
      state.user.webSiteUrl = data
    },
    SET_USER_EMERGENCY_CONTACT_FIRSTNAME(state, data) {
      state.user.emergencyContactFirstName = data
    },
    SET_USER_EMERGENCY_CONTACT_LASTNAME(state, data) {
      state.user.emergencyContactLastName = data
    },
    SET_USER_EMERGENCY_CONTACT_EMAIL(state, data) {
      state.user.emergencyContactEmail = data
    },
    SET_USER_EMERGENCY_CONTACT_PHONE(state, data) {
      state.user.emergencyContactPhone = data
    },
    SET_USER_EMERGENCY_JOB_TITLE(state, data) {
      state.user.emergencyContactJobTitle = data
    },
    SET_USER_CLIENT_TYPOLOGY_ID(state, data) {
      state.user.clientTypologyId = data
    },
    SET_RECAPTCHA_TOKEN(state, data) {
      state.user.recaptchaToken = data
    },
    SET_USER_NUMBER_OF_AIRCRAFTS(state, data) {
      state.user.numberOfAircrafts = data
    },
    SET_USER_NUMBER_OF_AOC(state, data) {
      state.user.numberOfAoc = data
    },
    SET_USER_AOC_COUNTRIES_CODES(state, data) {
      state.user.aocCountriesCodes = data
    },
    SET_USER_MAIN_TYPOLOGY_ID(state, data) {
      state.user.mainTypologyId = data
    },
    SET_USER_OTHER_TYPOLOGIES_IDS(state, data) {
      state.user.otherTypologiesIds = data
    },
    SET_USER_OTHER_SUBSIDIARIES_INTERESTED(state, data) {
      state.user.otherSubsidiariesInterested = data
    },
    SET_USER_SUBSIDIARIES_COUNTRIES_CODE(state, data) {
      state.user.subsidiariesCountriesCodes = data
    },
    SET_USER_ACCEPT_POLICY(state, data) {
      state.user.acceptPolicy = data
    },
  },
  actions: {
    async register({ commit, state }, payload) {
      let registerResponse
      if (state.typology === 'buyer') {
        registerResponse = await postClientRegisterRequest(payload)
      } else {
        registerResponse = await postSellerRegisterRequest(payload)
      }
      commit('SET_TYPOLOGY', null)
      commit('SET_USER_LEGAL_NAME', null)
      commit('SET_USER_BUSINESS_STRUCTURE', null)
      commit('SET_USER_INTRACOMMUNITY_VAT_NUMBER', null)
      commit('SET_USER_ADDRESS_NAME', null)
      commit('SET_USER_ADDRESS', null)
      commit('SET_USER_COMPLEMENTARY_ADDRESS', null)
      commit('SET_USER_POSTAL_CODE', null)
      commit('SET_USER_CITY', null)
      commit('SET_USER_CONTACT_NAME', null)
      commit('SET_USER_CONTACT_PHONE', null)
      commit('SET_USER_CONTACT_EMAIL', null)
      commit('SET_USER_ADDITIONAL_INFORMATIONS', null)
      commit('SET_USER_BUILDING_CODE', null)
      commit('SET_USER_COUNTRY_CODE', null)
      commit('SET_USER_ORGANIZATION_AIRPORTS_IDS', null)
      commit('SET_USER_WEBSITE_URL', null)
      commit('SET_USER_EMERGENCY_CONTACT_FIRSTNAME', null)
      commit('SET_USER_EMERGENCY_CONTACT_LASTNAME', null)
      commit('SET_USER_EMERGENCY_CONTACT_EMAIL', null)
      commit('SET_USER_EMERGENCY_CONTACT_PHONE', null)
      commit('SET_USER_EMERGENCY_JOB_TITLE', null)
      commit('SET_USER_CLIENT_TYPOLOGY_ID', null)
      commit('SET_USER_NUMBER_OF_AIRCRAFTS', null)
      commit('SET_USER_NUMBER_OF_AOC', null)
      commit('SET_USER_AOC_COUNTRIES_CODES', null)
      commit('SET_USER_MAIN_TYPOLOGY_ID', null)
      commit('SET_USER_OTHER_TYPOLOGIES_IDS', null)
      commit('SET_USER_OTHER_SUBSIDIARIES_INTERESTED', null)
      commit('SET_USER_SUBSIDIARIES_COUNTRIES_CODE', null)
      commit('SET_USER_ACCEPT_POLICY', null)
      return registerResponse
    },
    setTypology({ commit }, data) {
      commit('SET_TYPOLOGY', data)
    },
    setUserLegalName({ commit }, data) {
      commit('SET_USER_LEGAL_NAME', data)
    },
    setUserBusinessStructure({ commit }, data) {
      commit('SET_USER_BUSINESS_STRUCTURE', data)
    },
    setUserIntracommunityVatNumber({ commit }, data) {
      commit('SET_USER_INTRACOMMUNITY_VAT_NUMBER', data)
    },
    setUserAddressName({ commit }, data) {
      commit('SET_USER_ADDRESS_NAME', data)
    },
    setUserAddress({ commit }, data) {
      commit('SET_USER_ADDRESS', data)
    },
    setUserComplementaryAddress({ commit }, data) {
      commit('SET_USER_COMPLEMENTARY_ADDRESS', data)
    },
    setUserPostalCode({ commit }, data) {
      commit('SET_USER_POSTAL_CODE', data)
    },
    setUserCity({ commit }, data) {
      commit('SET_USER_CITY', data)
    },
    setUserContactName({ commit }, data) {
      commit('SET_USER_CONTACT_NAME', data)
    },
    setUserContactPhone({ commit }, data) {
      commit('SET_USER_CONTACT_PHONE', data)
    },
    setUserContactEmail({ commit }, data) {
      commit('SET_USER_CONTACT_EMAIL', data)
    },
    setUserAdditionalInformations({ commit }, data) {
      commit('SET_USER_ADDITIONAL_INFORMATIONS', data)
    },
    setUserBuildingCode({ commit }, data) {
      commit('SET_USER_BUILDING_CODE', data)
    },
    setUserCountryCode({ commit }, data) {
      commit('SET_USER_COUNTRY_CODE', data)
    },
    setUserOrganizationAirportsIds({ commit }, data) {
      commit('SET_USER_ORGANIZATION_AIRPORTS_IDS', data)
    },
    setUserWebSiteUrl({ commit }, data) {
      commit('SET_USER_WEBSITE_URL', data)
    },
    setUserEmergencyContactFirstName({ commit }, data) {
      commit('SET_USER_EMERGENCY_CONTACT_FIRSTNAME', data)
    },
    setUserEmergencyContactLastName({ commit }, data) {
      commit('SET_USER_EMERGENCY_CONTACT_LASTNAME', data)
    },
    setUserEmergencyContactEmail({ commit }, data) {
      commit('SET_USER_EMERGENCY_CONTACT_EMAIL', data)
    },
    setUserEmergencyContactPhone({ commit }, data) {
      commit('SET_USER_EMERGENCY_CONTACT_PHONE', data)
    },
    setUserEmergencyContactJobTitle({ commit }, data) {
      commit('SET_USER_EMERGENCY_JOB_TITLE', data)
    },
    setUserClientTypologyId({ commit }, data) {
      commit('SET_USER_CLIENT_TYPOLOGY_ID', data)
    },
    setRecaptchaToken({ commit }, data) {
      commit('SET_RECAPTCHA_TOKEN', data)
    },
    setUserNumberOfAircrafts({ commit }, data) {
      commit('SET_USER_NUMBER_OF_AIRCRAFTS', data)
    },
    setUserNumberOfAoc({ commit }, data) {
      commit('SET_USER_NUMBER_OF_AOC', data)
    },
    setUserAocCountriesCodes({ commit }, data) {
      commit('SET_USER_AOC_COUNTRIES_CODES', data)
    },
    setUserMainTypologyId({ commit }, data) {
      commit('SET_USER_MAIN_TYPOLOGY_ID', data)
    },
    setUserOtherTypologiesIds({ commit }, data) {
      commit('SET_USER_OTHER_TYPOLOGIES_IDS', data)
    },
    setUserOtherSubsidiariesInterested({ commit }, data) {
      commit('SET_USER_OTHER_SUBSIDIARIES_INTERESTED', data)
    },
    setUserSubsidiariesCountriesCodes({ commit }, data) {
      commit('SET_USER_SUBSIDIARIES_COUNTRIES_CODE', data)
    },
    setUserAcceptPolicy({ commit }, data) {
      commit('SET_USER_ACCEPT_POLICY', data)
    },
  },
}
