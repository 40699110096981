import globalApi from '@/request/globalApi/globalApi'
import { fetchAircraftsRequest } from '@/request/globalApi/requests/aircraftRequests'

export default {
  namespaced: true,
  state: {
    aircrafts: [],
    aircraftsLoading: false,
    aircraftsMetaData: { totalItems: 0, perPage: 12, firstPage: 1, previousPage: 1, nextPage: 1, lastPage: 1 },
    page: 1,
    name: null,
  },
  mutations: {
    SET_AIRCRAFTS: (state, aircrafts) => {
      state.aircrafts = aircrafts
    },

    SET_AIRCRAFTS_LOADING: (state, loading) => {
      state.aircraftsLoading = loading
    },

    SET_META_DATA: (state, metaData) => {
      state.aircraftsMetaData = metaData
    },

    SET_PAGE: (state, page) => {
      state.page = page
    },

    SET_NAME: (state, name) => {
      state.name = name
    },
  },
  actions: {
    fetchAircrafts({ commit, state }) {
      commit('SET_AIRCRAFTS_LOADING', true)
      const queryParams = { categoryOrder: 'asc', name: state.name, page: state.page }
      fetchAircraftsRequest(queryParams).then(({ data }) => {
        commit('SET_AIRCRAFTS', data.aircrafts)
        commit('SET_META_DATA', data)
      }).finally(() => {
        commit('SET_AIRCRAFTS_LOADING', false)
      })
    },
    // TODO: fake data is used or not?
    addAircraft(ctx, aircraftData) {
      return new Promise((resolve, reject) => {
        globalApi
          .post('/apps/aircraft/aircrafts', { aircraft: aircraftData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setPage({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setName({ commit }, name) {
      commit('SET_NAME', name)
    },
  },
}
