import offerInformation from './offerChildInformation'
import offerCommission from './offerChildCommission'
import offerSubscriber from './offerChildSubscriber'
import offerSubscription from './offerChildSubscription'
import offerTypology from './offerChildTypology'

export default [
  {
    path: '/offers',
    name: 'offers',
    component: () => import('@/views/offer/OffersList.vue'),
    meta: {
      title: 'offer.title',
      pln: 0,
      action: 'OPERATOR_SUBSCRIPTION_VIEW',
      breadcrumb: [
        {
          text: 'action.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offer/:id',
    name: 'offer',
    component: () => import('@/views/offer/OfferManager.vue'),
    children: [
      ...offerInformation,
      ...offerCommission,
      ...offerSubscriber,
      ...offerSubscription,
      ...offerTypology,
    ],
  },
]
