var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.overlay && !_vm.searchLoader,"variant":_vm.$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined}},[_c('validation-provider',{attrs:{"name":_vm.labelField,"rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(_vm.labelField ? 'b-form-group' : 'div',{tag:"component",class:[{ 'validation-required':_vm.required, 'is-invalid': errors[0] }, _vm.classGroup],attrs:{"label":_vm.labelField,"label-for":_vm.id,"label-cols":_vm.labelCols,"label-cols-lg":_vm.labelColsLg,"label-cols-md":_vm.labelColsMd,"label-cols-sm":_vm.labelColsSm,"label-cols-xl":_vm.labelColsXl}},[_c('v-select',_vm._g(_vm._b({attrs:{"value":_vm.value || '',"input-id":_vm.id,"searchable":_vm.searchable,"placeholder":_vm.localPlaceholder,"taggable":_vm.taggable,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"close-on-select":!_vm.multiple,"multiple":_vm.multiple},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),(_vm.required)?{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.value}},'input',attributes,false),events))]}}:null,(_vm.searchable)?{key:"no-options",fn:function(ref){
var search = ref.search;
return [(_vm.searchLoader)?_c('b-spinner',{attrs:{"small":""}}):(search)?[_vm._v(_vm._s(_vm.$t('common.no_results_found_for', {search: search})))]:[_vm._v(_vm._s(_vm.$t('common.no_results_found')))]]}}:null,(_vm.needPagination)?{key:"list-footer",fn:function(ref){
var search = ref.search;
return [_c('li',{staticClass:"pagination"},[_c('button',{staticClass:"w-100",attrs:{"type":"button","disabled":!_vm.previousPage},domProps:{"textContent":_vm._s(_vm.$t('common.previous'))},on:{"click":function($event){return _vm.$emit('click:previous-page', search || null)}}}),_c('button',{staticClass:"w-100",attrs:{"type":"button","disabled":!_vm.nextPage},domProps:{"textContent":_vm._s(_vm.$t('common.next'))},on:{"click":function($event){return _vm.$emit('click:next-page', search || null)}}})])]}}:null],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners)),(!_vm.toolTipErrors)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(errors[0] && _vm.toolTipErrors)?_c('b-tooltip',{attrs:{"target":_vm.id,"placement":"bottom","show":"","variant":"light"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }