const LANGUAGES = [
  {
    locale: 'en',
    img: require('@/assets/images/flags/en.png'),
    // Key used for translation (ex: Locale.vue)
    name: 'english',
  },
  {
    locale: 'fr',
    img: require('@/assets/images/flags/fr.png'),
    name: 'french',
  },
]

export default LANGUAGES
