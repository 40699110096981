export default [
  {
    path: '/accounting',
    name: 'accountingList',
    component: () => import('@/views/accounting/AccountingList.vue'),
    meta: {
      title: 'navigation.accounting',
      action: 'OPERATOR_ACCOUNTING_VIEW',
      breadcrumb: [
        {
          text: 'navigation.accounting',
          active: true,
        },
      ],
    },
  },
]
