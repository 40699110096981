import globalApi from '@/request/globalApi/globalApi'
import {
  fetchChatRequest,
  fetchChatsRequest,
  fetchUnreadMessagesRequest, patchMessageChatRequest,
} from '@/request/globalApi/requests/chatRequests'

export default {
  namespaced: true,
  state: {
    unreadMessages: 0,
    chatsContactsLoading: false,
    chatLoading: false,
    messageSending: false,
  },
  getters: {},
  mutations: {
    SET_CHATS_CONTACTS_LOADING: (state, loading) => {
      state.chatsContactsLoading = loading
    },
    SET_CHAT_LOADING: (state, loading) => {
      state.chatLoading = loading
    },
    SET_UNREAD_MESSAGES: (state, nbMessages) => {
      state.unreadMessages = nbMessages
    },
    SET_SENDING_MESSAGE: (state, sending) => {
      state.messageSending = sending
    },
  },
  actions: {
    async fetchUnreadMessages({ commit }) {
      const response = await fetchUnreadMessagesRequest()
      if (response) {
        commit('SET_UNREAD_MESSAGES', response.data.unreadMessages)
      }
    },

    async fetchChatsAndContacts({ commit }, selectedOrganization) {
      commit('SET_CHATS_CONTACTS_LOADING', true)
      const response = await fetchChatsRequest({ getAllItems: true })
      const chatsContacts = await Promise.all(response.data.chats.map(async contact => {
        const organization = (selectedOrganization.id === contact.organization1.id) ? contact.organization2 : contact.organization1
        return {
          id: organization.id,
          fullName: organization.legalName,
          chat: {
            id: contact.id,
            unseenMsgs: contact.numberOfUnreadMessages,
            lastMessage: {
              message: contact.lastMessageContent,
              time: contact.lastMessageDate,
              senderId: contact.lastMessageOrganizationId,
            },
          },
        }
      }))
      commit('SET_CHATS_CONTACTS_LOADING', false)
      return chatsContacts
    },

    getProfileUser() {
      return new Promise((resolve, reject) => {
        globalApi
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async getChat({ commit, dispatch }, chatContact) {
      commit('SET_CHAT_LOADING', true)
      const chatRequest = await fetchChatRequest(chatContact.chat.id)
      dispatch('fetchUnreadMessages')
      const chatObject = {
        chat: {
          id: chatContact.chat.id,
          userId: chatContact.id,
          unseenMsgs: chatRequest.data.numberOfUnreadMessages,
          chat: [],
        },
        contact: chatContact,
      }
      chatObject.chat.chat = chatRequest.data.messages.map(message => ({
        message: message.content,
        time: message.createdAt,
        senderId: message.createdBy.organizationId,
        senderName: message.createdBy.name,
        senderOrganizationName: message.createdBy.organizationName,
      }))
      commit('SET_CHAT_LOADING', false)
      return chatObject
    },

    async sendMessage({ commit }, { contactId, message }) {
      commit('SET_SENDING_MESSAGE', true)
      const resp = await patchMessageChatRequest(message, contactId)
      if (resp) {
        commit('SET_SENDING_MESSAGE', false)
      }
    },
  },
}
