import Swal from 'sweetalert2'
import i18n from '@/libs/i18n'

const _inputValue = ''

const _ToastVitalDelete = Swal.mixin({
  title: i18n.t('alert.delete_confirmation.title'),
  text: i18n.t('alert.delete_confirmation.message'),
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: i18n.t('alert.delete_confirmation.approve'),
  cancelButtonText: i18n.t('alert.delete_confirmation.cancel'),
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-dark ml-1',
  },
  buttonsStyling: false,
  input: 'text',
  inputLabel: i18n.t('alert.delete_vital_confirmation.input_label'),
  inputValue: _inputValue,
  inputValidator: value => {
    if (value.toLowerCase() !== 'delete') {
      return i18n.t('alert.delete_vital_confirmation.input_validator')
    }
  },
})

const _ToastDelete = Swal.mixin({
  title: i18n.t('alert.delete_confirmation.title'),
  text: i18n.t('alert.delete_confirmation.message'),
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: i18n.t('alert.delete_confirmation.approve'),
  cancelButtonText: i18n.t('alert.delete_confirmation.cancel'),
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-dark ml-1',
  },
  buttonsStyling: false,
})

const fireDeleteAlert = (vital, text) => {
  if (vital) {
    return new Promise((resolve, reject) => {
      resolve(
        _ToastVitalDelete
          .fire({ text: text || i18n.t('alert.delete_confirmation.message') })
          .then(result => result.isConfirmed),
      )
    })
  }
  return new Promise((resolve, reject) => {
    resolve(
      _ToastDelete
        .fire({ text: text || i18n.t('alert.delete_confirmation.message') })
        .then(result => result.isConfirmed),
    )
  })
}

export { fireDeleteAlert }
