export default [
  {
    path: '/attributes',
    name: 'attributes',
    component: () => import('@/views/attribute/AttributeList.vue'),
    meta: {
      title: 'navigation.attributes',
      action: 'OPERATOR_ATTRIBUTE_VIEW',
      breadcrumb: [
        {
          text: 'navigation.attributes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/attribute/add',
    name: 'attribute-add',
    component: () => import('@/views/attribute/AttributeAdd.vue'),
    meta: {
      title: 'navigation.attribute.add',
      action: 'OPERATOR_ATTRIBUTE_ADD',
      breadcrumb: [
        {
          text: 'navigation.attributes',
          to: '/attributes',
        },
        {
          text: 'navigation.attribute.add',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/attribute/edit/:attribute_id',
    name: 'attribute-edit',
    component: () => import('@/views/attribute/AttributeEdit.vue'),
    meta: {
      title: 'navigation.attribute.edit',
      action: 'OPERATOR_ATTRIBUTE_EDIT',
      breadcrumb: [
        {
          text: 'navigation.attributes',
          to: '/attributes',
        },
        {
          text: 'navigation.attribute.edit',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
]
