import { omit } from 'lodash'

import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
import globalApi from '@/request/globalApi/globalApi'

const END_POINT = 'api/organization'

export const fetchOrganizationHelicoptersRequest = (organizationId, queryParams) => globalApi.get(`${END_POINT}/${organizationId}/helicopter`, { params: queryParams })

export const fetchOrganizationHelicopterRequest = (organizationId, helicopterId) => globalApi.get(`${END_POINT}/${organizationId}/helicopter/${helicopterId}`)

export const postOrganizationHelicopterRequest = (organizationId, content) => globalApi.post(`${END_POINT}/${organizationId}/helicopter`, content)

export const patchOrganizationHelicopterRequest = (organizationId, helicopter) => globalApi.patch(`${END_POINT}/${organizationId}/helicopter/${helicopter.id}`, omit(helicopter, 'id'))

export const deleteOrganizationHelicopterRequest = (organizationId, helicopterId) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${organizationId}/helicopter/${helicopterId}`))
