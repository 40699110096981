import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  mainTypologiesChanged: false,
  offerInformationsShowed: [],
})

const defaultState = getDefaultState()

const getters = {
  getField,

  offerInformationById: state => id => {
    const offer = state.offerInformationsShowed.find(item => item.id === id)
    if (offer) return offer
    return null
  },
  offerReferenceById:
      state => ({ id, lang }) => {
        const offer = state.offerInformationsShowed.find(item => item.id === id)
        if (offer) return offer.reference[lang]
        return null
      },
}

const mutations = {
  updateField,

  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },

  SET_OFFER_INFORMATIONS_SHOWED: (state, offerInformations) => {
    state.offerInformationsShowed.push(offerInformations)
  },

  CLEAR_OFFER_INFORMATIONS_SHOWED: state => {
    state.offerInformationsShowed = []
  },

  UPDATE_OFFER_INFORMATIONS_SHOWED: (state, offerInformations) => {
    const index = state.offerInformationsShowed.findIndex(
      item => item.id === offerInformations.id,
    )
    state.offerInformationsShowed[index] = offerInformations
  },
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  setOfferInformationsShowed({ commit }, offerInformations) {
    commit('SET_OFFER_INFORMATIONS_SHOWED', offerInformations)
  },

  clearOfferInformationsShowed({ commit }) {
    commit('CLEAR_OFFER_INFORMATIONS_SHOWED')
  },

  updateOfferInformationsShowed({ commit }, offerInformations) {
    commit('UPDATE_OFFER_INFORMATIONS_SHOWED', offerInformations)
  },
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
