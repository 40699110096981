import axios from 'axios'
import config from '@/request/axiosBaseConfig'

import successHandler from '@/request/authApi/interceptors/authApiSucessInterceptor'
import errorHandler from '@/request/authApi/interceptors/authApiErrorInterceptor'

const authApi = axios.create(config)

authApi.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
)

export default authApi
