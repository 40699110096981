import mock from '@/@fake-db/mock'

const data = {
  rights: [
    {
      id: 0,
      group: 'Aircraft',
      name: 'Aircraft',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 1,
      group: 'Cancellation policy',
      name: 'Cancellation policy',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 2,
      group: 'Negotiated rates',
      name: 'Negotiated rates',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 3,
      group: 'Organization',
      name: 'Organization',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 4,
      group: 'Organization',
      name: 'Organization chat',
      level: 1,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 5,
      group: 'Organization',
      name: 'Organization account',
      level: 1,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 6,
      group: 'Organization',
      name: 'Organization aircraft',
      level: 1,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 7,
      group: 'Organization',
      name: 'Organization user',
      level: 1,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 8,
      group: 'Trip management',
      name: 'Trip management',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 9,
      group: 'Trip management',
      name: 'Trip',
      level: 1,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 10,
      group: 'Trip management',
      name: 'Trip quotation',
      level: 1,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 11,
      group: 'Trip management',
      name: 'Trip invoice',
      level: 1,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 12,
      group: 'Service',
      name: 'Service',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 13,
      group: 'Crew',
      name: 'Crew',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      id: 14,
      group: 'Passenger document',
      name: 'Passenger document',
      level: 0,
      read: false,
      write: false,
      create: false,
      delete: false,
    },
  ],
}

// ------------------------------------------------
// GET: Return rights
// ------------------------------------------------
mock.onGet('/apps/rights').reply(() => [
  200,
  {
    rights: data.rights,
  },
])
