export default [
  {
    path: '/mail-template',
    name: 'mail-template',
    component: () => import('@/views/mailTemplate/MailTemplateList.vue'),
    meta: {
      title: 'navigation.mail-template',
      action: 'OPERATOR_MAIL_VIEW',
      breadcrumb: [
        {
          text: 'navigation.mail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mail-template/:id',
    name: 'mail-template-edit',
    component: () => import('@/views/mailTemplate/MailTemplateEdit.vue'),
    meta: {
      title: 'navigation.mail_templates',
      action: 'OPERATOR_MAIL_VIEW',
      breadcrumb: [
        {
          text: 'navigation.mail_templates',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
]
