export default [
  {
    path: 'commission',
    name: 'offeCommission',
    children: [
      {
        path: 'duplicate',
        name: 'offeCommissionDuplicate',
        meta: {
          title: 'offer.title',
          pln: 1,
          action: 'OPERATOR_SUBSCRIPTION_ADD',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/offers',
            },

            {
              text: 'offer.commission.title',
              pln: 1,
              active: true,
            },
            {
              text: 'action.duplicate',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: 'show',
        name: 'offeCommissionShow',
        meta: {
          title: 'offer.title',
          pln: 1,
          action: 'OPERATOR_SUBSCRIPTION_VIEW',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/offers',
            },

            {
              text: 'offer.commission.title',
              pln: 1,
              active: true,
            },
            {
              text: 'action.show',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: 'edit',
        name: 'offeCommissionEdit',
        meta: {
          title: 'offer.title',
          pln: 1,
          action: 'OPERATOR_SUBSCRIPTION_EDIT',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/offers',
            },

            {
              text: 'offer.commission.title',
              pln: 1,
              active: true,
            },
            {
              text: 'action.edit',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
    ],
  },
]
