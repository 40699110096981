const getDefaultState = () => ({
  offerCommissionsShowed: [],
})

const defaultState = getDefaultState()

const getters = {
  offerCommissionById: state => id => {
    const offer = state.offerCommissionsShowed.find(item => item.id === id)
    if (offer) {
      return offer.commission
    }
    return null
  },
}

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  // AWAIT {id:1,commission{}}
  SET_OFFER_COMMISSIONS_SHOWED: (state, offerCommissions) => {
    state.offerCommissionsShowed.push(offerCommissions)
  },

  CLEAR_OFFER_COMMISSIONS_SHOWED: state => {
    state.offerCommissionsShowed = []
  },

  UPDATE_OFFER_COMMISSIONS_SHOWED: (state, offerCommissions) => {
    const index = state.offerCommissionsShowed.findIndex(
      item => item.id === offerCommissions.id,
    )
    state.offerCommissionsShowed[index] = offerCommissions
  },
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  // AWAIT {id:1,commission{}}
  setOfferCommissionsShowed({ commit }, offerCommissions) {
    commit('SET_OFFER_COMMISSIONS_SHOWED', offerCommissions)
  },

  clearOfferCommissionsShowed({ commit }) {
    commit('CLEAR_OFFER_COMMISSIONS_SHOWED')
  },

  updateOfferCommissionsShowed({ commit }, offerCommissions) {
    commit('UPDATE_OFFER_COMMISSIONS_SHOWED', offerCommissions)
  },
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
