export default [
  {
    path: '/invoice/:id',
    name: 'invoice',
    component: () => import('@/views/invoice/InvoiceView.vue'),
    children: [
      {
        path: 'show',
        name: 'invoiceShow',
        meta: {
          title: 'invoice.title',
          pln: 1,
          action: 'TRIP_ACCOUNTING_VIEW',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/invoice',
            },
            {
              text: 'action.show',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
    ],
  },
]
