export default [
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/product/ProductList.vue'),
    meta: {
      title: 'navigation.services',
      action: 'SERVICE_VIEW',
      breadcrumb: [
        {
          text: 'navigation.services',
          active: true,
        },
      ],
    },
  },
  {
    path: '/operator-services',
    name: 'operator_services',
    component: () => import('@/views/product/ProductList.vue'),
    meta: {
      title: 'navigation.services',
      action: 'OPERATOR_SERVICE_VIEW',
      breadcrumb: [
        {
          text: 'navigation.services',
          active: true,
        },
      ],
    },
  },
  {
    path: '/services/edit/:service_id/:typology/:tab',
    name: 'productEdit',
    component: () => import('@/views/product/ProductWizard.vue'),
    meta: {
      title: 'navigation.product.edit',
      action: 'SERVICE_EDIT',
      breadcrumb: [
        {
          text: 'navigation.services',
          to: '/services',
        },
        {
          text: 'navigation.product.edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/services/show/:service_id/:typology/:tab',
    name: 'productShow',
    component: () => import('@/views/product/ProductWizard.vue'),
    meta: {
      title: 'navigation.product.show',
      action: 'OPERATOR_SERVICE_VIEW',
      breadcrumb: [
        {
          text: 'navigation.services',
          to: '/operator-services',
        },
        {
          text: 'navigation.product.show',
          active: true,
        },
      ],
    },
  },
  {
    path: '/services/add',
    name: 'serviceAdd',
    component: () => import('@/views/product/ProductWizard.vue'),
    meta: {
      title: 'navigation.product.add',
      action: 'SERVICE_ADD',
      breadcrumb: [
        {
          text: 'navigation.product.add',
          active: true,
        },
      ],
    },
  },
]
