/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-dynamic-require */
import Vue from 'vue'

import flatpickr from 'flatpickr'

import flatPickr from 'vue-flatpickr-component'
// import i18n from '@/libs/i18n'

import '@core/scss/vue/libs/vue-flatpicker.scss'

// const { locale } = i18n

let translation = ''

const setFlatPickrLocale = locale => {
  if (locale === 'en') {
    translation = require('flatpickr/dist/l10n/default.js').default
    flatpickr.localize(translation)
    // flatPickr.set('locale', translation)
  } else {
    try {
      translation = require(`flatpickr/dist/l10n/${locale}.js`).default[locale]
      flatpickr.localize(translation)
      //   flatPickr.set('locale', translation)
    } catch (error) {
      // tolltips no trad found go to default en
    }
  }
}

flatPickr.props.config.default = () => ({
  defaultDate: new Date(),
  altInput: true,
  dateFormat: 'Y-m-dTH:i',
  altFormat: 'F j, Y H:i',
})

// flatPickr.setDefaults({
//   defaultDate: new Date(),
//   altInput: true,
//   dateFormat: 'Y-m-dTH:i',
// })

Vue.component('flat-pickr', flatPickr)

export default setFlatPickrLocale
