/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Vue from 'vue'
import { fetchCategoriesRequest } from '@/request/globalApi/requests/categoryRequests'

const _cleaningEmptyChildren = categories => {
  categories.forEach(category => {
    category.children.length
      ? _cleaningEmptyChildren(category.children)
      : delete category.children
  })
}

const _recursiveFilterActiveCategories = categories => {
  const activeCategories = categories
  function filterActiveCategories(categories) {
    categories.forEach((category, index, categories) => {
      if (category.active) {
        categories.splice(index, 1)
      }
      if (category.children) {
        filterActiveCategories(category.children)
      }
    })
  }
  filterActiveCategories(activeCategories)
  return activeCategories
}

const _recursiveFilterDepthCategories = (categories, depth) => {
  const depthCategories = categories
  function filterDepthCategories(categories) {
    categories.forEach((category, index, categories) => {
      if (category.nodeDepth >= depth) {
        delete categories[index].children
      }
      if (category.children) {
        filterDepthCategories(category.children)
      }
    })
  }
  filterDepthCategories(depthCategories)
  return depthCategories
}

export default {
  namespaced: true,
  state: {
    categoriesLoading: false,
    categories: [],
    categoriesName: [],
  },

  getters: {
    categoriesById: state => id => state[`${id}Categories`],
  },

  mutations: {
    SET_CATEGORIES_LOADING: (state, loading) => {
      state.categoriesLoading = loading
    },

    SET_CATEGORIES: (state, categories) => {
      state.categories = categories
    },

    SET_TEMP_CATEGORIES: (state, tempCategories) => {
      const tempStateName = tempCategories.synchronizer
        ? tempCategories.synchronizer
        : tempCategories.id

      Vue.set(state, tempStateName, state.categories)

      if (tempCategories.synchronizer) {
        Vue.set(state, `${tempStateName}Count`, 0)
      }

      if (tempCategories.active) {
        state[tempStateName] = _recursiveFilterActiveCategories(
          state[tempStateName],
        )
      }
      if (tempCategories.depth && tempCategories.depth > 0) {
        state[tempStateName] = _recursiveFilterDepthCategories(
          state[tempStateName],
          tempCategories.depth,
        )
      }
    },

    COUNT_TEMP_CATEGORIES_INSTANCES: (state, synchronizer) => {
      state[`${synchronizer}Count`] += 1
    },

    UPDATE_TEMP_CATEGORIES: (state, categories) => {
      state[`${categories.synchronizer}`] = categories.value
    },

    DELETE_TEMP_CATEGORIES: (state, synchronizer) => {
      state[`${synchronizer}Count`] -= 1
      if (state[`${synchronizer}Count`] === 0) {
        Vue.delete(state, synchronizer)
        Vue.delete(state, `${synchronizer}Count`)
      }
    },
  },

  actions: {
    async fetchCategories({ commit, state }, refresh) {
      if (refresh || !state.categories.length) {
        commit('SET_CATEGORIES_LOADING', true)
        await fetchCategoriesRequest()
          .then(response => {
            const cleanedCategories = response.data.categories
            _cleaningEmptyChildren(cleanedCategories)
            commit('SET_CATEGORIES', cleanedCategories)
          })
          .finally(() => {
            commit('SET_CATEGORIES_LOADING', false)
          })
      }
    },

    setTemporaryCategories({ commit }, synchronizer) {
      commit('SET_TEMP_CATEGORIES', synchronizer)
    },

    countTempCategoriesInstances({ commit }, synchronizer) {
      commit('COUNT_TEMP_CATEGORIES_INSTANCES', synchronizer)
    },

    deleteTemporaryCategories({ commit }, synchronizer) {
      commit('DELETE_TEMP_CATEGORIES', synchronizer)
    },
  },
}
