import { fetchOrganizationConfigurationTypologiesRequest } from '@/request/globalApi/requests/organizationConfigurationRequests'

export default {
  namespaced: true,
  state: { organizationTypologiesLoading: false, organizationTypologies: [] },
  getters: {
    optionableOrganizationTypologies: state => state.organizationTypologies.filter(
      typology => typology.optionable === true,
    ),
  },
  mutations: {
    SET_ORGANIZATION_CONFIGURATION_TYPOLOGIES_LOADING: (state, loading) => {
      state.organizationTypologiesLoading = loading
    },

    SET_ORGANIZATION_CONFIGURATION_TYPOLOGIES: (
      state,
      organizationTypologies,
    ) => {
      state.organizationTypologies = organizationTypologies
    },
  },
  actions: {
    fetchOrganizationConfigurationTypologies({ commit, state }) {
      if (!state.organizationTypologies.length) {
        commit('SET_ORGANIZATION_CONFIGURATION_TYPOLOGIES_LOADING', true)
        fetchOrganizationConfigurationTypologiesRequest()
          .then(response => {
            // const optionableTypologies = response.data.typologies
            // optionableTypologies.forEach((typology, index) => {
            //   !typology.optionable && optionableTypologies.splice(index, 1)
            // })
            commit(
              'SET_ORGANIZATION_CONFIGURATION_TYPOLOGIES',
              response.data.typologies,
            )
          })
          .finally(() => {
            commit('SET_ORGANIZATION_CONFIGURATION_TYPOLOGIES_LOADING', false)
          })
      }
    },
  },
}
