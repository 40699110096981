export default [
  {
    path: '/translations',
    name: 'translations',
    component: () => import('@/views/general-management/translations/TranslationsTable.vue'),
    meta: {
      title: 'navigation.translations_management',
      action: 'OPERATOR_TRANSLATION_VIEW',
      breadcrumb: [
        {
          text: 'navigation.translations_management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cache',
    name: 'cache',
    component: () => import('@/views/general-management/CacheManagement.vue'),
    meta: {
      title: 'navigation.cache_management',
      action: 'OPERATOR_PARAMETER_DELETE',
      breadcrumb: [
        {
          text: 'navigation.cache_management',
          active: true,
        },
      ],
    },
  },
]
