import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/cancellation-policy'

export const fetchCancellationPoliciesRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchCancellationPolicyRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postCancellationPolicyRequest = (cancellationPolicy, message) => globalApi.post(END_POINT, cancellationPolicy, { message })

export const patchCancellationPolicyRequest = (cancellationPolicy, message) => globalApi.patch(`${END_POINT}/${cancellationPolicy.id}`, cancellationPolicy, {
  message,
})

export const deleteCancellationPolicyRequest = (id, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }),
)
