export default [
  {
    path: '/organization-helicopter',
    name: 'organization-helicopters',
    component: () => import('@/views/organization-helicopter/OrganizationHelicopters.vue'),
    meta: {
      title: 'navigation.organization_helicopters',
      action: 'ORGANIZATION_ACCOUNT_HELICOPTER_VIEW',
      breadcrumb: [
        {
          text: 'navigation.organization_helicopters',
          active: true,
        },
      ],
    },
  },
]
