export default [
  {
    path: '/registration/:tab',
    name: 'registration',
    component: () => import('@/views/registration/Registration.vue'),
    meta: {
      title: 'navigation.register.title',
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
  {
    path: '/registration-validation',
    name: 'registration-validation',
    component: () => import('@/views/registration/tab/RegistrationValidation.vue'),
    meta: {
      title: 'navigation.register.title',
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
  {
    path: '/registration-user',
    name: 'registration-user',
    component: () => import('@/views/registration/RegistrationUser.vue'),
    meta: {
      title: 'navigation.register.title',
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
  {
    path: '/registration-user-validation',
    name: 'registration-user-validation',
    component: () => import('@/views/registration/RegistrationUserValidation.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.register.title',
      layout: 'full',
    },
  },
  {
    path: '/registration-user-valid',
    name: 'registration-user-valid',
    component: () => import('@/views/registration/RegistrationUserValid.vue'),
    meta: {
      title: 'navigation.register.title',
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
]
