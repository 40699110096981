import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/aircraft'

export const fetchAircraftsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchAircraftRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postAircraftRequest = (aircraft, message) => globalApi.post(END_POINT, aircraft, { message })

export const patchAircraftRequest = (aircraft, message) => globalApi.patch(`${END_POINT}/${aircraft.id}`, aircraft, { message })

export const deleteAircraftRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }))
