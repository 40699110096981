import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

const data = {
  workroles: [
    {
      id: 1,
      workrole: 'General Manager',
      rights: [
        'ORGANIZATION_CHAT_VIEW',
        'ORGANIZATION_CHAT_ADD',
        'ORGANIZATION_CHAT_VIEW_DELETE',
        'ORGANIZATION_ACCOUNT_VIEW',
        'ORGANIZATION_ACCOUNT_ADD',
        'ORGANIZATION_ACCOUNT_EDIT',
        'ORGANIZATION_ACCOUNT_DELETE',
      ],
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: true,
              write: true,
              create: false,
              delete: true,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
            {
              name: 'ORGANIZATION_AIRCRAFT',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
            {
              name: 'ORGANIZATION_USER',
              read: true,
              write: true,
              create: false,
              delete: false,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      workrole: 'Head of Business Unit',
      rights: [
        'TRIP_DELETE',
        'TRIP_EDIT',
        'TRIP_QUOTATION_VIEW',
        'TRIP_QUOTATION_ADD',
        'TRIP_QUOTATION_DELETE',
        'TRIP_QUOTATION_EDIT',
        'TEST_VIEW',
      ],
      groups: [
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: true,
              create: false,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],

        },
      ],
    },
    {
      id: 4,
      workrole: 'CTO',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: true,
              write: false,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_USER',
              read: false,
              write: false,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: false,
              delete: true,
            },
          ],
        },
        {
          name: 'AIRPORT',
          rights: [
            {
              name: 'AIRPORT',
              read: true,
              write: true,
              create: false,
              delete: false,
            },
          ],
        },
        {
          name: 'QUOTE',
          rights: [
            {
              name: 'QUOTE_CLAIM',
              read: true,
              write: true,
              create: false,
              delete: true,
            },
            {
              name: 'QUOTE_PAID',
              read: true,
              write: true,
              create: false,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 5,
      workrole: 'Head of Sales',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 6,
      workrole: 'Sales Manager',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 7,
      workrole: 'Customer account manager',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 8,
      workrole: 'Business Development',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 9,
      workrole: 'Purchasing & Supply contract manager',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 10,
      workrole: 'Head of Operations Control',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 11,
      workrole: 'Dispatcher',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
    {
      id: 12,
      workrole: 'Fuel Operations',
      groups: [
        {
          name: 'ORGANIZATION',
          rights: [
            {
              name: 'ORGANIZATION_CHAT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
            {
              name: 'ORGANIZATION_ACCOUNT',
              read: false,
              write: true,
              create: true,
              delete: false,
            },
          ],
        },
        {
          name: 'TRIP_MANAGEMENT',
          rights: [
            {
              name: 'TRIP',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_QUOTATION',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
            {
              name: 'TRIP_INVOICE',
              read: true,
              write: false,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'SERVICE',
          rights: [
            {
              name: 'SERVICE',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
        {
          name: 'CREW',
          rights: [
            {
              name: 'CREW',
              read: true,
              write: true,
              create: true,
              delete: true,
            },
          ],
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Workroles
// ------------------------------------------------
mock.onGet('/apps/workroles').reply(config => {
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
  } = config.params

  const queryLowered = q.toLowerCase()
  const filteredData = data.workroles.filter(
    workrole => workrole.workrole.toLowerCase().includes(queryLowered),
  )

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      workroles: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})
