import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/negotiated-rate'

export const fetchNegotiatedRatesRequest = params => globalApi.get(END_POINT, { params })

export const fetchNegotiatedRateRequest = negotiatedRateId => globalApi.get(`${END_POINT}/${negotiatedRateId}`)

export const postNegotiatedRateRequest = (negotiatedRate, message) => globalApi.post(END_POINT, negotiatedRate, { message })

export const patchNegotiatedRateRequest = (negotiatedRate, message) => globalApi.patch(`${END_POINT}/${negotiatedRate.id}`, negotiatedRate, { message })

export const deleteNegotiatedRateRequest = (negotiatedRateId, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${negotiatedRateId}`, { message }))
