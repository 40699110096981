<template>
  <app-select
    :label-field="label"
    :value="value || ''"
    :options="options"
    :label="options[0] && options[0].text ? 'text' : 'value'"
    :reduce="reduce ? reduce : objectFormat ? val => val : val => val.value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="{ ...slotData, translatedText: getOptionLabel(slotData) }" />
    </template>

    <template slot="selected-option" slot-scope="slotData">
      <slot name="selected-option" v-bind="{ ...slotData, translatedText: getOptionLabel(slotData) }">
        {{ getOptionLabel(slotData) }}
      </slot>
    </template>

    <template slot="option" slot-scope="slotData">
      <slot name="option" v-bind="{ ...slotData, translatedText: getOptionLabel(slotData) }">
        {{ getOptionLabel(slotData) }}
      </slot>
    </template>
  </app-select>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { snakeCase } from 'lodash'

import AppSelect from '@/components/AppSelect.vue'

/**
 * For enum no translated on API, create the trad in enum[snakeCase(enumClass)][value]
 *
 * @example
 * <enum-select
 *   id="pricingType"
 *   v-model="product.mainVariant.pricingType"
 *   :label="$t('service.prices.type')"
 *   enum-class="PricingType"
 *   :overlay="!isLoaded"
 *   required
 * />
 *
 * It target the trad in enum.pricing_type[value]
 */

export default defineComponent({
  name: 'EnumSelect',

  components: {
    AppSelect,
  },

  props: {
    value: {
      type: [String, Object, Array, Number],
      default: '',
    },
    /**
     * The enumClass asked by API
     */
    enumClass: {
      type: String,
      required: true,
    },
    /**
     * The select label
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Override text transformation
     */
    transformText: {
      type: Function,
      default: null,
    },
    /**
     * Override text transformation
     */
    text: {
      type: String,
      default: 'value',
    },
    /**
     * If true, return the enum object. By default, return it value only
     */
    objectFormat: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: undefined,
    },
  },

  data() {
    return {
      localOverlay: true,
    }
  },

  computed: {
    /**
     * Return the store's enumClass or an empty array
     */
    options() {
      const enumClass = this.$store.state.enum[this.enumClass]
      if (enumClass && enumClass.length) {
        if (typeof enumClass[0].value === 'object') {
          return enumClass.map(item => item.value)
        }
        // For string or for FuelType format (value is a number)
        return enumClass
      }
      return []
    },
  },

  mounted() {
    // Once request even if others <enum-select are mounted in same time
    if (!this.$store.state.enum[`${this.enumClass}Loading`]) {
      this.fetchEnum2(this.enumClass).finally(() => {
        this.localOverlay = false
      })
    } else {
      this.localOverlay = false
    }
  },

  methods: {
    ...mapActions('enum', ['fetchEnum2']),

    /**
     * Render the translation value if available
     */
    getOptionLabel(currentEnum) {
      // TODO: for optimization, if trans is already loaded, return it speedly
      const translation = (text, snakeCaseText = false) => {
        /* There is no translation for this text on API */
        // Override the transformation of the render text
        if (this.transformText) {
          return this.transformText(text)
        }

        // If translation key exists on vue-i18n, return this translation
        if (this.$te(`enum.${snakeCase(this.enumClass)}.${!snakeCaseText ? text : snakeCase(text)}`)) {
          return this.$t(`enum.${snakeCase(this.enumClass)}.${!snakeCaseText ? text : snakeCase(text)}`)
        }
        // Else, return the brut text
        return text
      }

      // Classic enum, without translation api. Automatic translation if no `transformText()` prop
      if (typeof currentEnum[this.text] === 'string') {
        return translation(currentEnum[this.text])
      }
      // Only for 'FuelType' | Format: { value: 3, text: 'Coffee' }
      if (typeof currentEnum[this.text] === 'number') {
        return translation(currentEnum.text, true)
      }
      // Translated enum on API. return `transformText(translatedApiText)` or `translatedApiText`
      if (currentEnum.id) {
        const target = this.$options.filters.trans(currentEnum.name)
        if (this.transformText) return this.transformText(target)
        return target
      }
      // Default return, but never used in good utilisation
      return ''
    },
  },
})
</script>

<style lang="scss">
body:not(body.dark-layout) .vs__search::placeholder {
  color: rgba(110, 107, 123, 0.4) !important;
}
</style>
