const getDefaultState = () => ({
  offerSubscribersShowed: [],
})

const defaultState = getDefaultState()

const getters = {
  offerSubscribersById: state => id => {
    const offer = state.offerSubscribersShowed.find(item => item.id === id)
    if (offer) {
      return offer.subscribers
    }
    return null
  },
}

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  // AWAIT {id:1,subscribers[]}
  SET_OFFER_SUBSCRIBERS_SHOWED: (state, offerSubscribers) => {
    state.offerSubscribersShowed.push(offerSubscribers)
  },

  CLEAR_OFFER_SUBSCRIBERS_SHOWED: state => {
    state.offerSubscribersShowed = []
  },

  UPDATE_OFFER_SUBSCRIBERS_SHOWED: (state, offerSubscribers) => {
    const index = state.offerSubscribersShowed.findIndex(item => item.id === offerSubscribers.id)
    state.offerSubscribersShowed[index] = offerSubscribers
  },
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  // AWAIT {id:1,subscribers[]}
  setOfferSubscribersShowed({ commit }, offerSubscribers) {
    commit('SET_OFFER_SUBSCRIBERS_SHOWED', offerSubscribers)
  },

  clearOfferSubscribersShowed({ commit }) {
    commit('CLEAR_OFFER_SUBSCRIBERS_SHOWED')
  },

  updateOfferSubscribersShowed({ commit }, offerSubscribers) {
    commit('UPDATE_OFFER_SUBSCRIBERS_SHOWED', offerSubscribers)
  },
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
