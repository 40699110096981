import { $themeConfig } from '@themeConfig'
import LANGUAGES from '@/constants/language'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
    },

    // Dupliqué de la langue utilisateur
    lang: localStorage.getItem('lang') || $themeConfig.user.language,
    avaliableLanguages: LANGUAGES,

    currency: localStorage.getItem('currency') || $themeConfig.user.currency,

    measurement: localStorage.getItem('measurement') || $themeConfig.user.measurement,

    dataTable: {
      parPageOption: [5, 10, 25, 50],
      perPage: 10,
    },
  },
  getters: {},
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin
      localStorage.setItem('skin', skin)
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_LANG(state, lang) {
      localStorage.setItem('lang', lang)
      state.lang = lang
    },
    UPDATE_AVALIABLE_LANGUAGES(state, avaliableLanguages) {
      state.avaliableLanguages = avaliableLanguages
    },
    UPDATE_CURRENCY(state, currency) {
      localStorage.setItem('currency', currency)
      state.currency = currency
    },
    UPDATE_MEASUREMENT(state, measurement) {
      localStorage.setItem('measurement', measurement)
      state.measurement = measurement
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_DATA_TABLE_PER_PAGE_OPTIONS(state, val) {
      state.dataTable.parPageOption = val
    },
    UPDATE_DATA_TABLE_PER_PAGE(state, val) {
      state.dataTable.perPage = val
    },
  },
  actions: {},
}
