import mock from '@/@fake-db/mock'
// eslint-disable-next-line object-curly-newline
import { paginateArray, sortCompare, randomDate, getRandomInt } from '@/@fake-db/utils'

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000)

const data = {
  products: [
    {
      id: 100,
      name: {
        fr: 'Hotel booking',
        en: 'Hotel booking',
      },
      slug: 'vip-air-handling-46',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Booking request based on hotel list proposal',
        en: 'Booking request based on hotel list proposal',
      },
      price: 70,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://www.rheinmetall-defence.com/media/editor_media/rm_defence/produktbilder/air_start_units/MSU-Trailer_text_landscape_new.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft assistance technical equipment',
          en: 'Aircraft assistance technical equipment',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'hour',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            priceOnQuote: true,
            prices: [],
            extendedData: [],
          },
    },
    {
      id: 96,
      name: {
        fr: 'Airstart unit (ASU)',
        en: 'Airstart unit (ASU)',
      },
      slug: 'vip-air-handling-46',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Ground Power Unit rental per 30min',
        en: 'Ground Power Unit rental per 30min',
      },
      price: 70,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://www.rheinmetall-defence.com/media/editor_media/rm_defence/produktbilder/air_start_units/MSU-Trailer_text_landscape_new.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft assistance technical equipment',
          en: 'Aircraft assistance technical equipment',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'hour',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 58.33,
                vatRate: 20,
                sellingPriceIncludingTaxes: 70,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 94,
      name: {
        fr: 'Airconditioning unit (ACU)',
        en: 'Airconditioning unit (ACU)',
      },
      slug: 'vip-air-handling-44',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Ground Power Unit rental per 30min',
        en: 'Ground Power Unit rental per 30min',
      },
      price: 80,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://img.aeroexpo.online/images_ar/photo-m2/168785-10456456.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft assistance technical equipment',
          en: 'Aircraft assistance technical equipment',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'hour',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 66.66,
                vatRate: 20,
                sellingPriceIncludingTaxes: 80,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 92,
      name: {
        fr: 'GPU',
        en: 'GPU',
      },
      slug: 'vip-air-handling-42',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Ground Power Unit rental per 30min',
        en: 'Ground Power Unit rental per 30min',
      },
      price: 100,
      fees: null,
      status: 'inactive',
      active: false,
      image: 'https://www.airport-suppliers.com/wp-content/uploads/2019/01/Bertoli-GPU-Product-main-image.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft assistance technical equipment',
          en: 'Aircraft assistance technical equipment',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Rejected',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'hour',
            pricingType: 'price_per_slice',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 80,
                vatRate: 20,
                sellingPriceIncludingTaxes: 100,
                ecologicalTax: null,
                packingQuantity: 1,
              },
              {
                id: 2,
                fromQuantity: 1,
                toQuantity: 2,
                sellingPriceExcludingTaxes: 180,
                vatRate: 20,
                sellingPriceIncludingTaxes: 200,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 90,
      name: {
        fr: 'Ice',
        en: 'Ice',
      },
      slug: 'vip-air-handling-40',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Ice packed in 1l bags',
        en: 'Ice packed in 1l bags',
      },
      price: 10,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://w7.pngwing.com/pngs/632/950/png-transparent-pack-of-ice-cubes-ice-packs-bag-ice-makers-restaurant-ice-cubes-retail-refrigeration-industry.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Catering',
          en: 'Catering',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingOnQuote: true,
            pricingMeasurement: 'bag',
            pricingType: 'price_per_slice',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 10,
                vatRate: 20,
                sellingPriceIncludingTaxes: 10,
                ecologicalTax: null,
                packingQuantity: 1,
              },
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 28,
                vatRate: 20,
                sellingPriceIncludingTaxes: 20,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 88,
      name: {
        fr: 'Hot water',
        en: 'Hot water',
      },
      slug: 'vip-air-handling-38',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Still water per litre',
        en: 'Still water per litre',
      },
      price: 5,
      fees: null,
      status: 'inactive',
      active: false,
      image: 'https://femina.wwmindia.com/content/2020/may/hotwater21589437480.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Catering',
          en: 'Catering',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'litre',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 3,
                vatRate: 20,
                sellingPriceIncludingTaxes: 4.1,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 87,
      name: {
        fr: 'Catering',
        en: 'Catering',
      },
      slug: 'you-fly-handling-37',
      provider: {
        id: 4,
        name: 'You Fly Handling',
      },
      description: {
        fr: 'Meals order and delivered on board',
        en: 'Meals order and delivered on board',
      },
      price: null,
      fees: null,
      status: 'inactive',
      active: false,
      image: 'https://post.healthline.com/wp-content/uploads/2020/09/Airplane_Food_Tray-732x549-Thumbnail-732x549.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Catering',
          en: 'Catering',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'passenger',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 3983.24,
                vatRate: 20,
                sellingPriceIncludingTaxes: 4764.75,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 84,
      name: {
        fr: 'Coffee',
        en: 'Coffee',
      },
      slug: 'vip-air-handling-34',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Coffer per litre ',
        en: 'Coffer per litre ',
      },
      price: 10,
      fees: null,
      status: 'Pending',
      active: false,
      image: 'https://upload.wikimedia.org/wikipedia/commons/4/45/A_small_cup_of_coffee.JPG',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Catering',
          en: 'Catering',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Rejected',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingType: 'price_per_unity',
            pricingMeasurement: 'litre',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 8,
                vatRate: 20,
                sellingPriceIncludingTaxes: 10,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 83,
      name: {
        fr: 'Exterior cleaning',
        en: 'Exterior cleaning',
      },
      slug: 'you-fly-handling-33',
      provider: {
        id: 4,
        name: 'You Fly Handling',
      },
      description: {
        fr: 'Fuselage, wings and landing gear',
        en: 'Fuselage, wings and landing gear',
      },
      price: null,
      fees: null,
      status: 'Pending',
      active: false,
      image: 'https://jetwash.aero/wp-content/uploads/2017/06/privatejet1.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft cleaning',
          en: 'Aircraft cleaning',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'passengers',
            pricingType: 'price_per_unity',
            priceOnQuote: true,
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [],
            extendedData: [],
          },
    },
    {
      id: 80,
      name: {
        fr: 'Lavatory & wastewater',
        en: 'Lavatory & wastewater',
      },
      slug: 'vip-air-handling-30',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Toilets and water change',
        en: 'Toilets and water change',
      },
      price: 150,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://thepointsguy.global.ssl.fastly.net/us/originals/2018/01/everything-you-need-to-know-about-plane-toilets.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft cleaning',
          en: 'Aircraft cleaning',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'MTOW',
            pricingType: 'price_per_lot',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 3983.24,
                vatRate: 19.62,
                sellingPriceIncludingTaxes: 4764.75,
                ecologicalTax: null,
                packingQuantity: 1,
              },
              {
                id: 2,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 5974.86,
                vatRate: 19.62,
                sellingPriceIncludingTaxes: 7147.13,
                ecologicalTax: null,
                packingQuantity: 10,
              },
              {
                id: 3,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 8962.29,
                vatRate: 19.62,
                sellingPriceIncludingTaxes: 10720.69,
                ecologicalTax: null,
                packingQuantity: 20,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 74,
      name: {
        fr: 'Interior disinfection',
        en: 'Interior disinfection',
      },
      slug: 'vip-air-handling-24',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'COVID compliance disinfection',
        en: 'COVID compliance disinfection',
      },
      price: 100,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://www.aircraftinteriorsinternational.com/wp-content/uploads/2020/04/wizz-air-cleaning.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft cleaning',
          en: 'Aircraft cleaning',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'event',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 80,
                vatRate: 20,
                sellingPriceIncludingTaxes: 100,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 68,
      name: {
        fr: 'Interior cleaning',
        en: 'Interior cleaning',
      },
      slug: 'vip-air-handling-28',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Full interior cleaning: seats, tables, waste, floor',
        en: 'Full interior cleaning: seats, tables, waste, floor',
      },
      price: 200,
      fees: null,
      status: 'Pending',
      active: false,
      image: 'https://cdn.hswstatic.com/gif/plane-clean.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft cleaning',
          en: 'Aircraft cleaning',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'event',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 7.5,
                vatRate: 20,
                sellingPriceIncludingTaxes: 9,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 66,
      name: {
        fr: 'Dishwashing',
        en: 'Dishwashing',
      },
      slug: 'vip-air-handling-26',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Container unload, wash and delivery on board',
        en: 'Container unload, wash and delivery on board',
      },
      price: 60,
      fees: null,
      status: 'Pending',
      active: false,
      image: 'https://aircraft-completion.com/wp-content/uploads/DishwashAir3.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Aircraft cleaning',
          en: 'Aircraft cleaning',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'per_load',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 50,
                vatRate: 20,
                sellingPriceIncludingTaxes: 60,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 62,
      name: {
        fr: 'Towing & push back',
        en: 'Towing & push back',
      },
      slug: 'vip-air-handling-22',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Tractage et push back',
        en: 'Tractage et push back',
      },
      price: 100,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://www.hydro.aero/assets/images/3/HYDRO_Tow-bar_validation-8d660d04.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Gound Handling',
          en: 'Gound Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'MTOW',
            pricingType: 'price_per_lot',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 80,
                vatRate: 20,
                sellingPriceIncludingTaxes: 100,
                ecologicalTax: null,
                packingQuantity: 5,
              },
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 800,
                vatRate: 20,
                sellingPriceIncludingTaxes: 1000,
                ecologicalTax: null,
                packingQuantity: 10,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 61,
      name: {
        fr: 'Airport assistance agent',
        en: 'Airport assistance agent',
      },
      slug: 'vip-air-handling-21',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Special assistance at request',
        en: 'Special assistance at request',
      },
      price: 60,
      fees: null,
      status: 'Pending',
      active: false,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3bm6CYRt9c9JZe3yF9fHGQi3j3Wyv0lV3pw&usqp=CAU',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Gound Handling',
          en: 'Gound Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'hour',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 50,
                vatRate: 20,
                sellingPriceIncludingTaxes: 60,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 59,
      name: {
        fr: 'De-icing',
        en: 'De-icing',
      },
      slug: 'vip-air-handling-19',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Aircraft de-icing',
        en: 'Aircraft de-icing',
      },
      price: 300,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://www.aircanada.com/content/dam/aircanada/portal/images/media/editorials/editorials/de-icing.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Gound Handling',
          en: 'Gound Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'MTOW',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 250,
                vatRate: 20,
                sellingPriceIncludingTaxes: 300,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 57,
      name: {
        fr: 'Engine protection',
        en: 'Engine protection',
      },
      slug: 'vip-air-handling-17',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Engine covers set up to protect during the stay',
        en: 'Engine covers set up to protect during the stay',
      },
      price: 280,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://images.squarespace-cdn.com/content/v1/52ff9ecee4b0e37a19ad79dc/1423534011047-KEOOCBB1HPV3Y2OW4WBD/KMP005small.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Gound Handling',
          en: 'Gound Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'event',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 233.33,
                vatRate: 20,
                sellingPriceIncludingTaxes: 280,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 55,
      name: {
        fr: 'VIP vehicle to terminal',
        en: 'VIP vehicle to terminal',
      },
      slug: 'vip-air-handling-15',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Luxury car for passenger transfer (per event)',
        en: 'Luxury car for passenger transfer (per event)',
      },
      price: 50,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://i2-prod.getreading.co.uk/incoming/article21243521.ece/ALTERNATES/s1200c/0_Screenshot-2021-08-06-at-120020.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Gound Handling',
          en: 'Gound Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'event',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 250,
                vatRate: 20,
                sellingPriceIncludingTaxes: 300,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 53,
      name: {
        fr: 'Disabled PAX assistance',
        en: 'Disabled PAX assistance',
      },
      slug: 'vip-air-handling-13',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'wheel chair and assistance agent',
        en: 'wheel chair and assistance agent',
      },
      price: 50,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://upgradedpoints.com/wp-content/uploads/2019/04/disabled-passenger-in-the-airport.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Gound Handling',
          en: 'Gound Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Rejected',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'passengers',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 50,
                vatRate: 20,
                sellingPriceIncludingTaxes: 60,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 52,
      name: {
        fr: 'PPR change',
        en: 'PPR change',
      },
      slug: 'vip-air-handling-11',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Arrival & departure time booking at airport per movement',
        en: 'Arrival & departure time booking at airport per movement',
      },
      price: 20,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://links.bu.ac.th/wp-content/uploads/2020/06/form.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Ground Handling',
          en: 'Ground Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingMeasurement: 'event',
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 16.66,
                vatRate: 20,
                sellingPriceIncludingTaxes: 20,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 51,
      name: {
        fr: 'Prior Permission Request',
        en: 'Prior Permission Request',
      },
      slug: 'vip-air-handling-9',
      provider: {
        id: 4,
        name: 'VIP Air Handling',
      },
      description: {
        fr: 'Arrival & departure time booking at airport per movement',
        en: 'Arrival & departure time booking at airport per movement',
      },
      price: 40,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://links.bu.ac.th/wp-content/uploads/2020/06/form.png',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Ground Handling',
          en: 'Ground Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant:
          {
            id: 1,
            description: 'Mr. Rocio Sawayn',
            seoMetaTitle: null,
            seoMetaDescription: null,
            stockQuantity: null,
            reference: 'Missouri Bradtke',
            internalReference: 'Mr. Deontae Schultz PhD',
            pcb: null,
            minimumOrderQuantity: null,
            stockAlert: null,
            rateOptionMandatory: false,
            rateOptionMultiple: false,
            recommendedRetailPrice: null,
            discountedPrice: false,
            discountedType: null,
            discountedPriceStartAt: null,
            discountedPriceEndAt: null,
            pricingType: 'price_per_unity',
            attributeValues: [],
            visibilityStartAt: '2021-08-25T00:00:00+00:00',
            visibilityEndAt: '1981-12-03T00:00:00+00:00',
            files: [],
            pricingMeasurement: 'event',
            prices: [
              {
                id: 1,
                fromQuantity: 0,
                toQuantity: 1,
                sellingPriceExcludingTaxes: 33.33,
                vatRate: 20,
                sellingPriceIncludingTaxes: 40,
                ecologicalTax: null,
                packingQuantity: 1,
              },
            ],
            extendedData: [],
          },
    },
    {
      id: 50,
      name: {
        fr: 'Ramp handling',
        en: 'Ramp handling',
      },
      slug: 'you-fly-handling-8',
      provider: {
        id: 4,
        name: 'You Fly Handling',
      },
      description: {
        fr: 'Airplane compulsory take in charge: marshalling, towing, luggage, customs, transfer to terminal per movement',
        en: 'Airplane compulsory take in charge: marshalling, towing, luggage, customs, transfer to terminal per movement',
      },
      price: 650,
      fees: null,
      status: 'active',
      active: true,
      image: 'https://files.indcareer.com/files/Diploma%20in%20Ramp%20Handling%20.jpg?q=files/Diploma%20in%20Ramp%20Handling%20.jpg',
      type: 'service',
      mode: 'noCalendar',
      mainCategory: {
        id: 17,
        name: {
          fr: 'Ground Handling',
          en: 'Ground Handling',
        },
        active: false,
        nodeDepth: 3,
        iconFilePath: null,
        iconFileUrl: null,
        slugs: [
          [],
        ],
        oldSlugs: [],
        children: [],
      },
      isInWishlist: false,
      isInCart: false,
      state: 'Accepted',
      extendedData: {
        fuel: [
          {
            id: 1,
            airport: {
              id: 31,
              name: 'Albertha Christiansen',
            },
            pricingType: 'commercial',
            fuelCertificateOption: {
              id: 1,
              paymentTypeOption: true,
              certificateNumberOption: false,
              dateOption: false,
              beginTimeOfFuelingOption: true,
              endTimeOfFuelingOption: false,
              aircraftRegistrationOption: false,
              fuelTruckNumberOption: false,
              fuelTypeOption: false,
              amountInLitersOption: true,
              fuelDensityOption: true,
              fuelTemperatureOption: true,
              calculatedNetLitersAt15DegreesCelsiusOption: false,
              fuelingTaxRelevantOrNotOption: true,
              invoiceNumberOption: false,
              providerOption: true,
              clientOption: true,
            },
            importUrl: null,
          },
        ],
        helicopter: [
          {
            id: 1,
            visibilityStartAt: '2021-02-09T00:00:00+00:00',
            visibilityEndAt: '2022-12-15T00:00:00+00:00',
            priceOnQuote: false,
            linesWithRates: false,
            localizationRequirement: 'gps_coordinates_is_required',
            helicopterVariantRegularLines: [],
          },
        ],
      },
      dateStart: '2021-02-09T00:00:00+00:00',
      dateEnd: '2022-12-15T00:00:00+00:00',
      organization: {
        id: 7,
        legalName: 'Otto Schmeler',
        intracommunityVatNumber: 'BD46356778567',
        commercialName: null,
        description: null,
        slogan: null,
        motto: null,
        websiteUrl: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        status: 'active',
        invoicePrefix: 'jcg_',
        invoiceActive: false,
        emergencyContactName: 'Dakota Crooks',
        emergencyContactEmail: 'henderson06@gmail.com',
        emergencyContactPhone: '+3312075349681',
        accountingContactName: null,
        accountingContactPhone: null,
        accountingContactEmail: null,
        feesCollector: false,
        siret: null,
        ape: null,
        shareCapital: null,
        rcs: null,
        invoiceMandatePath: null,
        cgvPath: null,
        defaultLang: [],
        defaultCurrency: [],
        defaultAddress: [],
        logoFilePath: null,
        bannerFilePath: null,
        invoiceMandateUrl: null,
        cgvUrl: null,
        logoFileUrl: null,
        bannerFileUrl: null,
      },
      langs: [],
      mainVariant: {
        id: 1,
        description: 'Mr. Rocio Sawayn',
        seoMetaTitle: null,
        seoMetaDescription: null,
        stockQuantity: null,
        reference: 'Missouri Bradtke',
        internalReference: 'Mr. Deontae Schultz PhD',
        pcb: null,
        minimumOrderQuantity: null,
        stockAlert: null,
        rateOptionMandatory: false,
        rateOptionMultiple: false,
        recommendedRetailPrice: null,
        discountedPrice: false,
        discountedType: null,
        discountedPriceStartAt: null,
        discountedPriceEndAt: null,
        pricingMeasurement: 'MTOW',
        pricingType: 'price_per_unity',
        attributeValues: [],
        visibilityStartAt: '2021-08-25T00:00:00+00:00',
        visibilityEndAt: '1981-12-03T00:00:00+00:00',
        files: [],
        prices: [
          {
            id: 1,
            fromQuantity: 0,
            toQuantity: 1,
            sellingPriceExcludingTaxes: 500,
            vatRate: 20,
            sellingPriceIncludingTaxes: 600,
            ecologicalTax: null,
            packingQuantity: 1,
          },
        ],
        extendedData: [],
      },
    },
    {
      id: 1,
      name: { fr: 'Dauphin H 155', en: 'Dauphin H 155' },
      passengers: 6,
      luggages: 10,
      speed: 280,
      slug: 'heliexpress-1',
      provider: {
        id: 6,
        name: 'HeliExpress',
      },
      price: null,
      fees: null,
      type: 'transfer',
      image: 'https://helisecurite.fr/wp-content/uploads/2020/12/Helicoptere-Dauphin-Airbus-H-155-Summer.jpg',
    },
    {
      id: 2,
      name: { fr: 'BELL 429', en: 'BELL 429' },
      passengers: 6,
      luggages: 8,
      speed: 250,
      slug: 'helitransfer-2',
      provider: {
        id: 8,
        name: 'Helitransfer',
      },
      price: null,
      fees: null,
      type: 'transfer',
      image: 'https://helisecurite.fr/wp-content/uploads/2020/12/Helicoptere-Bell-429-Summer.jpg',
    },
    {
      id: 3,
      name: { fr: 'H 135 T3 H', en: 'H 135 T3 H' },
      passengers: 5,
      luggages: 3,
      speed: 260,
      slug: 'helitransfer-3',
      provider: {
        id: 8,
        name: 'Helitransfer',
      },
      price: null,
      fees: null,
      type: 'transfer',
      image: 'https://helisecurite.fr/wp-content/uploads/2021/03/Helicoptere-Airbus-H-135-T3-Summer-Small-1.jpg',
    },
    {
      id: 4,
      name: { fr: 'A 109 Grand New', en: 'A 109 Grand New' },
      passengers: 6,
      luggages: 3,
      speed: 289,
      slug: 'heliexpress-4',
      provider: {
        id: 6,
        name: 'HeliExpress',
      },
      price: null,
      fees: null,
      type: 'transfer',
      image: 'https://www.leonardocompany.com/o/adaptive-media/image/5640607/h_959/Hero%20banner_AW109%20GrandNew.jpg',
    },
    {
      id: 5,
      name: { fr: 'AS 355', en: 'AS 355' },
      passengers: 5,
      luggages: 2,
      speed: 220,
      slug: 'heliexpress-5',
      provider: {
        id: 6,
        name: 'HeliExpress',
      },
      price: null,
      fees: null,
      type: 'transfer',
      image: 'https://helisecurite.fr/wp-content/uploads/2021/03/Helicoptere-Airbus-AS-355-Summer.jpg',
    },
    {
      id: 6,
      name: { fr: 'H 125', en: 'H 125' },
      passengers: 5,
      luggages: 3,
      speed: 230,
      slug: 'helitransfer-6',
      provider: {
        id: 8,
        name: 'Helitransfer',
      },
      price: null,
      fees: null,
      type: 'transfer',
      image: 'https://helisecurite.fr/wp-content/uploads/2021/03/Helicoptere-Airbus-H-125-Summer-Small-1.jpg',
    },
  ],
  userWishlist: [],
  userCart: [],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/products').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', sortBy = 'featured', perPage = 9, page = 1, perType = '', providerId = null } = config.params

  const queryLowered = q.toLowerCase()

  const filteredData = data.products
    .filter(product => product.name.en.toLowerCase().includes(queryLowered)
          && (perType === '' || product.type === perType)
          && (providerId === null || (product.provider.id === providerId)))

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'price-desc') {
      sortDesc = true
      return 'price'
    }
    if (sortBy === 'price-asc') {
      return 'price'
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  paginatedData.forEach(product => {
    /* eslint-disable no-param-reassign */
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1
    /* eslint-enable */
  })

  return [
    200,
    {
      products: paginatedData,
      total: filteredData.length,
      userWishlist: data.userWishlist,
      userCart: data.userCart,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/ecommerce\/products\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)
  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.products.findIndex(p => p.id === productId)
  const product = data.products[productIndex]

  if (product) {
    // Add data of wishlist and cart
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1

    return [200, { product }]
  }
  return [404]
})

// ------------------------------------------------
// GET: Return Wishlist Products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/wishlist').reply(() => {
  const products = data.userWishlist.map(wishlistProduct => {
    const product = data.products.find(p => p.id === wishlistProduct.productId)
    product.isInCart = data.userCart.findIndex(p => p.productId === wishlistProduct.productId) > -1
    return product
  })

  return [200, { products }]
})

// ------------------------------------------------
// GET: Return Cart Products
// ------------------------------------------------
mock.onGet('/apps/ecommerce/cart').reply(() => {
  const products = data.userCart.map(cartProduct => {
    const product = data.products.find(p => p.id === cartProduct.productId)

    // Other data
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === cartProduct.productId) > -1
    product.qty = cartProduct.qty
    product.shippingDate = randomDate(nextDay, nextWeek)
    product.offers = getRandomInt(1, 4)
    product.discountPercentage = getRandomInt(3, 20)

    return product
  })

  return [200, { products }]
})

// ------------------------------------------------
// POST: Add Item in user Cart
// ------------------------------------------------
mock.onPost('/apps/ecommerce/cart').reply(config => {
  // Get product from post data
  const { productId } = JSON.parse(config.data)

  const { length } = data.userCart
  let lastId = 0
  if (length) lastId = data.userCart[length - 1].i

  data.userCart.push({
    id: lastId + 1,
    productId,
    qty: 1,
  })

  return [201]
})

// ------------------------------------------------
// DELETE: Remove all Items from user cart
// ------------------------------------------------
mock.onDelete('/apps/ecommerce/cart/delete').reply(() => {
  // Get product id from URL
  data.userCart = []
  return [200]
})

// ------------------------------------------------
// DELETE: Remove Item from user Cart
// ------------------------------------------------
mock.onDelete(/\/apps\/ecommerce\/cart\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.userCart.findIndex(i => i.productId === productId)
  if (productIndex > -1) data.userCart.splice(productIndex, 1)

  return [200]
})

// ------------------------------------------------
// POST: Add Item in user Wishlist
// ------------------------------------------------
mock.onPost('/apps/ecommerce/wishlist').reply(config => {
  // Get product from post data
  const { productId } = JSON.parse(config.data)

  const { length } = data.userWishlist
  let lastId = 0
  if (length) lastId = data.userWishlist[length - 1].i

  data.userWishlist.push({
    id: lastId + 1,
    productId,
  })

  return [201]
})

// ------------------------------------------------
// DELETE: Remove Item from user Wishlist
// ------------------------------------------------
mock.onDelete(/\/apps\/ecommerce\/wishlist\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.userWishlist.findIndex(i => i.productId === productId)
  if (productIndex > -1) data.userWishlist.splice(productIndex, 1)

  return [200]
})
