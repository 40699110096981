import { fetchOrganizationConfigurationWorkRolesRequest } from '@/request/globalApi/requests/organizationConfigurationRequests'

export default {
  namespaced: true,
  state: {
    workrolesLoading: false,
    workroles: [],
  },

  mutations: {
    SET_WORKROLES_LOADING: (state, loading) => {
      state.workrolesLoading = loading
    },

    SET_WORKROLES: (state, workroles) => {
      state.workroles = workroles
    },
  },

  actions: {
    fetchWorkroles({ commit, state }, refresh) {
      if (refresh || !state.workroles.length) {
        commit('SET_WORKROLES_LOADING', true)
        fetchOrganizationConfigurationWorkRolesRequest()
          .then(response => {
            commit('SET_WORKROLES', response.data.workRoles)
          })
          .finally(() => {
            commit('SET_WORKROLES_LOADING', false)
          })
      }
    },
  },
}
