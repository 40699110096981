import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    providers: [
      {
        id: 2,
        name: 'VIP Air Handling',
        type: 'fbo',
        username: 'The top service',
        description: 'Eius populus ab incunabulis primis ad usque pueritiae tempus extremum, quod annis circumcluditur fere trecentis, circummurana pertulit bella, deinde aetatem ingressus adultam post multiplices bellorum aerumnas Alpes transcendit et fretum.',
        background: '@/assets/images/slider/04.jpg',
        image: '@/assets/images/avatars/1-small.png',
        coverImg: '@/assets/images/slider/profileBackground.jpg',
        designation: 'Aircraft operator',
        logo: 'https://www.laboiteverte.fr/wp-content/uploads/2011/08/fauxgo-faux-logo-film-compagnie-03.jpg',
        informations: {
          vendorCat: 'Aircraft',
          airportName: 'Biarritz',
          ICAOCode: 'GRruosrUG568fD',
        },
        workingHours: [
          {
            weekDay: 1,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 2,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 3,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 4,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 5,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '16:00',
              }],
          },
          {
            weekDay: 6,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 0,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
        ],
        location: [{
          id: 1,
          position: {
            lat: 43.468615500000006,
            lng: -1.5320416,
          },
          airport: 'Geneva',
          info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        }],
      },
      {
        id: 3,
        name: 'You Fly Handling',
        type: 'fbo',
        username: 'The top service',
        description: 'Eius populus ab incunabulis primis ad usque pueritiae tempus extremum, quod annis circumcluditur fere trecentis, circummurana pertulit bella, deinde aetatem ingressus adultam post multiplices bellorum aerumnas Alpes transcendit et fretum.',
        background: '@/assets/images/slider/04.jpg',
        image: '@/assets/images/avatars/1-small.png',
        coverImg: '@/assets/images/slider/profileBackground.jpg',
        designation: 'Aircraft operator',
        logo: 'https://www.laboiteverte.fr/wp-content/uploads/2011/08/fauxgo-faux-logo-film-compagnie-04.jpg',
        informations: {
          vendorCat: 'Aircraft',
          airportName: 'Biarritz',
          ICAOCode: 'GRruosrUG568fD',
        },
        workingHours: [
          {
            weekDay: 1,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 2,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 3,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 4,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 5,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '16:00',
              }],
          },
          {
            weekDay: 6,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 0,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
        ],
        location: [{
          id: 1,
          position: {
            lat: 43.468615500000006,
            lng: -1.5320416,
          },
          airport: 'Geneva',
          info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        }],
      },
      {
        id: 6,
        name: 'HeliExpress',
        type: 'helicopter',
        username: 'The top service',
        description: 'Eius populus ab incunabulis primis ad usque pueritiae tempus extremum, quod annis circumcluditur fere trecentis, circummurana pertulit bella, deinde aetatem ingressus adultam post multiplices bellorum aerumnas Alpes transcendit et fretum.',
        background: '@/assets/images/slider/04.jpg',
        image: '@/assets/images/avatars/1-small.png',
        coverImg: '@/assets/images/slider/profileBackground.jpg',
        designation: 'Aircraft operator',
        logo: 'https://www.laboiteverte.fr/wp-content/uploads/2011/08/fauxgo-faux-logo-film-compagnie-04.jpg',
        informations: {
          vendorCat: 'Aircraft',
          airportName: 'Biarritz',
          ICAOCode: 'GRruosrUG568fD',
        },
        workingHours: [
          {
            weekDay: 1,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 2,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 3,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 4,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 5,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '16:00',
              }],
          },
          {
            weekDay: 6,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 0,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
        ],
        location: [{
          id: 1,
          position: {
            lat: 43.468615500000006,
            lng: -1.5320416,
          },
          airport: 'Geneva',
          info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        }],
      },
      {
        id: 8,
        name: 'Helitransfer',
        type: 'helicopter',
        username: 'The top service',
        description: 'Eius populus ab incunabulis primis ad usque pueritiae tempus extremum, quod annis circumcluditur fere trecentis, circummurana pertulit bella, deinde aetatem ingressus adultam post multiplices bellorum aerumnas Alpes transcendit et fretum.',
        background: '@/assets/images/slider/04.jpg',
        image: '@/assets/images/avatars/1-small.png',
        coverImg: '@/assets/images/slider/profileBackground.jpg',
        designation: 'Aircraft operator',
        logo: 'https://www.laboiteverte.fr/wp-content/uploads/2011/08/fauxgo-faux-logo-film-compagnie-04.jpg',
        informations: {
          vendorCat: 'Aircraft',
          airportName: 'Biarritz',
          ICAOCode: 'GRruosrUG568fD',
        },
        workingHours: [
          {
            weekDay: 1,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 2,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 3,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 4,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 5,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '16:00',
              }],
          },
          {
            weekDay: 6,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
          {
            weekDay: 0,
            hours: [
              {
                start: '9:00',
                end: '12:00',
              },
              {
                start: '13:00',
                end: '17:00',
              }],
          },
        ],
        location: [{
          id: 1,
          position: {
            lat: 43.468615500000006,
            lng: -1.5320416,
          },
          airport: 'Geneva',
          info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        }],
      },
    ],
  },
  mutations: {
  },
  actions: {
    fetchProviders({ state }, queryParams) {
      return new Promise((resolve, reject) => {
        resolve(state.providers)
        reject()
      })
    },
    fetchProvider({ state }, id) {
      return new Promise((resolve, reject) => {
        const provider = state.providers.find(item => item.id === parseInt(id, 10))
        resolve(provider)
        reject()
      })
    },
  },
}
