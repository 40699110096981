import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

const data = {
  organizations: [
    {
      id: 1,
      generalInformation: {
        legalName: 'Jean Dubois',
        commercialName: 'Jean Dubois',
        defaultLang: 'fr',
        defaultCurrency: 'euro',
        address: '8 rue Leonard de Vinci',
        addressComplementary: '',
        postalCode: '64000',
        city: 'Bayonne',
        country: 'France',
        invoice: {
          prefix: 'JB',
          format: 'Format1',
        },
        emergencyContact: {
          name: 'Laurie Duchamp',
          email: 'LauMail@mail.fr',
          tel: '02909899',
        },
        accountingContact: {
          name: 'Patrick Dupond',
          email: 'pat@mail.fr',
          tel: '07567893',
        },
        feesCollector: false,
      },
      presentation: {
        avatar: '',
        banner: '',
        description: '',
        website: '',
        socialNetworks: [
          {
            name: '',
            link: '',
          },
        ],
      },
      legalInformation: {
        siret: '09023890734',
        apeCode: '343409087',
        VATNumber: '334333229865',
        shareCapital: '8876',
        RCS: '98DY83G83',
        facturationPrefix: 'JB',
        cgvFile: null,
      },
      KYC: {
        bank: 'Bank France',
        incumbentName: 'Jean Dubois',
        IBAN: '2U87083HJK',
        BIC: 'BE334442S',
        RCS: 'JUHF786FUG',
        prefix: 'JB',
        organization: {
          sector: 'Commercial',
          website: '',
          address: '16 cours fauriel',
          phone: '',
          description: '',
          kbisFile: '',
        },
        legalIncumbent: {
          name: 'Jean Dubois',
          email: 'Jean@mail.fr',
          address: '16 rue leonard de vinci',
          phone: '09384793',
          identityPiece: 'Identity card',
          recto: '',
          verso: '',
        },
      },
      addresses: [
        {
          id: 1,
          name: 'La sicle',
          address: '98 rue Leonard de vinci',
          complementaryAddress: '',
          postalCode: '64000',
          city: 'Bayonne',
          country: 'France',
          UTC: '',
          information: '',
          codeBuilding: '',
          defaultAddress: true,
        },
      ],
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Organizations
// ------------------------------------------------
mock.onGet('/api/organization/organizations').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
  } = config.params

  const queryLowered = q.toLowerCase()
  const filteredData = data.organizations.filter(
    organization => organization.organization.generalInformation.name.toLowerCase().includes(queryLowered) || organization.generalInformation.commercialName.toLowerCase().includes(queryLowered),
  )

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      organizations: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Organization
// ------------------------------------------------
mock.onGet(/\/api\/organization\/organizations\/\d+/).reply(config => {
  // Get event id from URL
  let organizationId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  organizationId = Number(organizationId)

  const organizationIndex = data.organizations.findIndex(e => e.id === organizationId)
  const organization = data.organizations[organizationIndex]

  if (organization) return [200, organization]
  return [404]
})

// ------------------------------------------------
// POST: Add new organization address
// ------------------------------------------------
mock.onPost(/\/api\/organization\/organizations\/\d+/).reply(config => {
  // Get organizationId id from URL
  // Get event from post data
  const organizationId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const { address } = JSON.parse(config.data)

  const currentOrganization = data.organizations.find(organization => organization.id === organizationId)

  if (address.defaultAddress) {
    // eslint-disable-next-line array-callback-return
    currentOrganization.addresses.map(currentAddresse => {
      // eslint-disable-next-line no-param-reassign
      currentAddresse.defaultAddress = false
    })
  }

  let lastIndex = 0
  if (currentOrganization.addresses.length) {
    lastIndex = currentOrganization.addresses[currentOrganization.addresses.length - 1].id
  }

  address.id = lastIndex + 1

  currentOrganization.addresses.push(address)

  return [201, { address }]
})
