export default [
  {
    path: '/slot-management',
    name: 'slot-management',
    component: () => import('@/views/slot-management/SlotManagement.vue'),
    meta: {
      title: 'navigation.slot_management',
      action: 'TRIP_SLOT_MANAGEMENT_VIEW',
      breadcrumb: [
        {
          text: 'navigation.slot_management',
          active: true,
        },
      ],
    },
  },
]
