import Vue from 'vue'
import {
  BVConfigPlugin,
  BContainer,
  BSidebar,
  BOverlay,
  BTable,
  BTableSimple,
  BButton,
  BFormDatepicker,
  BRow,
  BCol,
  BTabs,
  BNavbar,
  BLink,
  BBadge,
  BFormInput,
  BFormGroup,
  BTab,
  BCollapse,
  BFormTextarea,
  BImg,
  BNavItemDropdown,
  BAvatar,
  BDropdownItem,
  BNavbarNav,
  BNavItem,
  BDropdownDivider,
  BFormCheckbox,
  BFormFile,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BListGroup,
  BListGroupItem,
  BTooltip,
  BMedia,
  BFormSelect,
  BSpinner,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BInputGroupAppend,
  BPagination,
  BForm,
  BCardTitle,
  BFormCheckboxGroup,
  BFormSelectOption,
  BModal,
  BAlert,
  BCardSubTitle,
  BMediaBody,
  BFormRadio,
  BFormRadioGroup,
  BNav,
  BPaginationNav,
  BTd,
  BTh,
  BFormTimepicker,
  BNavbarToggle,
  BTr,
  BThead,
  BTbody,
  BTfoot,
  BPopover,
  BButtonGroup,
  BFormSpinbutton,
  BCardImg,
} from 'bootstrap-vue'

// import i18n from '@/libs/i18n'

Vue.use(BVConfigPlugin, {
  BSidebar: {
    backdrop: true,
    bgVariant: 'white',
    lazy: true,
    noHeader: true,
    right: true,
    shadow: true,
    sidebarClass: 'sidebar-lg',
  },

  BOverlay: {
    rounded: true,
    spinnerSmall: true,
    spinnerVariant: 'primary',
    opacity: 0.85,
    noFade: true,
    variant: 'transparent',
  },
  BTable: {
    // emptyText: i18n.t('common.no_record_found'), not reactive in default props
    responsive: true,
    showEmpty: true,
    hover: true,
  },
  BButton: { variant: 'primary' },
  BFormDatepicker: {
    // locale: i18n.locale,  not reactive in default props
    dateFormatOptions: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    },
    menuClass: 'w-100',
    calendarWidth: '100%',
  },
  BFormTimepicker: {
    hour12: false,
  },
})

Vue.component('BPopover', BPopover)
Vue.component('BSidebar', BSidebar)
Vue.component('BOverlay', BOverlay)
Vue.component('BTable', BTable)
Vue.component('BTableSimple', BTableSimple)
Vue.component('BButton', BButton)
Vue.component('BFormDatepicker', BFormDatepicker)
Vue.component('BFormTimepicker', BFormTimepicker)
Vue.component('BFormFile', BFormFile)
///
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
///
Vue.component('BTbody', BTbody)
Vue.component('BTr', BTr)
Vue.component('BTfoot', BTfoot)
Vue.component('BThead', BThead)
Vue.component('BTabs', BTabs)
Vue.component('BNavbar', BNavbar)
Vue.component('BNavbarToggle', BNavbarToggle)
Vue.component('BLink', BLink)
Vue.component('BBadge', BBadge)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BTab', BTab)
Vue.component('BCollapse', BCollapse)
Vue.component('BFormTextarea', BFormTextarea)
Vue.component('BImg', BImg)
Vue.component('BNavItemDropdown', BNavItemDropdown)
Vue.component('BAvatar', BAvatar)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BNavbarNav', BNavbarNav)
Vue.component('BNavItem', BNavItem)
Vue.component('BDropdownDivider', BDropdownDivider)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BBreadcrumb', BBreadcrumb)
Vue.component('BBreadcrumbItem', BBreadcrumbItem)
Vue.component('BCard', BCard)
Vue.component('BCardBody', BCardBody)
Vue.component('BCardImg', BCardImg)
Vue.component('BCardHeader', BCardHeader)
Vue.component('BCardText', BCardText)
Vue.component('BListGroup', BListGroup)
Vue.component('BListGroupItem', BListGroupItem)
Vue.component('BTooltip', BTooltip)
Vue.component('BMedia', BMedia)
Vue.component('BFormSelect', BFormSelect)
Vue.component('BSpinner', BSpinner)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BInputGroupPrepend', BInputGroupPrepend)
Vue.component('BDropdown', BDropdown)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BPagination', BPagination)
Vue.component('BForm', BForm)
Vue.component('BCardTitle', BCardTitle)
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup)
Vue.component('BFormSelectOption', BFormSelectOption)
Vue.component('BModal', BModal)
Vue.component('BAlert', BAlert)
Vue.component('BCardSubTitle', BCardSubTitle)
Vue.component('BMediaBody', BMediaBody)
Vue.component('BFormRadio', BFormRadio)
Vue.component('BFormRadioGroup', BFormRadioGroup)
Vue.component('BNav', BNav)
Vue.component('BPaginationNav', BPaginationNav)
Vue.component('BTh', BTh)
Vue.component('BTd', BTd)
Vue.component('BContainer', BContainer)
Vue.component('BButtonGroup', BButtonGroup)
Vue.component('BFormSpinbutton', BFormSpinbutton)
