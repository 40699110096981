export default [
  {
    path: '/hangar/dashboard',
    name: 'hangar-dashboard',
    component: () => import('@/views/dashboard/hangar/HangarDashboard.vue'),
    meta: {
      action: 'DASHBOARD_HANGAR_VIEW',
    },
  },
  {
    path: '/hangar/calendar',
    name: 'hangar-calendar',
    component: () => import('@/views/dashboard/hangar/HangarCalendar.vue'),
    meta: {
      action: 'DASHBOARD_HANGAR_VIEW',
    },
  },
]
