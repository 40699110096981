import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    marketplaceConfiguration: {},
  },
  getters: {
    object: state => state.marketplaceConfiguration,
  },
  mutations: {
    updateMarketplaceConfiguration: (state, marketplaceConfiguration) => {
      Vue.set(state.marketplaceConfiguration, marketplaceConfiguration)
    },
  },
  actions: {
  },
}
