import Vue from 'vue'

import moment from 'moment-timezone'
import VueMoment from 'vue-moment'

moment.tz.setDefault(moment.tz.guess())

moment.locale(localStorage.getItem('lang'))

Vue.use(VueMoment, {
  moment,
})
