import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    tripStepEventHangarSearch: {},
  },
  mutations: {
    SET_START_DATE(state, { tripStepEventId, startDate }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        Vue.set(state.tripStepEventHangarSearch, tripStepEventId, {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      }
      state.tripStepEventHangarSearch[tripStepEventId].startDate = startDate
    },
    SET_START_TIME(state, { tripStepEventId, startTime }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        Vue.set(state.tripStepEventHangarSearch, tripStepEventId, {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      }
      state.tripStepEventHangarSearch[tripStepEventId].startTime = startTime
    },
    SET_END_DATE(state, { tripStepEventId, endDate }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        Vue.set(state.tripStepEventHangarSearch, tripStepEventId, {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      }
      state.tripStepEventHangarSearch[tripStepEventId].endDate = endDate
    },
    SET_END_TIME(state, { tripStepEventId, endTime }) {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        Vue.set(state.tripStepEventHangarSearch, tripStepEventId, {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      }
      state.tripStepEventHangarSearch[tripStepEventId].endTime = endTime
    },
  },
  getters: {
    getStartDateByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return null
      }

      return state.tripStepEventHangarSearch[tripStepEventId].startDate
    },
    getStartTimeByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return null
      }

      return state.tripStepEventHangarSearch[tripStepEventId].startTime
    },
    getEndDateByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return null
      }

      return state.tripStepEventHangarSearch[tripStepEventId].endDate
    },
    getEndTimeByEvent: state => tripStepEventId => {
      if (!Object.hasOwn(state.tripStepEventHangarSearch, tripStepEventId)) {
        return null
      }

      return state.tripStepEventHangarSearch[tripStepEventId].endTime
    },
  },
}
