export default [
  {
    path: '/account/:tab',
    name: 'account',
    component: () => import('@/views/account/AccountWizard.vue'),
    meta: {
      title: 'navigation.account',
      action: 'read',
      resource: 'DEFAULT',
      breadcrumb: [
        {
          text: 'navigation.account',
          active: true,
        },
      ],
    },
  },
]
