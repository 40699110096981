<template>
  <div :id="`${formName}-text-input-itn`">
    <validation-observer :ref="`${formName}-text-input-itn-observer`" v-slot="{ errors }">
      <b-overlay :show="loading">
        <b-tabs :id="`${formName}-text-input-itn-tabs`" align="left">
          <template v-if="showLabel" #tabs-start>
            <li v-if="label" class="tabs-start nav-item align-self-end mr-2" role="presentation">
              <span class="label">{{ label }}</span>
            </li>
          </template>
          <b-tab
            v-for="lang in languages"
            :id="`${formName}-text-input-itn-tab-${lang.locale}`"
            :key="lang.locale"
            :active="lang.locale === localDefaultLang"
            class="tab"
            @click="focusOn(`${formName}-text-input-itn-input-${lang.locale}`)"
          >
            <template #title>
              <div class="title">
                <b-img :src="lang.img" class="img" />
                <span>{{ lang.locale }} </span>
                <span v-if="isRequired(lang.locale)" class="text-danger">&nbsp;* </span>
              </div>
            </template>
            <validation-provider :name="label" :rules="getRules(lang.locale)" :vid="`${formName}-text-input-itn-provider-${lang.locale}`">
              <b-form-group>
                <b-form-input
                  :id="`${formName}-text-input-itn-input-${lang.locale}`"
                  :ref="`${formName}-text-input-itn-input-${lang.locale}`"
                  :disabled="disabled"
                  :placeholder="getPlaceholder()"
                  :readonly="readonly"
                  :state="getFirstObserverError(errors) && false"
                  :value="value ? value[lang.locale] : ''"
                  class="form-input"
                  type="text"
                  @input="inputText($event, lang.locale)"
                />
                <small v-if="getFirstObserverError(errors) && !toolTipErrors" class="text-danger">
                  {{ getFirstObserverError(errors) }}
                </small>
                <b-tooltip
                  v-if="getFirstObserverError(errors) && toolTipErrors"
                  :target="`${formName}-text-input-itn-input-${lang.locale}`"
                  placement="bottom"
                  show
                  variant="light"
                >
                  <small class="text-danger">{{ getFirstObserverError(errors) }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
          </b-tab>
          <template #empty>
            <div class="text-center text-muted">
              <b-form-input :id="`${formName}-text-input-itn-input-empty`" :placeholder="localNoLanguages" class="form-input" disabled />
            </div>
          </template>
        </b-tabs>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: 'AppFormTextInputItn',

  props: {
    formName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    languages: {
      type: Array,
      default: () => [],
    },
    defaultLang: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
    required: {
      type: Array,
      default: () => [],
    },
    noLanguages: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    toolTipErrors: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    localNoLanguages() {
      return this.noLanguages || this.$t('common.no_languages')
    },
    localDefaultLang() {
      return this.defaultLang || this.$store.state.auth.defaultLang
    },
  },
  mounted() {
    // Ajout de la langue de la plateforme en required par défaut
    this.required.push(localStorage.getItem('appLang'))
  },
  methods: {
    inputText(event, lang) {
      this.value[lang] = event
      this.$emit('input-text', event)
    },

    focusOn(ref) {
      this.$nextTick(() => {
        this.$refs[ref][0].focus()
      })
    },

    isRequired(lang) {
      return this.required.includes(lang)
    },

    getRules(lang) {
      const rules = this.required.includes(lang) ? ['required'] : []
      return rules.concat(this.rules).join('|')
    },

    getPlaceholder() {
      let placeholder = this.label
      if (typeof this.placeholder === 'object') {
        placeholder = this.$options.filters.trans(this.placeholder)
      } else if (this.placeholder) {
        placeholder = this.placeholder
      }
      return placeholder
    },

    getFirstObserverError(errors) {
      const errorsToArray = Object.entries(errors)
        .map(key => ({ [key[0]]: key[1] }))
        .filter(error => Object.values(error)[0].length > 0)

      if (errorsToArray.length) {
        return `${Object.keys(errorsToArray[0]).toString().slice(-2).toUpperCase()} : ${Object.values(errorsToArray[0])}`
      }

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 0.2857rem;
  display: block !important;
  color: #5e5873;
  font-size: 0.857rem;
}
.tab {
  margin-bottom: 0px;
}
.form-input {
  margin-top: -14px;
}
.title {
  margin-bottom: -3px;
}
.img {
  margin-right: 10px;
  height: 14px;
  width: 22px;
}

.tabs-start {
  min-width: 100px;
}

.dark-layout {
  .label {
    color: $theme-dark-body-color;
  }
}
</style>
