import { fetchAllRightsRequest } from '@/request/globalApi/requests/rightsRequests'

export default {
  namespaced: true,
  state: {
    rightsLoading: false,
    rights: [],
  },

  mutations: {
    SET_RIGHTS_LOADING: (state, loading) => {
      state.workrolesLoading = loading
    },

    SET_RIGHTS: (state, rights) => {
      state.rights = rights
    },
  },

  actions: {
    fetchAllRights({ commit, state }, refresh) {
      if (refresh || !state.rights.length) {
        commit('SET_RIGHTS_LOADING', true)
        fetchAllRightsRequest()
          .then(response => {
            commit('SET_RIGHTS', response.data.rights)
          })
          .finally(() => {
            commit('SET_RIGHTS_LOADING', false)
          })
      }
    },
  },
}
