import { fetchCancellationPoliciesRequest } from '@/request/globalApi/requests/cancellationPolicyRequests'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    cancellationPoliciesLoading: false,
    cancellationPolicies: [],

    pagination: { totalItems: 0, firstPage: 1, previousPage: 1, nextPage: 1, lastPage: 1 },
    queryParams: {
      page: 1,
      numberOfItemsPerPage: 0,
      cancellationPolicyStatus: null,
      nameOrder: null,
      descriptionOrder: null,
    },
    search: {
      name: null,
      description: null,
    },
    sorting: {
      sortBy: 'name',
      sortDesc: false,
    },

    cancellationPoliciesShowed: [],

    cancellationPoliciesNameLoading: false,
    cancellationPoliciesName: [],
  },

  getters: {
    getField,

    cancellationPolicyById: state => id => {
      const result = state.cancellationPoliciesShowed.find(item => item.id === id)
      return result
    },
  },

  mutations: {
    updateField,

    SET_CANCELLATION_POLICY_LOADING: (state, loading) => {
      state.cancellationPoliciesLoading = loading
    },

    SET_CANCELLATION_POLICIES: (state, cancellationPolicies) => {
      state.cancellationPolicies = cancellationPolicies
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SORT: (state, sort) => {
      state.sorting = sort
      state.queryParams.nameOrder = null
      state.queryParams.descriptionOrder = null
      state.queryParams[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.queryParams.numberOfItemsPerPage = perPage
    },

    SET_CANCELLATION_POLICIES_SHOWED: (state, cancellationPolicy) => {
      state.cancellationPoliciesShowed.push(cancellationPolicy)
    },

    CLEAR_CANCELLATION_POLICIES_SHOWED: state => {
      state.cancellationPoliciesShowed = []
    },

    SET_CANCELLATION_POLICIES_NAME_LOADING: (state, loading) => {
      state.cancellationPoliciesNameLoading = loading
    },

    SET_CANCELLATION_POLICIES_NAME: (state, cancellationPoliciesName) => {
      state.cancellationPoliciesName = cancellationPoliciesName
    },
  },

  actions: {
    fetchCancellationPolicies({ commit, state, rootState }, refresh) {
      if (refresh || !state.cancellationPolicies.length) {
        state.queryParams.numberOfItemsPerPage === 0
          && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_CANCELLATION_POLICY_LOADING', true)
        fetchCancellationPoliciesRequest({ ...state.queryParams, ...state.search })
          .then(response => {
            commit('SET_CANCELLATION_POLICIES', response.data.cancellationPolicies)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,
              firstPage: response.data.firstPage,
              previousPage: response.data.previousPage,
              nextPage: response.data.nextPage,
              lastPage: response.data.lastPage,
            })
            commit('CLEAR_CANCELLATION_POLICIES_SHOWED')
          })
          .finally(() => {
            commit('SET_CANCELLATION_POLICY_LOADING', false)
          })
      }
    },

    setCancelationPoliciesShowed({ commit }, cancellationPolicy) {
      commit('SET_CANCELLATION_POLICIES_SHOWED', cancellationPolicy)
    },

    clearCancelationPoliciesShowed({ commit }) {
      commit('CLEAR_CANCELLATION_POLICIES_SHOWED')
    },

    setSort({ commit }, sort) {
      commit('SET_SORT', sort)
    },

    fetchCancellationPoliciesName({ commit, state }) {
      if (!state.cancellationPoliciesName.length) {
        fetchCancellationPoliciesRequest({ light: true, getAllItems: true }).then(response => {
          commit('SET_CANCELLATION_POLICIES_NAME', response.data.cancellationPolicies)
        })
      }
    },
  },
}
