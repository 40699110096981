export default [
  {
    path: '/aircrafts',
    name: 'aircrafts',
    component: () => import('@/views/aircraft/Aircrafts.vue'),
    meta: {
      title: 'navigation.aircrafts',
      action: 'OPERATOR_AIRCRAFT_VIEW',
      breadcrumb: [
        {
          text: 'navigation.aircrafts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/aircraft/edit/:aircraft_id',
    name: 'aircraft-edit',
    component: () => import('@/views/aircraft/AircraftForm.vue'),
    meta: {
      title: 'navigation.aircrafts',
      action: 'OPERATOR_AIRCRAFT_EDIT',
      breadcrumb: [
        {
          text: 'navigation.aircrafts',
          to: '/aircrafts',

        },
        {
          text: 'navigation.aircraft.edit',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/aircraft/add',
    name: 'aircraft-add',
    component: () => import('@/views/aircraft/AircraftForm.vue'),
    meta: {
      title: 'navigation.aircraft.add',
      action: 'OPERATOR_AIRCRAFT_ADD',
      breadcrumb: [
        {
          text: 'navigation.aircrafts',
          to: '/aircrafts',

        },
        {
          text: 'navigation.aircraft.add',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
]
