<template>
  <b-overlay :show="overlay && !searchLoader" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
    <validation-provider #default="{ errors }" :name="labelField" :rules="{ required }">
      <component
        :is="labelField ? 'b-form-group' : 'div'"
        :class="[{ 'validation-required':required, 'is-invalid': errors[0] }, classGroup]"
        :label="labelField"
        :label-for="id"
        :label-cols="labelCols"
        :label-cols-lg="labelColsLg"
        :label-cols-md="labelColsMd"
        :label-cols-sm="labelColsSm"
        :label-cols-xl="labelColsXl"
      >
        <v-select
          :value="value || ''"
          :input-id="id"
          :searchable="searchable"
          :placeholder="localPlaceholder"
          :taggable="taggable"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :close-on-select="!multiple"
          :multiple="multiple"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <!-- Load all v-select's scoped slots to be used from parent -->
          <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
          </template>

          <!-- required v-select -->
          <template v-if="required" #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!value"
              v-bind="attributes"
              v-on="events"
            />
          </template>

          <template v-if="searchable" v-slot:no-options="{ search }">
            <b-spinner v-if="searchLoader" small />
            <template v-else-if="search">{{ $t('common.no_results_found_for', {search}) }}</template>
            <template v-else>{{ $t('common.no_results_found') }}</template>
          </template>

          <!-- Pagination into dropdown only if necessary -->
          <template v-if="needPagination" v-slot:list-footer="{ search }">
            <li class="pagination">
              <button
                class="w-100"
                type="button"
                :disabled="!previousPage"
                @click="$emit('click:previous-page', search || null)"
                v-text="$t('common.previous')"
              />
              <button
                class="w-100"
                type="button"
                :disabled="!nextPage"
                @click="$emit('click:next-page', search || null)"
                v-text="$t('common.next')"
              />
            </li>
          </template>
        </v-select>
        <small v-if="!toolTipErrors" class="text-danger">{{ errors[0] }}</small>
        <b-tooltip v-if="errors[0] && toolTipErrors" :target="id" placement="bottom" show variant="light">
          <small class="text-danger">{{ errors[0] }}</small>
        </b-tooltip>
      </component>
    </validation-provider>
  </b-overlay>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'AppSelect',

  props: {
    value: { type: [String, Number, Array, Object], default: null },
    id: { type: String, default: undefined },
    labelField: { type: String, default: null },
    placeholder: { type: String, default: '' },
    classGroup: { type: String, default: '' },
    labelCols: { type: [String, Number], default: undefined },
    labelColsLg: { type: [String, Number], default: undefined },
    labelColsMd: { type: [String, Number], default: undefined },
    labelColsSm: { type: [String, Number], default: undefined },
    labelColsXl: { type: [String, Number], default: undefined },
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    taggable: { type: Boolean, default: false },
    toolTipErrors: { type: Boolean, default: false },
    overlay: { type: Boolean, default: false },
    paginated: { type: Boolean, default: false },
    perPage: { type: Number, default: 12 }, // default value api
    totalItems: { type: Number, default: 0 },
    previousPage: { type: Number, default: null },
    nextPage: { type: Number, default: null },
    lastPage: { type: Number, default: 1 },
  },

  setup(props, ctx) {
    const { $i18n } = ctx.root
    const searchLoader = ref(false)

    const localPlaceholder = computed(() => {
      let placeholder = props.placeholder || props.labelField
      if (props.taggable) placeholder += ` | ${$i18n.t('common.select_taggable')}`
      return placeholder
    })

    const needPagination = computed(() => props.paginated && props.totalItems > props.perPage && !searchLoader.value && props.lastPage > 1)

    return {
      searchLoader,
      localPlaceholder,
      needPagination,
    }
  },
})
</script>
