import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { fetchTripInfosRequest } from '@/request/globalApi/requests/tripRequest'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    tripLoading: false,
    trip: null,
  },
  getters: {},
  mutations: {
    updateField,

    SET_TRIP: (state, trip) => {
      state.trip = trip
    },
    SET_TRIP_LOADING: (state, loading) => {
      state.tripLoading = loading
    },
  },
  actions: {
    // TODO : Remove returns and getting trip in components with mapState in computed
    async fetchTrip({ state, commit }, { tripId, forced }) {
      if (!state.trip || forced) {
        commit('SET_TRIP_LOADING', true)
        return fetchTripInfosRequest(tripId)
            .then(response => {
              commit('SET_TRIP', response.data.trip)
              return response.data.trip
            })
            .finally(() => {
              commit('SET_TRIP_LOADING', false)
            })
      } else {
        return state.trip
      }
    },
  },
}
