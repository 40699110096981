import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.mixin({
  methods: {
    alert(title, text, icon) {
      this.$swal({
        title,
        text,
        icon: icon || 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    toaster(title, variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: icon || 'CheckCircleIcon',
          variant: variant || 'danger',
        },
      })
    },
  },
})
