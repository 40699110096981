import mock from './mock'

// JWT
import './jwt'

// Apps
import './data/shop'
import './data/chat'
import './data/organization'
import './data/user'
import './data/aircraft'
import './data/trip'
import './data/rights'
import './data/workroles'

import './data/dashboard/analytics'
import './data/dashboard/ecommerce'

import './data/card/card-statistics'
import './data/card/card-analytics'

mock.onAny()
  .passThrough() // forwards the matched request over network
