import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = '/api/organization-configuration'

// TYPOLOGY
// WARNING await endpoint typology
export const fetchOrganizationConfigurationTypologiesRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const postOrganizationConfigurationTypologyRequest = (typology, message) => globalApi.post(`${END_POINT}/typology`, typology, { message })

export const patchOrganizationConfigurationTypologyRequest = (typology, message) => globalApi.patch(`${END_POINT}/typology/${typology.id}`, typology, { message })

export const deleteOrganizationConfigurationTypologyRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/typology/${id}`, { message }))

// WORK ROLE

export const fetchOrganizationConfigurationWorkRolesRequest = () => globalApi.get(`${END_POINT}/workrole`)

export const fetchOrganizationConfigurationWorkRoleRequest = id => globalApi.get(`${END_POINT}/workrole/${id}`)

export const postOrganizationConfigurationWorkRoleRequest = async (workrole, message) => globalApi.post(`${END_POINT}/workrole`, workrole, { message })

export const patchOrganizationConfigurationWorkRoleRequest = (workrole, message) => globalApi.patch(`${END_POINT}/workrole/${workrole.id}`, workrole, { message })

export const deleteOrganizationConfigurationWorkRoleRequest = (id, message) => globalApi.delete(`${END_POINT}/workrole/${id}`, { message })
