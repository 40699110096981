import globalApi from '@/request/globalApi/globalApi'
import ability from '@/libs/ability'
import { postLoginCheckRequest } from '@/request/authApi/requests/apiRequests'
import { loadValidationLocale } from '@/libs/vee-validate'
import LANGUAGES from '@/constants/language'
import router from '@/router/router'

export default {
  namespaced: true,
  state: {
    // TODO: appConfig.lang and auth.lang are inversed. No bug, but bad place/name
    defaultLang: localStorage.getItem('lang') || 'en',

    activelang: LANGUAGES,

    user: JSON.parse(localStorage.getItem('user')) || {},

    selectedOrganization: localStorage.getItem('selectedOrganization')
      ? JSON.parse(localStorage.getItem('selectedOrganization'))
      : { id: null },

    isAuthorized: !!localStorage.getItem('token'),
    abilities: JSON.parse(localStorage.getItem('abilities')) || [
      { action: 'read', subject: 'DEFAULT' },
    ],
  },

  getters: {
    selectedOrganizationId: state => (state.selectedOrganization.id ? state.selectedOrganization.id : null),
    isBuyer: state => state.selectedOrganization.buyer && !state.selectedOrganization.seller,
    isSeller: state => state.selectedOrganization.seller && !state.selectedOrganization.buyer,
    isOperator: state => !state.selectedOrganization.seller && !state.selectedOrganization.buyer,
  },

  mutations: {
    LOGIN(state, user) {
      // TODO: bad place
      const setCookie = (cname, cvalue, nbDays = 2) => {
        const day = new Date()
        day.setTime(day.getTime() + nbDays * 24 * 60 * 60 * 1000) // nbDays * 24h
        const expires = `expires=${day.toUTCString()}`
        document.cookie = `${cname}=${cvalue};${expires};path=/`
      }
      state.isAuthorized = true
      state.user = user
      state.defaultLang = user.defaultLang.code

      // globalApi.defaults.headers.Authorization = `Bearer ${user.token}`

      localStorage.setItem('token', user.token)
      localStorage.setItem('user', JSON.stringify(user))
      setCookie('refreshToken', user.refreshToken)
    },

    UPDATE_TOKEN(state, token) {
      state.user.token = token
      localStorage.setItem('token', token)
    },

    SET_SELECTED_ORGANISATION(state, organization) {
      state.selectedOrganization = organization

      globalApi.defaults.headers.selectedOrganizationId = organization.id

      localStorage.setItem('selectedOrganization', JSON.stringify(organization))
    },

    SET_ABILITIES(state, rights) {
      state.abilities = state.user.rights.concat([
        { action: 'read', subject: 'DEFAULT' },
      ])
      if (rights) state.abilities = state.abilities.concat(rights)

      ability.update(state.abilities)

      localStorage.setItem('abilities', JSON.stringify(state.abilities))
    },

    LOGOUT(state) {
      state.user = {}
      state.selectedOrganization = {}
      state.isAuthorized = false
      state.abilities = [{ action: 'read', subject: 'DEFAULT' }]

      delete globalApi.defaults.headers.Authorization
      delete globalApi.defaults.headers.selectedOrganizationId

      ability.update(state.abilities)

      localStorage.removeItem('user')
      localStorage.removeItem('currency')
      localStorage.removeItem('lang')
      localStorage.removeItem('selectedOrganization')
      localStorage.removeItem('token')
      localStorage.removeItem('abilities')
    },
  },
  actions: {
    login({ commit, dispatch }, credentials) {
      postLoginCheckRequest(credentials)
        .then(response => {
          // AWAIT prop type for user from API
          commit('LOGIN', { ...response.data, type: '' })

          if (response.data.organizations.length) dispatch('setSelectedOrganization', response.data.organizations[0])
          if (!response.data.organizations.length) commit('SET_ABILITIES')

          loadValidationLocale(response.data.defaultLang.code)

          commit(
            'appConfig/UPDATE_LANG',
            response.data.defaultLang.code || 'en',
            { root: true },
          )
          commit(
            'appConfig/UPDATE_CURRENCY',
            response.data.defaultCurrency.code || 'EUR',
            { root: true },
          )
        })
        .then(() => {
          if (router.currentRoute.query.redirect) {
            router.replace(router.currentRoute.query.redirect)
          } else {
            router.push({ name: 'home' })
          }
        })
    },

    updateToken({ commit }, token) {
      commit('UPDATE_TOKEN', token)
    },

    setSelectedOrganization({ commit }, organization) {
      commit('SET_SELECTED_ORGANISATION', organization)
      commit('SET_ABILITIES', organization.rights)
    },

    logout({ commit, dispatch }) {
      commit('LOGOUT')
      if (router.currentRoute.name !== 'login') {
        router.push({ name: 'login' })
      }

      dispatch('resetAll', null, { root: true })
    },
  },
}
