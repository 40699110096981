import globalApi from '@/request/globalApi/globalApi'

const END_POINT = 'api/marketplace/invoice'

export const fetchItemsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchItemFiltersRequest = () => new Promise(resolve => {
  const data = {
    data: {
      payment: [],
      type: [],
    },
  }

  resolve(data)
})

export const fetchItemStateRequest = () => new Promise(resolve => {
  const data = {
    data: [],
  }

  resolve(data)
})
