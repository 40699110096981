import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/category'

export const fetchCategoriesRequest = params => globalApi.get(END_POINT, { params })

export const fetchCategoryRequest = categoryId => globalApi.get(`${END_POINT}/${categoryId}`)

export const postCategoryRequest = (category, message) => globalApi.post(END_POINT, category, { message })

export const patchCategoryRequest = (category, message) => globalApi.patch(`${END_POINT}/${category.id}`, category, { message })

export const deleteCategoryRequest = (categoryId, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${categoryId}`, { message }))

// PRODUCTS

export const fetchCategoryProductRequest = categoryId => globalApi.get(`${END_POINT}/${categoryId}/products`)

export const fetchCategoriesProductRequest = params => globalApi.get(`${END_POINT}/products`, { params })

// PRODUCT NAME

export const fetchCategoryProductNameRequest = (categoryId, params) => globalApi.get(`${END_POINT}/${categoryId}/product-name`, { params })

export const postCategoryProductNameRequest = (categoryId, productName, message) => globalApi.post(`${END_POINT}/${categoryId}/product-name`, productName, { message })

export const patchCategoryProductNameRequest = (categoryId, productName, message) => globalApi.patch(`${END_POINT}/${categoryId}/product-name/${productName.id}`, productName, { message })

export const deleteCategoryProductNameRequest = (categoryId, productNameId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${categoryId}/product-name/${productNameId}`, { message }),
)
