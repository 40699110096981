export default [
  {
    path: '/negotiated-rates',
    name: 'negotiated-rates',
    component: () => import('@/views/negotiated-rates/NegotiatedRates.vue'),
    meta: {
      title: 'negotiated_rate.title',
      pln: 0,
      action: 'NEGOTIATED_RATE_VIEW',
      breadcrumb: [
        {
          text: 'action.list',
          active: true,
        },
      ],
    },
  },
]
