import { fetchOrganizationsRequest } from '@/request/globalApi/requests/organizationRequests'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    organizationsLightLoading: false,
    organizationsLight: [],
    organizationsLoading: false,
    organizations: [],
    paginate: { page: 1, numberOfItemsPerPage: 10 },
    pagination: { totalItems: 0 },
    searchFilterSelection: 'legalName',
    searchText: null,
    order: { idOrder: 'asc' },

    filter: {
      organizationTypology: null,
      countryCode: null,
      organizationStatus: null,
    },

    dataTableSort: {
      sortBy: 'id',
      sortDesc: false,
    },
  },
  getters: {
    getField,

    searchFieldOptions: state => Object.keys(state.search),

    filtersCount: state => {
      let count = 0
      Object.values(state.filter).forEach(filt => {
        if (Array.isArray(filt) && filt.length > 0) {
          count += 1
        }
        if (!Array.isArray(filt) && filt) {
          count += 1
        }
      })
      return count
    },
  },
  mutations: {
    updateField,

    CLEAR_FILTERS: state => {
      Object.keys(state.filter).forEach(key => {
        state.filter[key] = null
      })
    },

    SET_DEFAULT_PER_PAGE: (state, numberOfItemsPerPage) => {
      state.paginate.numberOfItemsPerPage = numberOfItemsPerPage
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_ORGANIZATIONS_LIGHT_LOADING: (state, loading) => {
      state.organizationsLightLoading = loading
    },

    SET_ORGANIZATIONS_LIGHT: (state, organizationsLight) => {
      state.organizationsLight = organizationsLight
    },

    SET_ORGANIZATIONS_LOADING: (state, loading) => {
      state.organizationsLoading = loading
    },

    SET_ORGANIZATIONS: (state, organizations) => {
      state.organizations = organizations
    },

    SET_SORT: (state, sort) => {
      state.order = {}
      state.order[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_SEARCH: (state, search) => {
      state.searchText = search
      !state.searchField && (state.searchField = 'legalName')
      Object.keys(state.search).forEach(key => {
        state.search[key] = null
      })
      state.search[state.searchField] = search
    },

    CLEAR_ORGANIZATIONS_SHOWED: state => {
      state.organizationsShowed = []
    },
  },
  actions: {
    fetchOrganizationsLight({ commit, state }) {
      if (!state.organizationsLight.length) {
        commit('SET_ORGANIZATIONS_LIGHT_LOADING', true)
        fetchOrganizationsRequest({ light: true, getAllItems: true })
          .then(response => {
            commit('SET_ORGANIZATIONS_LIGHT', response.data.organizations)
          })
          .finally(() => {
            commit('SET_ORGANIZATIONS_LIGHT_LOADING', false)
          })
      }
    },

    fetchOrganizations({ commit, state, rootState }, refresh) {
      if (refresh || !state.organizations.length) {
        state.paginate.numberOfItemsPerPage === 0
        && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.numberOfItemsPerPage)
        commit('SET_ORGANIZATIONS_LOADING', true)

        fetchOrganizationsRequest({
          ...state.paginate,
          ...state.order,
          legalName: state.searchText,
          ...state.filter,
        })
          .then(response => {
            commit('SET_ORGANIZATIONS', response.data.organizations)
            commit('SET_PAGINATION', { totalItems: response.data.totalItems })
          })
          .finally(() => {
            commit('SET_ORGANIZATIONS_LOADING', false)
          })
      }
    },

    setSort({ commit, dispatch }, sort) {
      commit('SET_SORT', sort)
      dispatch('fetchOrganizations', true)
    },

    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },

    clearOrganizationsShowed({ commit }) {
      commit('CLEAR_ORGANIZATIONS_SHOWED')
    },

    clearFilters({ commit, dispatch }) {
      commit('CLEAR_FILTERS')
      dispatch('fetchOrganizations', true)
    },
  },
}
