import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,

  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    // SCORE
    heightScore: 50,
    bandwidthScore: 50,
    rttScore: 50,
    ramScore: 50,
    threadScore: 50,
    finalScore: 50,
    perPage: 12,
  },

  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },

    xlAndUp: state => state.windowWidth >= $themeBreakpoints.xl,
    lgAndUp: state => state.windowWidth >= $themeBreakpoints.lg,
    mdAndUp: state => state.windowWidth >= $themeBreakpoints.md,
    smAndUp: state => state.windowWidth >= $themeBreakpoints.sm,
    xsAndUp: state => state.windowWidth >= $themeBreakpoints.xs,

    xlAndDown: state => state.windowWidth <= $themeBreakpoints.xl,
    lgAndDown: state => state.windowWidth <= $themeBreakpoints.lg,
    mdAndDown: state => state.windowWidth <= $themeBreakpoints.md,
    smAndDown: state => state.windowWidth <= $themeBreakpoints.sm,
    xsAndDown: state => state.windowWidth <= $themeBreakpoints.xs,
  },

  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },

    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },

    UPDATE_SCORES(state, scores) {
      state.heightScore = scores.heightScore
      state.bandwidthScore = scores.bandwidthScore
      state.rttScore = scores.rttScore
      state.ramScore = scores.ramScore
      state.threadScore = scores.threadScore
      state.finalScore = scores.finalScore
      state.perPage = scores.perPage
    },
  },

  actions: {
    updateScore({ commit }) {
      // SCORE VARIABLES
      let heightScore = 50
      let bandwidthScore = 50
      let rttScore = 50
      let ramScore = 50
      let threadScore = 50
      let finalScore = 50
      let perPage = 10

      // NAVIGATOR VARIABLES
      const height = window.screen.height || 720
      const navigator = window.navigator || {}
      const downlink = navigator.connection.downlink
        || navigator.mozConnection.downlink
        || navigator.webkitConnection.downlink
        || 10
      const rtt = navigator.connection.rtt
        || navigator.mozConnection.rtt
        || navigator.webkitConnection.rtt
        || 10
      const deviceMemory = navigator.deviceMemory || 4
      const hardwareConcurrency = navigator.hardwareConcurrency || 4

      // SCORE FUNCTIONS
      // scoreOuterHeight px
      const scoreHeight = height => {
        if (height >= 2160) return 100
        if (height >= 1080) return 75
        if (height >= 720) return 50
        return 25
      }
      // scoreDownlink mbps
      // do deciated function dowlink result brided due to fingerprint protection
      const scoreBandwidth = downlink => {
        if (downlink >= 50) return 100
        if (downlink >= 25) return 75
        if (downlink >= 10) return 50
        return 25
      }
      // scorelantency milliseconds
      const scorelantency = rtt => {
        if (rtt <= 25) return 100
        if (rtt <= 50) return 75
        if (rtt <= 100) return 50
        return 25
      }
      // scoreDownlink mbps
      const scoreRam = deviceMemory => {
        if (deviceMemory >= 8) return 100
        if (deviceMemory >= 4) return 75
        if (deviceMemory >= 2) return 50
        return 25
      }
      // scorethread logical processor
      const scorethread = hardwareConcurrency => {
        if (hardwareConcurrency >= 8) return 100
        if (hardwareConcurrency >= 4) return 75
        if (hardwareConcurrency >= 2) return 50
        return 25
      }
      const scoreFinal = (
        heightScore,
        bandwidthScore,
        rttScore,
        ramScore,
        threadScore,
      ) => {
        const finalScore = (heightScore * 2
            + bandwidthScore * 9
            + rttScore * 4
            + ramScore * 4
            + threadScore)
          / 20
        return finalScore
      }
      const scorePerPage = finalScore => {
        if (finalScore <= 10) return 2
        if (finalScore <= 20) return 5
        if (finalScore <= 30) return 7
        if (finalScore <= 40) return 10
        if (finalScore <= 50) return 12
        if (finalScore <= 60) return 15
        if (finalScore <= 70) return 17
        if (finalScore <= 80) return 20
        if (finalScore <= 90) return 22
        if (finalScore <= 100) return 25
        return 12
      }

      heightScore = scoreHeight(height)
      bandwidthScore = scoreBandwidth(downlink)
      rttScore = scorelantency(rtt)
      ramScore = scoreRam(deviceMemory)
      threadScore = scorethread(hardwareConcurrency)
      finalScore = scoreFinal(
        heightScore,
        bandwidthScore,
        rttScore,
        ramScore,
        threadScore,
      )
      perPage = scorePerPage(finalScore)

      commit('UPDATE_SCORES', {
        heightScore,
        bandwidthScore,
        rttScore,
        ramScore,
        threadScore,
        finalScore,
        perPage,
      })
    },
  },
}
