export default [
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/policy/PrivacyPolicy.vue'),
    meta: {
      title: 'navigation.privacy_policy',
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy',
    component: () => import('@/views/policy/CookiesPolicy.vue'),
    meta: {
      title: 'navigation.cookies_policy',
      action: 'read',
      resource: 'DEFAULT',
    },
  },
  {
    path: '/terms-of-services',
    name: 'terms-of-services',
    component: () => import('@/views/policy/TermsOfServices.vue'),
    meta: {
      title: 'navigation.terms_of_services',
      action: 'read',
      resource: 'DEFAULT',
    },
  },
  {
    path: '/commercial-terms',
    name: 'commercial-terms',
    component: () => import('@/views/policy/CommercialTerms.vue'),
    meta: {
      title: 'navigation.commercial_terms',
      action: 'read',
      resource: 'DEFAULT',
    },
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import('@/views/policy/TermsOfUse.vue'),
    meta: {
      title: 'navigation.terms_of_use',
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
  {
    path: '/legal-notices',
    name: 'legal-notices',
    component: () => import('@/views/policy/LegalNotices.vue'),
    meta: {
      title: 'navigation.legal_notices',
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
]
