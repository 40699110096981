import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/organization'

// AIRCRAFTS
export const fetchOrganizationAircraftsRequest = (organizationId, params) => globalApi.get(`${END_POINT}/${organizationId}/aircraft`, { params })

export const fetchOrganizationAircraftRequest = (organizationId, organizationAircraftId) => globalApi.get(`${END_POINT}/${organizationId}/aircraft/${organizationAircraftId}`)

export const postOrganizationAircraftRequest = (organizationId, organizationAircraft, message) => globalApi.post(`${END_POINT}/${organizationId}/aircraft`, organizationAircraft, { message })

export const patchOrganizationAircraftRequest = (organizationId, organizationAircraft, message) => globalApi.patch(`${END_POINT}/${organizationId}/aircraft/${organizationAircraft.id}`, organizationAircraft, { message })

export const deleteOrganizationAircraftRequest = (organizationId, organizationAircraftId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${organizationId}/aircraft/${organizationAircraftId}`, { message }),
)
