import { getField, updateField } from 'vuex-map-fields'
import { fetchNegotiatedRatesRequest } from '@/request/globalApi/requests/negotiatedRateRequests'

export default {
  namespaced: true,
  state: {
    negotiatedRatesLoading: false,

    negotiatedRates: [],
    pagination: { totalItems: 0 },

    paginate: { page: 1, numberOfItemsPerPage: 0 },
    order: {
      companyNameOrder: 'asc',
      intracommunityVatNumberOrder: '',
    },
    search: {
      companyName: '',
      intracommunityVatNumber: '',
    },

    dataTableSort: {
      sortBy: 'companyName',
      sortDesc: false,
    },

    searchField: 'companyName',
    searchText: '',

    negotiatedRatesShowed: [],

    negotiatedRatesNameLoading: false,
    negotiatedRatesName: [],

    lightMode: false,
  },

  getters: {
    getField,

    negotiatedRateById: state => id => {
      const negotiatedRate = state.negotiatedRatesShowed.find(item => item.id === id)
      return negotiatedRate
    },
    searchFieldOptions: state => {
      const options = Object.keys(state.search)
      // options.unshift('all')
      return options
    },
  },

  mutations: {
    updateField,

    SET_NEGOCIATED_RATES_LOADING: (state, loading) => {
      state.negotiatedRatesLoading = loading
    },

    SET_NEGOCIATED_RATES: (state, negotiatedRates) => {
      state.negotiatedRates = negotiatedRates
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SORT: (state, sort) => {
      state.order.companyNameOrder = ''
      state.order.intracommunityVatNumberOrder = ''
      state.order[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_SEARCH: (state, search) => {
      state.searchText = search
      state.searchField === '' && (state.searchField = 'companyName')
      // state.searchField === '' && (state.searchField = 'all')
      // if (state.searchField === 'all') {
      //   state.search.companyName = search
      //   state.search.intracommunityVatNumber = search
      // } else {
      state.search.companyName = ''
      state.search.intracommunityVatNumber = ''
      state.search[state.searchField] = search
      // }
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.paginate.numberOfItemsPerPage = perPage
    },

    SET_NEGOCIATED_RATES_SHOWED: (state, negotiatedRate) => {
      state.negotiatedRatesShowed.push(negotiatedRate)
    },

    CLEAR_NEGOCIATED_RATES_SHOWED: state => {
      state.negotiatedRatesShowed = []
    },

    SET_NEGOCIATED_RATES_NAME_LOADING: (state, loading) => {
      state.negotiatedRatesNameLoading = loading
    },

    SET_NEGOCIATED_RATES_NAME: (state, negotiatedRatesName) => {
      state.negotiatedRatesName = negotiatedRatesName
    },

    SET_NEGOCIATED_RATES_LIGHT_MODE: (state, lightMode) => {
      state.lightMode = lightMode
    },
  },

  actions: {
    fetchNegotiatedRates({ commit, state, rootState }, refresh) {
      if (refresh || !state.negotiatedRates.length) {
        state.paginate.numberOfItemsPerPage === 0
          && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_NEGOCIATED_RATES_LOADING', true)
        fetchNegotiatedRatesRequest({ ...state.paginate, ...state.order, ...state.search, lightMode: state.lightMode })
          .then(response => {
            commit('SET_NEGOCIATED_RATES', response.data.negotiatedRates)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,
            })
            commit('CLEAR_NEGOCIATED_RATES_SHOWED')
          })
          .finally(() => {
            commit('SET_NEGOCIATED_RATES_LOADING', false)
          })
      }
    },

    setSort({ commit, dispatch }, sort) {
      commit('SET_SORT', sort)
      dispatch('fetchNegotiatedRates', true)
    },
    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },

    setNegotiatedRatesShowed({ commit }, negotiatedRate) {
      commit('SET_NEGOCIATED_RATES_SHOWED', negotiatedRate)
    },

    clearNegotiatedRatesShowed({ commit }) {
      commit('CLEAR_NEGOCIATED_RATES_SHOWED')
    },

    fetchNegotiatedRatesName({ commit, state }) {
      if (!state.negotiatedRatesName.length) {
        commit('SET_NEGOCIATED_RATES_NAME_LOADING', true)
        fetchNegotiatedRatesRequest({ light: true, getAllItems: true })
          .then(response => {
            commit('SET_NEGOCIATED_RATES_NAME', response.data.negotiatedRates)
          })
          .finally(() => {
            commit('SET_NEGOCIATED_RATES_NAME_LOADING', false)
          })
      }
    },
  },
}
