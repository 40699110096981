import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  typologiesChanged: false,
  offerTypologiesShowed: [],
})

const defaultState = getDefaultState()

const getters = {
  getField,

  offerTypologiesById: state => id => {
    const offer = state.offerTypologiesShowed.find(item => item.id === id)
    if (offer) {
      return offer.typologies
    }
    return null
  },
}

const mutations = {
  updateField,
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  // AWAIT {id:1,typologies[]}
  SET_OFFER_TYPOLOGIES_SHOWED: (state, offerTypologies) => {
    state.offerTypologiesShowed.push(offerTypologies)
  },

  CLEAR_OFFER_TYPOLOGIES_SHOWED: state => {
    state.offerTypologiesShowed = []
  },

  UPDATE_OFFER_TYPOLOGIES_SHOWED: (state, offerTypologies) => {
    const index = state.offerTypologiesShowed.findIndex(
      item => item.id === offerTypologies.id,
    )
    state.offerTypologiesShowed[index] = offerTypologies
  },
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  // AWAIT {id:1,typologies[]}
  setOfferTypologiesShowed({ commit }, offerTypologies) {
    commit('SET_OFFER_TYPOLOGIES_SHOWED', offerTypologies)
  },

  clearOfferTypologiesShowed({ commit }) {
    commit('CLEAR_OFFER_TYPOLOGIES_SHOWED')
  },

  updateOfferTypologiesShowed({ commit }, offerTypologies) {
    commit('UPDATE_OFFER_TYPOLOGIES_SHOWED', offerTypologies)
  },
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
