export default [
  {
    path: '/organization-aircraft',
    name: 'organization-aircrafts',
    component: () => import('@/views/organization-aircraft/OrganizationAircrafts.vue'),
    meta: {
      title: 'navigation.organization_aircrafts',
      action: 'ORGANIZATION_ACCOUNT_AIRCRAFT_VIEW',
      breadcrumb: [
        {
          text: 'navigation.organization_aircrafts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization-aircraft/edit/:organization_aircraft_id',
    name: 'organization-aircraft-edit',
    component: () => import('@/views/organization-aircraft/OrganizationAircraftForm.vue'),
    meta: {
      title: 'navigation.organization_aircraft.edit',
      action: 'ORGANIZATION_ACCOUNT_AIRCRAFT_EDIT',
      breadcrumb: [
        {
          text: 'navigation.organization_aircrafts',
          to: '/aircrafts',
        },
        {
          text: 'navigation.organization_aircraft.edit',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/organization-aircraft/duplicate/:organization_aircraft_id',
    name: 'organization-aircraft-duplicate',
    component: () => import('@/views/organization-aircraft/OrganizationAircraftForm.vue'),
    meta: {
      title: 'navigation.organization_aircraft.edit',
      action: 'ORGANIZATION_ACCOUNT_AIRCRAFT_ADD',
      breadcrumb: [
        {
          text: 'navigation.organization_aircrafts',
          to: '/aircrafts',
        },
        {
          text: 'navigation.organization_aircraft.duplicate',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/organization-aircraft/add',
    name: 'organization-aircraft-add',
    component: () => import('@/views/organization-aircraft/OrganizationAircraftForm.vue'),
    meta: {
      title: 'navigation.aircraft.add',
      action: 'ORGANIZATION_ACCOUNT_AIRCRAFT_ADD',
      breadcrumb: [
        {
          text: 'navigation.organization_aircrafts',
          to: '/aircrafts',
        },
        {
          text: 'navigation.aircraft.add',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
]
