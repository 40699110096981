import { getField, updateField } from 'vuex-map-fields'
import i18n from '@/libs/i18n'
import { fetchOrganizationAircraftsRequest } from '@/request/globalApi/requests/organizationAircraftRequests'

export default {
  namespaced: true,
  state: {
    organizationAircraftsLoading: true,
    organizationAircrafts: [],
    organizationAircraftsShowed: [],
    pagination: {
      totalItems: 0,
      firstPage: 1,
      previousPage: 1,
      nextPage: 1,
      lastPage: 1,
    },
    queryParams: {
      page: 1,
      numberOfItemsPerPage: 10,
      nameOrder: null,
      categoryOrder: null,
      registrationOrder: null,
      aocOrder: null,
      statusOrder: null,
    },
    searchField: 'name',
    searchText: null,
    search: {
      name: null,
      category: null,
      registration: null,
      aoc: null,
    },
    sorting: {
      sortBy: 'eee',
      sortDesc: false,
    },
    selectedOrganization: JSON.parse(localStorage.getItem('selectedOrganization')) || {},
  },

  getters: {
    getField,

    organizationAircraftById: state => id => state.organizationAircraftsShowed.find(item => item.id === id),

    searchFieldOptions: state => {
      const options = Object.keys(state.search)
      const normalizedOptions = options.slice(1).map(option => (
        { value: option, text: i18n.t(`aircraft.${option}`) }
      ))
      normalizedOptions.unshift({ value: 'name', text: i18n.t('aircraft.aircraft') })
      return normalizedOptions
    },
  },

  mutations: {
    updateField,

    SET_ORGANIZATION_AIRCRAFTS_LOADING: (state, loading) => {
      state.organizationAircraftsLoading = loading
    },

    SET_ORGANIZATION_AIRCRAFTS: (state, organizationAircrafts) => {
      state.organizationAircrafts = organizationAircrafts
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SORT: (state, sort) => {
      state.sorting = sort
      state.queryParams.nameOrder = null
      state.queryParams.categoryOrder = null
      state.queryParams.registrationOrder = null
      state.queryParams.aocOrder = null
      state.queryParams.statusOrder = null
      state.queryParams[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_SEARCH: (state, search) => {
      state.searchText = search
      !state.searchField && (state.searchField = 'name')

      Object.keys(state.search).forEach(key => {
        state.search[key] = null
      })
      state.search[state.searchField] = search
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.queryParams.numberOfItemsPerPage = perPage
    },

    SET_ORGANIZATION_AIRCRAFT_SHOWED: (state, organizationAircraft) => {
      state.organizationAircrafts.push(organizationAircraft)
    },

    CLEAR_ORGANIZATION_AIRCRAFTS_SHOWED: state => {
      state.organizationAircraftsShowed = []
    },
  },

  actions: {
    fetchOrganizationAircrafts({ commit, state, rootState }, refresh) {
      if (refresh || !state.organizationAircrafts.length) {
        state.queryParams.numberOfItemsPerPage === 0
        && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_ORGANIZATION_AIRCRAFTS_LOADING', true)
        fetchOrganizationAircraftsRequest(rootState.auth.selectedOrganization.id, { ...state.queryParams, [state.searchField]: state.searchText })
          .then(response => {
            commit('SET_ORGANIZATION_AIRCRAFTS', response.data.organizationAircrafts)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,
              firstPage: response.data.firstPage,
              previousPage: response.data.previousPage,
              nextPage: response.data.nextPage,
              lastPage: response.data.lastPage,
            })
            commit('CLEAR_ORGANIZATION_AIRCRAFTS_SHOWED')
          })
          .finally(() => {
            commit('SET_ORGANIZATION_AIRCRAFTS_LOADING', false)
          })
      }
    },

    setOrganizationAircraftsShowed({ commit }, organizationAircraft) {
      commit('SET_ORGANIZATION_AIRCRAFT_SHOWED', organizationAircraft)
    },

    clearOrganizationAircraftShowed({ commit }) {
      commit('CLEAR_ORGANIZATION_AIRCRAFT')
    },

    setSort({ commit }, sort) {
      commit('SET_SORT', sort)
    },

    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },
  },
}
