import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import i18n from '@/libs/i18n'

const _toastDelay = response => {
  let title = i18n.t('login.update_credential')
  if (response.data) {
    const fullName = (response.data.firstName && response.data.lastName)
      ? `${response.data.firstName} ${response.data.lastName}`
      : null

    title = fullName ? `${i18n.t('login.welcome')} ${fullName}` : i18n.t('registration.registration_user.welcome')
  }

  Vue.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon: 'EditIcon',
      variant: 'success',
    },
  })
}

const successHandler = response => {
  if (response.status >= 200 && response.status < 300) {
    let dontAlertUser = response.config.dontAlertUser || false
    if (!dontAlertUser) {
      setTimeout(_toastDelay(response), 250)
    }
    dontAlertUser = false
  }
  return response
}

export default successHandler
