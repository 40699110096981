import Vue from 'vue'

import i18n from '@/libs/i18n'

import { ValidationObserver, ValidationProvider, extend, localize, localeChanged } from 'vee-validate'

import en from 'vee-validate/dist/locale/en.json'

import * as rules from 'vee-validate/dist/rules'

import {
  validatorPositive,
  validatorUrl,
  validatorPassword,
  validatorCreditCard,
  validatorIban,
  validatorTel,
  validatorDecimalOrInt,
  validatorPercentage,
} from './vee-validate-specific-validators'

// Define ValidationObserver, ValidationProvider globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Install and set default locale to en
localize('en', en)

// set locale
export function setValidationLocale(code) {
  localize(code)
}

// lasy load locale and set locale
export function loadValidationLocale(code) {
  localeChanged()
  if (code === 'en') return localize('en')
  return import(`vee-validate/dist/locale/${code}.json`).then((locale) => {
    localize(code, locale)
  })
}

// GENERAL RULES
// all this rules are accessible directly in component
// SEE https://vee-validate.logaretm.com/v3/guide/rules.html#rules
// import in component like this
// <validation-provider rules="required|numeric" ...

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
  })
})

extend('decimalOrInt', {
  validate: validatorDecimalOrInt,
  message: i18n.t('validator.decimal_or_int'),
})

extend('percentage', {
  validate: validatorPercentage,
  message: i18n.t('validator.percentage'),
})

//SPECIFIC RULES
// import in component like this
// import { positive } from '@/libs/vee-validate'
// methods: {positive,}

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: validatorUrl,
  message: 'URL is invalid',
})

export const iban = extend('iban', {
  validate: validatorIban,
  message: i18n.t('validator.invalid_iban.message', localStorage.getItem('lang')),
})

export const tel = extend('tel', {
  validate: validatorTel,
  message: i18n.t('validator.invalid_tel.message', localStorage.getItem('lang')),
})
