import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

const data = {
  trips: [
    {
      organizationId: 100,
      status: 'Action required',
      estimatedFees: 1234,
      cancelled: false,
      fuel: 'Kerosene',
      passengersSeats: 15,
      tripSteps: [
        {
          id: 48575,
          fixedBasedOperatorId: 200,
          ICAO: 'LFBP',
          flightMission: 'PAX',
          flightType: 'COMMERCIAL',
          passengersNumber: 1,
          crewsNumber: 2,
          luggagesNumber: 4,
          bagsNumber: 5,
          disabledNumber: 1,
          type: 'flight',
          categorie: 'departure',
          UTC: 'Europe/London',
          city: 'Paris',
          date: '2021-08-02',
          time: '08:00',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
              {
                departure: 'Paris',
                arrival: 'Annecy',
              },
          passengers: [],
          slot: {
            UTC: 'Europe/London',
            start: 'Sun Aug 29 2021 09:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            end: 'Sun Aug 29 2021 10:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            valid: false,
          },
          providers: [{
            id: 200,
            name: 'Passenger transport between the aircraft and the terminal',
            slug: 'Provider-3',
            description: 'Eos autem debitis est temporibus animi sit porro commodi. Et ipsam laudantium et aliquam exercitationem aut minus vitae et incidunt cupiditate aut rerum eaque sit natus alias! Aut consequatur quasi aut architecto iste est deserunt doloribus non expedita dolor est alias omnis qui Quis voluptas.',
            brand: 'HeliExpress',
            price: 239,
            fees: 9,
            image: 'https://loremflickr.com/450/280/plane',
            type: 'service',
            isInWishlist: false,
            qty: 1,
            status: 'Action required',
            shippingDate: '2021-09-02T04:12:12.883Z',
            offers: 2,
            discountPercentage: 7,
          }],
        },
        {
          id: 51804,
          ICAO: 'LFMN',
          type: 'flight',
          categorie: 'arrival',
          UTC: 'Europe/London',
          city: 'Annecy',
          date: '2021-08-16',
          time: '09:00',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
              {
                departure: 'Paris',
                arrival: 'Bordeaux',
              },
          passengers: [],
          providers: [],
          slot: null,
        },
        {
          id: 67986,
          fixedBasedOperatorId: 200,
          flightMission: 'PAX',
          flightType: 'COMMERCIAL',
          passengersNumber: 1,
          crewsNumber: 2,
          luggagesNumber: 4,
          bagsNumber: 5,
          disabledNumber: 1,
          type: 'flight',
          categorie: 'departure',
          UTC: 'Europe/London',
          city: 'Lille',
          date: '2021-08-03',
          time: '10:00',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
              {
                departure: 'Lille',
                arrival: 'Bordeau',
              },
          passengers: [],
          slot: {
            UTC: 'Europe/London',
            start: 'Sun Aug 30 2021 09:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            end: 'Sun Aug 30 2021 10:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            valid: false,
          },
          providers: [{
            id: 200,
            name: 'Passenger transport between the aircraft and the terminal',
            slug: 'Provider-3',
            description: 'Eos autem debitis est temporibus animi sit porro commodi. Et ipsam laudantium et aliquam exercitationem aut minus vitae et incidunt cupiditate aut rerum eaque sit natus alias! Aut consequatur quasi aut architecto iste est deserunt doloribus non expedita dolor est alias omnis qui Quis voluptas.',
            brand: 'HeliExpress',
            price: 239,
            fees: 9,
            image: 'https://loremflickr.com/450/280/plane',
            type: 'service',
            isInWishlist: false,
            qty: 1,
            status: 'Action required',
            shippingDate: '2021-09-02T04:12:12.883Z',
            offers: 2,
            discountPercentage: 7,
          }],
        },
        {
          id: 9765,
          ICAO: 'HUTD',
          type: 'flight',
          categorie: 'arrival',
          UTC: 'Europe/London',
          city: 'Bordeaux',
          date: '2021-08-03',
          time: '11:30',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
              {
                departure: 'Lille',
                arrival: 'Bordeaux',
              },
          passengers: [],
          providers: [],
          slot: null,
        },
      ],
      name: 'M Dupuis From Paris to Bordeaux',
      flightType: 'IFR',
      passengersNumber: 3,
      crewsNumber: 3,
      aircraft: 'A380',
      id: 2,
    },
    {
      id: 3,
      name: 'Business trip from Paris to Annecy',
      flightType: 'IFR',
      passengersNumber: 3,
      crewsNumber: 4,
      aircraft: 'A380',
      organizationId: 100,
      status: 'Action required',
      estimatedFees: 1234,
      cancelled: false,
      fuel: 'Kerosene',
      passengersSeats: 15,
      tripSteps: [
        {
          id: 48575,
          ICAO: 'JUYF',
          fixedBasedOperatorId: 200,
          flightMission: 'PAX',
          flightType: 'COMMERCIAL',
          passengersNumber: 1,
          crewsNumber: 2,
          luggagesNumber: 4,
          bagsNumber: 5,
          disabledNumber: 1,
          type: 'flight',
          categorie: 'departure',
          UTC: 'Europe/London',
          city: 'Paris',
          date: '2021-08-02',
          time: '09:00',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
            {
              departure: 'Paris',
              arrival: 'Annecy',
            },
          passengers: [],
          slot: {
            UTC: 'Europe/London',
            start: 'Sun Aug 29 2021 09:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            end: 'Sun Aug 29 2021 10:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            valid: false,
          },
          providers: [{
            id: 200,
            name: 'Provider 3',
            slug: 'Provider-3',
            description: 'Eos autem debitis est temporibus animi sit porro commodi. Et ipsam laudantium et aliquam exercitationem aut minus vitae et incidunt cupiditate aut rerum eaque sit natus alias! Aut consequatur quasi aut architecto iste est deserunt doloribus non expedita dolor est alias omnis qui Quis voluptas.',
            brand: 'HeliExpress',
            price: 239,
            fees: 9,
            image: 'https://loremflickr.com/450/280/plane',
            type: 'service',
            isInWishlist: false,
            qty: 1,
            status: 'Action required',
            shippingDate: '2021-09-02T04:12:12.883Z',
            offers: 2,
            discountPercentage: 7,
          }],
        },
        {
          id: 51804,
          ICAO: 'IKUG',
          type: 'flight',
          categorie: 'arrival',
          UTC: 'Europe/London',
          city: 'Annecy',
          date: '2021-08-16',
          time: '10:20',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
            {
              departure: 'Paris',
              arrival: 'Bordeaux',
            },
          passengers: [],
          providers: [],
          slot: null,
        },
        {
          id: 67986,
          ICAO: 'UYFB',
          fixedBasedOperatorId: 200,
          flightMission: 'PAX',
          flightType: 'COMMERCIAL',
          passengersNumber: 1,
          crewsNumber: 2,
          luggagesNumber: 4,
          bagsNumber: 5,
          disabledNumber: 1,
          type: 'flight',
          categorie: 'departure',
          UTC: 'Europe/London',
          city: 'Lille',
          date: '2021-08-03',
          time: '11:00',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
              {
                departure: 'Lille',
                arrival: 'Bordeaux',
              },
          passengers: [],
          slot: {
            UTC: 'Europe/London',
            start: 'Sun Aug 30 2021 09:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            end: 'Sun Aug 30 2021 10:00:00 GMT+0200 (heure d’été d’Europe centrale)',
            valid: false,
          },
          providers: [{
            id: 200,
            name: 'Provider 3',
            slug: 'Provider-3',
            description: 'Eos autem debitis est temporibus animi sit porro commodi. Et ipsam laudantium et aliquam exercitationem aut minus vitae et incidunt cupiditate aut rerum eaque sit natus alias! Aut consequatur quasi aut architecto iste est deserunt doloribus non expedita dolor est alias omnis qui Quis voluptas.',
            brand: 'HeliExpress',
            price: 239,
            fees: 9,
            image: 'https://loremflickr.com/450/280/plane',
            type: 'service',
            isInWishlist: false,
            qty: 1,
            status: 'Action required',
            shippingDate: '2021-09-02T04:12:12.883Z',
            offers: 2,
            discountPercentage: 7,
          }],
        },
        {
          id: 9765,
          ICAO: 'OYRF',
          type: 'flight',
          categorie: 'arrival',
          UTC: 'Europe/London',
          city: 'Bordeaux',
          date: '2021-08-03',
          time: '12:00',
          status: 'Action required',
          flight: {
            type: 'Private',
            fuel: 'Biojet',
          },
          destination:
              {
                departure: 'Lille',
                arrival: 'Bordeaux',
              },
          passengers: [],
          providers: [],
          slot: null,
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Trips
// ------------------------------------------------
mock.onGet('/apps/trip/trips').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.trips.filter(
    trip => (trip.flightType.toLowerCase().includes(queryLowered)
        || trip.name.toLowerCase().includes(queryLowered))
      && trip.status === (status || trip.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      trips: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Trip
// ------------------------------------------------
mock.onGet(/\/apps\/trip\/trips\/\d+/).reply(config => {
  // Get event id from URL
  let tripId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  tripId = Number(tripId)

  const tripIndex = data.trips.findIndex(e => e.id === tripId)
  const trip = data.trips[tripIndex]
  const responseData = {
    trip,
  }

  if (trip) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// POST: Add new trip
// ------------------------------------------------
mock.onPost('/apps/trip/trips').reply(config => {
  const { trip } = JSON.parse(config.data)

  // Assign Status
  trip.status = 'Action required'

  const { length } = data.trips
  let lastIndex = 0
  if (length) {
    lastIndex = data.trips[length - 1].id
  }
  trip.id = lastIndex + 1

  data.trips.push(trip)

  return [201, { trip }]
})

// ------------------------------------------------
// POST: Add new trip
// ------------------------------------------------
mock.onPost('/apps/trip/trips').reply(config => {
  const { trip } = JSON.parse(config.data)

  // Assign Status
  trip.status = 'Action required'

  const { length } = data.trips
  let lastIndex = 0
  if (length) {
    lastIndex = data.trips[length - 1].id
  }
  trip.id = lastIndex + 1

  data.trips.push(trip)

  return [201, { trip }]
})

// ------------------------------------------------
// DELETE: Remove Item from user Cart
// ------------------------------------------------
mock.onDelete(/\/apps\/trip\/trips\/\d+/).reply(config => {
  // Get product id from URL
  let tripId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  tripId = Number(tripId)

  const tripIndex = data.trips.findIndex(i => i.id === tripId)
  if (tripIndex > -1) data.trips.splice(tripIndex, 1)

  return [200]
})
