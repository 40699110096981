import globalApi from '@/request/globalApi/globalApi'

const END_POINT = 'api/payment-method'

export const fetchPaymentMethodsRequest = params => globalApi.get(END_POINT, params)

export const fetchPaymentMethodRequest = paymentMethodId => globalApi.get(`${END_POINT}/${paymentMethodId}`)

export const postPaymentMethodRequest = (queryParam, message) => globalApi.post(END_POINT, queryParam, { message })

export const editPaymentMethodRequest = (paymentMethod, message) => globalApi.patch(`${END_POINT}/${paymentMethod.id}`, paymentMethod, {
  message,
})

export const deletePaymentMethodRequest = (paymentMethodId, message) => globalApi.delete(`${END_POINT}/${paymentMethodId}`, { message })
