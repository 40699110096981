import {
  fetchHangarSlicesNumber,
  fetchHangarsVariantsDashboardRequest,
  fetchHangarsVariantsRequest,
} from '@/request/globalApi/requests/hangarRequests'
import { pick } from 'lodash'

export default {
  namespaced: true,
  state: {
    dashboardLoading: false,
    hangarsVariantsOptionsLoading: false,
    slicesNumber: null,
    selectedSlices: [],
    dashboard: {},
    previousPage: null,
    nextPage: null,
    page: 1,
    totalItems: 0,
    hangarsVariantsOptions: [],
    selectedHangarVariant: null,
  },
  mutations: {
    SET_DASHBOARD_LOADING(state, loading) {
      state.dashboardLoading = loading
    },
    SET_HANGARS_VARIANTS_OPTIONS_LOADING(state, loading) {
      state.hangarsVariantsOptionsLoading = loading
    },
    SET_SLICES_NUMBER(state, slicesNumber) {
      state.slicesNumber = slicesNumber
    },
    SET_SELECTED_SLICES(state, selectedSlices) {
      state.selectedSlices = selectedSlices
    },
    SET_DASHBOARD(state, dashboard) {
      state.dashboard = dashboard
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_HANGARS_VARIANTS_OPTIONS(state, hangarsVariantsData) {
      Object.assign(state, {
        hangarsVariantsOptions: hangarsVariantsData.variants,
        ...pick(hangarsVariantsData, 'previousPage', 'nextPage', 'lastPage', 'totalItems'),
      })
    },
    SET_SELECTED_HANGAR_VARIANT(state, hangarVariant) {
      state.selectedHangarVariant = hangarVariant
    },
  },
  actions: {
    fetchHangarSlicesNumber({ commit, state }) {
      if (state.slicesNumber === null) {
        fetchHangarSlicesNumber().then(({ data }) => {
          commit('SET_SLICES_NUMBER', data.slicesNumber)
        })
      }
    },
    async fetchHangarsVariants({ commit, state }, refetch) {
      if (state.hangarsVariantsOptions.length && !refetch) return state.hangarsVariantsOptions
      commit('SET_HANGARS_VARIANTS_OPTIONS_LOADING', true)
      return fetchHangarsVariantsRequest({ page: state.page }).then(({ data }) => {
        commit('SET_HANGARS_VARIANTS_OPTIONS', data)
      }).finally(() => {
        commit('SET_HANGARS_VARIANTS_OPTIONS_LOADING', false)
      })
    },
    async fetchHangarsVariantsDashboard({ commit }, { variantId, queryParams = {} }) {
      commit('SET_DASHBOARD_LOADING', true)
      return fetchHangarsVariantsDashboardRequest(variantId, queryParams).then(({ data }) => {
        commit('SET_DASHBOARD', data)
      }).finally(() => {
        commit('SET_DASHBOARD_LOADING', false)
      })
    },
  },
}
