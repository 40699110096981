export default {
  namespaced: true,
  state: {
    categoryProductsNameShowed: [],
  },

  getters: {
    productNameById: (state) => (id) => {
      const product = state.categoryProductsNameShowed.find((item) => item.id === id)
      if (product) {
        return product.categoryProductsNameShowed
      }
    },
  },

  mutations: {
    // AWAIT {id:1,products[]}
    SET_CATEGORY_PRODUCTS_NAME_SHOWED: (state, categoryProductsName) => {
      state.categoryProductsNameShowed.push(categoryProductsName)
    },

    CLEAR_CATEGORY_PRODUCTS_NAME_SHOWED: (state) => {
      state.categoryProductsNameShowed = []
    },
  },

  actions: {
    // AWAIT {id:1,products[]}
    setCategoryProductsNameShowed({ commit }, categoryProductsName) {
      commit('SET_CATEGORY_PRODUCTS_NAME_SHOWED', categoryProductsName)
    },

    clearCategoryProductsNameShowed({ commit }) {
      commit('CLEAR_CATEGORY_PRODUCTS_NAME_SHOWED')
    },
  },
}
