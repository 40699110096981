import { getField, updateField } from 'vuex-map-fields'
import { fetchOrganizationExternalAccountsRequest } from '@/request/globalApi/requests/organizationRequests'

export default {
  namespaced: true,
  state: {
    organizationExternalAccountsLoading: true,
    organizationExternalAccounts: [],
    pagination: {
      totalItems: 0,
    },
    queryParams: {
      page: 1,
      numberOfItemsPerPage: 0,
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    SET_EXTERNAL_ACCOUNTS_LOADING: (state, loading) => {
      state.organizationExternalAccountsLoading = loading
    },

    SET_EXTERNAL_ACCOUNTS: (state, organizationExternalAccounts) => {
      state.organizationExternalAccounts = organizationExternalAccounts
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.queryParams.numberOfItemsPerPage = perPage
    },

  },

  actions: {
    fetchOrganizationExternalAccounts({ commit, state, rootState }, payload) {
      if (payload.refresh || !state.organizationExternalAccounts.length) {
        state.queryParams.numberOfItemsPerPage === 0
        && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_EXTERNAL_ACCOUNTS_LOADING', true)
        fetchOrganizationExternalAccountsRequest(payload.organizationId, { ...state.queryParams })
          .then(response => {
            commit('SET_EXTERNAL_ACCOUNTS', response.data.items)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,

            })
          })
          .finally(() => {
            commit('SET_EXTERNAL_ACCOUNTS_LOADING', false)
          })
      }
    },
  },
}
