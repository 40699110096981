export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/base/Home.vue'),
    meta: {
      title: 'navigation.home',
      action: 'read',
      resource: 'DEFAULT',
      breadcrumb: [
        {
          text: 'navigation.home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/base/Login.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.login.title',
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/base/ForgotPassword.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.login.title',
      layout: 'full',
    },
  },
  {
    path: '/forgot-password-validation',
    name: 'forgot-password-validation',
    component: () => import('@/views/base/ForgotPasswordValidation.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.login.title',
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/base/ResetPassword.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.login.title',
      layout: 'full',
    },
  },
  {
    path: '/reset-password-validation',
    name: 'reset-password-validation',
    component: () => import('@/views/base/ResetPasswordValidation.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.login.title',
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/base/Register.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.register.title',
      layout: 'full',
    },
  },
  {
    path: '/register-validation/:id',
    name: 'register-validation',
    component: () => import('@/views/base/register/RegisterValidation.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.register.title',
      layout: 'full',
    },
  },
  {
    path: '/register-validate',
    name: 'register-validate',
    component: () => import('@/views/base/register/RegisterValidate.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.register.title',
      layout: 'full',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/base/Contact.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      title: 'navigation.login.title',
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/base/NotAuthorized.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/base/Maintenance.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
  {
    path: '*',
    component: () => import('@/views/base/Error404.vue'),
    meta: {
      action: 'read',
      resource: 'DEFAULT',
      layout: 'full',
    },
  },
]
