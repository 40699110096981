export default [
  {
    path: '/organization/:organization_id/user/:organizationUser_id',
    name: 'user-organization-details',
    component: () => import('@/views/user/user-view/UserDetails.vue'),
    meta: {
      title: 'navigation.organization.user.title',
      action: 'ORGANIZATION_USER_VIEW',
      breadcrumb: [
        {
          text: 'navigation.organization.title',
          to: '/organization',
        },
        {
          text: 'navigation.organization.user.title',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/organization/:organization_id/user-edit/:organizationUser_id',
    name: 'user-organization-edit',
    component: () => import('@/views/user/user-edit/UserEdit.vue'),
    meta: {
      title: 'navigation.organization.user.edit',
      action: 'ORGANIZATION_USER_EDIT',
      breadcrumb: [
        {
          text: 'navigation.organization.title',
          to: '/organization',
        },
        {
          text: 'navigation.organization.user.edit',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/users/:organizationUser_id',
    name: 'user-details',
    component: () => import('@/views/user/user-view/UserDetails.vue'),
    meta: {
      title: 'navigation.user.edit',
      action: 'ORGANIZATION_USER_VIEW',
      breadcrumb: [
        {
          text: 'navigation.user.edit',
          active: true,
        },
      ],
    },
  },
]
