/* eslint-disable no-console */

import Vue from 'vue'
import '@/@fake-db/db'
import { ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

// import { Integrations } from '@sentry/tracing'
// import * as Sentry from '@sentry/vue'
// 3RD PARTY LIBRARIES
import '@/libs/abilityPlugin'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alert-2'
import '@/libs/google-maps'
import '@/libs/stripes'
import '@/libs/vee-validate'
import '@/libs/vue-select'
import '@/libs/flat-pickr'
import '@/libs/boostrap-vue'
import '@/libs/custom-components'
import '@/libs/font-awesome-icon'
import '@/libs/vue-font-awesome-picker'
import '@/libs/recaptcha'

// PLUGINS
import '@/plugins/moment'

// FILTERS
import '@/filters/filters'

// DIRECTIVES
import '@/directives/vue-ripple-directive'
import '@/directives/b-tooltip-directive'
import '@/directives/b-toggle-directive'
import '@/directives/scroll-to-directive'

// MAJOR LIBRARIES
import i18n from '@/libs/i18n'
import ability from '@/libs/ability'

// LEGACY LIBRARIES
import store from '@/store/store'
import router from '@/router/router'

import App from './App.vue'

import './helpers/alert'

// PROTOTYPE
import '@/prototype/lodash'
import '@/prototype/getMeasurementUnitItn'

// BSV Plugin Registration
Vue.use(ModalPlugin) // Useful for $BVModal

// Composition API
Vue.use(VueCompositionAPI)

// ERRORS LOGING AND TRACKING
if (process.env.NODE_ENV !== 'production') {
  Vue.config.errorHandler = err => {
    console.error(err)
  }

  Vue.config.productionTip = true
} else {
  Vue.config.productionTip = false
}

if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_ENV) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [new BrowserTracing()], // for local testing { tracingOrigins: ['*'] }
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    ignoreErrors: ['NavigationDuplicated'],
  })
}

// SCSS
require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

new Vue({
  ability,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
