//  `long` is a i18n key for $t('measurement.unit.LONG')
const MEASUREMENT = {
  metric: {
    cm: { abbr: 'cm', long: 'centimeter' },
    m: { abbr: 'm', long: 'meter' },
    km: { abbr: 'km', long: 'kilometer' },
    m2: { abbr: 'm²', long: 'square_meter' },
    l: { abbr: 'L', long: 'liter' },
    g: { abbr: 'g', long: 'gram' },
    kg: { abbr: 'kg', long: 'kilogram' },
    epl: { abbr: '€/L', long: 'currency_per_liter' },
  },
  imperial: {
    cm: { abbr: 'in', long: 'inch' },
    m: { abbr: 'ft', long: 'foot' },
    km: { abbr: 'mi', long: 'mile' },
    m2: { abbr: 'sq yd', long: 'square_yard' },
    l: { abbr: 'gal', long: 'gallon' },
    g: { abbr: 'oz', long: 'ounce' },
    kg: { abbr: 'lb', long: 'pound' },
    epl: { abbr: '€/USG', long: 'currency_per_gallon' },
  },
}

export default MEASUREMENT
