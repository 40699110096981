import { getField, updateField } from 'vuex-map-fields'
import {
  fetchItemFiltersRequest,
  fetchItemsRequest,
  fetchItemStateRequest,
} from '@/request/globalApi/requests/accountingRequests'

export default {
  namespaced: true,
  state: {
    itemsLoading: false,
    itemStateLoading: false,
    itemState: null,
    pagination: { totalItems: 0 },
    paginate: {
      page: 1,
      numberOfItemsPerPage: 0,
    },
    dataTableSort: {
      sortBy: 'reference',
      sortDesc: false,
    },
    searchQuery: null,
    order: {
      referenceOrder: 'asc',
    },
    filter: {
      startAt: '',
      endAt: '',
      statesCodes: [],
      invoiceType: [],
      invoiceState: [],
      paymentMethod: [],
    },
    filtersOptionsLoading: false,
    filtersOptions: null,
    items: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.paginate.numberOfItemsPerPage = perPage
    },

    SET_PAGE: (state, page) => {
      state.paginate.page = page
    },

    SET_ITEMS_LOADING: (state, loading) => {
      state.itemsLoading = loading
    },

    SET_ITEMS: (state, items) => {
      state.items = items
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SEARCH: (state, search) => {
      state.searchQuery = search
      Object.keys(state.search).forEach(key => {
        state.search[key] = ''
      })
      state.search[state.searchField] = search
    },

    SET_SORT: (state, sort) => {
      state.order = {}
      state.order[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_ITEM_STATE: (state, data) => {
      state.itemState = data
    },

    SET_ITEM_FILTERS_OPTION_LOADING: (state, loading) => {
      state.filtersOptionsLoading = loading
    },

    SET_ITEM_FILTERS_OPTION: (state, filtersOptions) => {
      state.filtersOptions = filtersOptions
    },

    SET_ITEM_STATE_LOADING: (state, loading) => {
      state.itemStateLoading = loading
    },

    CLEAR_FILTERS: state => {
      Object.keys(state.filter).forEach(key => {
        state.filter[key] = []
      })
    },
  },
  actions: {
    fetchItems({ commit, state, rootState }, refresh) {
      if (refresh || !state.items.length) {
        state.paginate.numberOfItemsPerPage === 0
          && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_ITEMS_LOADING', true)
        fetchItemsRequest({ ...state.paginate, ...state.order, searchQuery: state.searchQuery, ...state.filter })
          .then(response => {
            commit('SET_ITEMS', response.data.invoices)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,
            })
          })
          .finally(() => {
            commit('SET_ITEMS_LOADING', false)
          })
      }
    },

    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },

    setSort({ commit, dispatch }, sort) {
      commit('SET_SORT', sort)
      dispatch('fetchItems', true)
    },

    setPage({ commit, dispatch }, page) {
      commit('SET_PAGE', page)
      dispatch('fetchItems', true)
    },

    fetchItemFiltersOption({ commit, state }) {
      if (state.filtersOptions === null) {
        commit('SET_ITEM_FILTERS_OPTION_LOADING', true)
        fetchItemFiltersRequest()
          .then(response => {
            commit('SET_ITEM_FILTERS_OPTION', response.data)
          })
          .finally(() => {
            commit('SET_ITEM_FILTERS_OPTION_LOADING', false)
          })
      }
    },

    clearFilters({ commit, dispatch }) {
      commit('CLEAR_FILTERS')
      dispatch('fetchItems', true)
    },

    fetchItemState({ commit, state }) {
      if (state.filtersOptions === null) {
        commit('SET_ITEM_STATE_LOADING', true)
        fetchItemStateRequest()
          .then(response => {
            commit('SET_ITEM_STATE', response.data)
          })
          .finally(() => {
            commit('SET_ITEM_STATE_LOADING', false)
          })
      }
    },
  },
}
