import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/offer'

// OFFER

export const fetchOffersRequest = params => globalApi.get(END_POINT, { params })

export const postOfferRequest = (offer, message) => globalApi.post(END_POINT, offer, { message })

export const deleteOfferRemoveRequest = (offerId, message) => fireDeleteAlert(true).then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${offerId}/remove`, { message }),
)

export const deleteOfferDeleteRequest = (offerId, offer, message) => fireDeleteAlert(true).then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${offerId}/delete`, { message, params: offer }),
)

// INFO

export const fetchOfferInfoRequest = offerId => globalApi.get(`${END_POINT}/${offerId}/info`)

export const patchOfferInfoRequest = (offerId, offerInfo, message) => globalApi.patch(`${END_POINT}/${offerId}/info`, offerInfo, { message })

// TYPOLOGY

export const fetchOfferTypologiesRequest = (offerId, params) => globalApi.get(`${END_POINT}/${offerId}/typology`, { params })

export const patchOfferTypologyRequest = (offerId, offerTypology, message) => globalApi.patch(`${END_POINT}/${offerId}/typology`, offerTypology, { message })

// COMISSION

export const fetchOfferCommissionsRequest = (offerId, params) => globalApi.get(`${END_POINT}/${offerId}/commission`, { params })

export const patchOfferCommissionRequest = (offerId, offerCommission, message) => globalApi.patch(`${END_POINT}/${offerId}/commission`, offerCommission, { message })

// COMISSION SLICE

export const postOfferCommissionSliceRequest = (offerId, offerCommissionId, slice, message) => globalApi.post(`${END_POINT}/${offerId}/commission/${offerCommissionId}/slice`, slice, { message })

export const patchOfferCommissionSliceRequest = (offerId, offerCommissionId, slice, message) => globalApi.patch(`${END_POINT}/${offerId}/commission/${offerCommissionId}/slice/${slice.id}`, slice, { message })

export const deleteOfferCommissionSliceRequest = (offerId, offerCommissionId, sliceId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed
      && globalApi.delete(`${END_POINT}/${offerId}/commission/${offerCommissionId}/slice/${sliceId}`, { message }),
)

// COMISSION CATEGORY

export const postOfferCommissionCategoryRequest = (offerId, categoriesIds, message) => globalApi.post(`${END_POINT}/${offerId}/commission-category`, categoriesIds, { message })

export const patchOfferCommissionCategoryRequest = (offerId, offerCommissionId, categoriesIds, message) => globalApi.patch(`${END_POINT}/${offerId}/commission-category/${offerCommissionId}`, categoriesIds, { message })

export const deleteOfferCommissionCategoryRequest = (offerId, offerCommissionId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${offerId}/commission-category/${offerCommissionId}`, { message }),
)

// SUBSCRIBER

export const fetchOfferSubscribersRequest = (offerId, params) => globalApi.get(`${END_POINT}/${offerId}/subscriber`, { params })

// SUBSCRIPTION

export const fetchOfferSubscriptionRequest = (offerId, params) => globalApi.get(`${END_POINT}/${offerId}/subscription`, { params })

export const patchOfferSubscriptionRequest = (offerId, offerSubscription, message) => globalApi.patch(`${END_POINT}/${offerId}/subscription`, offerSubscription, { message })
