export default [
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/category/CategoriesList.vue'),
    meta: {
      title: 'navigation.categories',
      action: 'OPERATOR_CATEGORY_VIEW',
      breadcrumb: [
        {
          text: 'navigation.categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/category/:category_id',
    name: 'category-edit',
    component: () => import('@/views/category/CategoryEdit.vue'),
    meta: {
      title: 'navigation.category.edit',
      action: 'OPERATOR_CATEGORY_EDIT',
      breadcrumb: [
        {
          text: 'navigation.categories',
          to: '/categories',
        },
        {
          text: 'navigation.category.edit',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
  {
    path: '/category',
    name: 'category-add',
    component: () => import('@/views/category/CategoryAdd.vue'),
    meta: {
      title: 'navigation.category.add',
      action: 'OPERATOR_CATEGORY_ADD',
      breadcrumb: [
        {
          text: 'navigation.categories',
          to: '/categories',
        },
        {
          text: 'navigation.category.add',
          active: true,
        },
      ],
      backBtn: true,
    },
  },
]
