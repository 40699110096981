<template>
  <validation-provider v-slot="{ errors }" :vid="vid || id" :rules="computedRules" :name="localVvName" :class="{ 'validation-required': isRequired }">
    <component
      :is="label ? 'b-form-group' : 'div'"
      :class="classGroup"
      :label="label || undefined"
      :label-for="label ? id : undefined"
      :label-cols="labelCols"
      :label-cols-lg="labelColsLg"
      :label-cols-md="labelColsMd"
      :label-cols-sm="labelColsSm"
      :label-cols-xl="labelColsXl"
    >
      <b-overlay :show="overlay" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
        <b-input-group :append="append" :class="classInputGroup" :style="localStyleGroup">
          <b-form-input
            :id="id"
            :ref="`AppInput-${id}`"
            :value="value"
            :title="localTitle"
            :class="[classInput]"
            :style="styleInput"
            :state="errors[0] ? false : state"
            :placeholder="placeholder || label"
            :required="isRequired"
            v-bind="$attrs"
            v-on="{
              ...$listeners,
              input: val => textChange(val),
            }"
          />
          <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
          </template>
          <!-- <b-form-invalid-feedback v-if="!hideErrors" tag="small">
            {{ deleteFieldText(errors[0]) }}
          </b-form-invalid-feedback> -->
        </b-input-group>
      </b-overlay>
      <small v-if="!hideErrors && errors[0]" class="text-danger">
        {{ deleteFieldText(errors[0]) }}
      </small>
      <slot name="error" :errors="errors" />
    </component>
  </validation-provider>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { omit } from 'lodash'

// https://vee-validate.logaretm.com/v3/api/validation-provider.html#scoped-slot-props
// TODO: Mixin for field form components

/**
 * @example
 * <app-input
    id="hookUpFee"
    v-model.number="editedAirport.hookUpFee"
    :label="$t('service.fuel.prices.hook_up_fees')"
    type="number"
    step="0.01"
    rules="positive"
    append="%"
    :overlay="!isLoaded"
  />
 */

export default defineComponent({
  name: 'AppInput',

  props: {
    value: [Number, String],
    vid: { type: String, default: null },
    id: { type: String, default: undefined },
    append: { default: undefined },
    name: { type: String, default: undefined },
    classGroup: { type: String, default: undefined },
    styleGroup: { type: Object, default: () => ({}) },
    classInputGroup: { type: String, default: undefined },
    classInput: { type: String, default: undefined },
    styleInput: { type: Object, default: () => ({}) },
    vvName: { type: [Boolean, String], default: false },
    label: { type: String, default: '' },
    title: { type: [Boolean, String], default: null },
    labelCols: { type: [String, Number], default: undefined },
    labelColsLg: { type: [String, Number], default: undefined },
    labelColsMd: { type: [String, Number], default: undefined },
    labelColsSm: { type: [String, Number], default: undefined },
    labelColsXl: { type: [String, Number], default: undefined },
    autoResize: { type: Boolean, default: false },
    overlay: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    rules: { type: String, default: '' },
    rulesName: { type: String, default: '' },
    required: { type: Boolean, default: false },
    hideErrors: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
  },

  setup(props, { emit: $emit }) {
    const isRequired = computed(() => props.rules.includes('required') || props.required)

    const fs = ref(1)
    const localStyleGroup = computed(() => {
      if (typeof props.styleGroup === 'string') return props.styleGroup
      return props.autoResize
        ? {
          minWidth: '20em',
          width: props.value ? `${props.value.length * fs.value}em` : '20em',
          maxWidth: '100% !important',
          ...props.styleGroup,
        }
        : { ...props.styleGroup }
    })
    const localTitle = computed(() => {
      if (typeof props.title === 'string') return props.title
      if (props.title && props.value) return props.placeholder
      return ''
    })
    const inputWidth = ref('inherit')
    const localVvName = ref(null)
    if (props.vvName) localVvName.value = props.vvName === true ? props.label : props.vvName
    else localVvName.value = props.name

    const computedRules = computed(() => {
      let normalizeRules = props.rules
      if (isRequired.value && !normalizeRules.includes('required')) {
        if (!normalizeRules) normalizeRules = 'required'
        else normalizeRules += '|required'
      }
      return normalizeRules
    })

    // Trick to prevent 'The {field} field must be ...'
    // But bad solution about langs, I suppose...
    // vvName with default to ' ' seems work, but break the validator
    const deleteFieldText = msg => (msg && !props.vvName && !props.name ? msg.replace(/( {field})/, '') : msg)

    const textChange = val => {
      $emit('input', val !== '' ? val : null)
      if (props.autoResize) {
        fs.value = document.getElementById(props.id).style.fontSize.match(/(\d*\.)?\d+/)[0] * 0.6
      }
    }

    return {
      omit,
      isRequired,
      localVvName,
      computedRules,
      deleteFieldText,
      textChange,
      inputWidth,
      localStyleGroup,
      localTitle,
      fs,
    }
  },
})
</script>
