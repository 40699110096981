import Vue from 'vue'
import moment from 'moment'
import store from '@/store/store'
import i18n from '@/libs/i18n'
import DATE from '@/constants/dateFormat'
import { snakeCase } from 'lodash'

const numberOrZero = number => (!Number.isNaN(number) && number !== undefined ? number : 0)

Vue.filter('capitalize', value => {
  if (!value) return ''
  return value.toString().charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('trans', transObject => {
  if (typeof transObject === 'string') return transObject // ! bad utilization
  if (!transObject || typeof transObject !== 'object') return '' // ! bad utilization
  return transObject[store.state.appConfig.lang] || transObject[store.state.auth.defaultLang] || transObject.en
})

// ? BE CAREFUL: enumTranslate from helper has its params inversed about it! (enumClass, enumValue)
Vue.filter('enumTranslate', (enumValue, enumClass, snakeCaseText = false) => {
  if (i18n.te(`enum.${snakeCase(enumClass)}.${!snakeCaseText ? enumValue : snakeCase(enumValue)}`)) {
    return i18n.t(`enum.${snakeCase(enumClass)}.${!snakeCaseText ? enumValue : snakeCase(enumValue)}`)
  }
  return enumValue
})

Vue.filter('priceFormat', number => {
  const nb = numberOrZero(number)
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: store.state.appConfig.currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(nb)
})

Vue.filter('percentFormat', number => {
  const nb = numberOrZero(number)
  return new Intl.NumberFormat(i18n.locale, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(nb / 100)
})

Vue.filter('decimalFormat', number => {
  const nb = numberOrZero(number)
  return new Intl.NumberFormat(i18n.locale, {
    currency: store.state.appConfig.currency,
    minimumFractionDigits: 2,
  }).format(nb)
})

Vue.filter('dateFormat', (date, format = 'SMALL_DATE_FORMAT') => moment(date).format(DATE[format]))

Vue.filter('dateFormatUtc', (date, format = 'SMALL_DATE_FORMAT') => moment.utc(date).format(DATE[format]))

Vue.filter('timeFormatUtc', (date, format = 'TIME_FORMAT') => moment.utc(date).format(DATE[format]))
