import globalApi from '@/request/globalApi/globalApi'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStripeIntent() {
      return globalApi.get('/api/my-account/setup-intent')
    },
    fetchStripeIntentToken(ctx, tokenNum) {
      return globalApi.get(`/api/setup-intent/${tokenNum}`)
    },
    fetchAvailablePayment(code) {
      return globalApi.get(`/api/available-payment-methods/${code}`)
    },
  },
}
