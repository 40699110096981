import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import i18n from '@/libs/i18n'

const _toastDelay = (response, method, successTitle, forceToast) => {
  if (response.config.method === 'post' && !forceToast) {
    Vue.swal({
      title: i18n.t('request.status.success', {
        msg: successTitle || method,
      }),
      text: response.config.message || '',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    })
  } else {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('request.status.success', {
          msg: successTitle || method,
        }),
        text: response.config.message || '',
        icon: 'CheckCircleIcon',
        variant: 'success',
      },
    })
  }
}

const successHandler = response => {
  if (response.status >= 200 && response.status < 300) {
    let dontAlertUser = response.config.dontAlertUser || false
    let method = ''
    const forceToast = response.config.forceToast || false
    const successTitle = response.config.successTitle || null
    const action = response.config.action || response.config.method
    switch (action) {
      case 'post':
        method = i18n.t('request.action.add')
        break
      case 'patch':
        method = i18n.t('request.action.update')
        break
      case 'delete':
        method = i18n.t('request.action.delete')
        break
      default:
        dontAlertUser = true
        break
    }
    if (!dontAlertUser) {
      setTimeout(_toastDelay(response, method, successTitle, forceToast), 250)
    }
    dontAlertUser = false
  }
  return response
}

export default successHandler
