import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

const data = {
  aircrafts: [
    {
      fullName: null,
      name: 'Challenger 350',
      email: '',
      role: null,
      currentPlan: null,
      company: 'Eagle Eye - France',
      contact: '',
      category: '4',
      manufacturer: 'Bombardier',
      MTOW: '18.416',
      height: '6.1',
      length: '20.9',
      wingspan: '21',
      distanceMTOW: '1474',
      maxFuel: '2344',
      measurementUnity: 'litres',
      registration: 'GBOPV',
      callSign: 'G-BOPV',
      homebase: 'London Lutton',
      numberSeat: '10',
      fireFighting: '4',
      AOC: 'GB790566KA',
      typeFuel: 'JetFuel',
      fuelVolume: '34555',
      fuelMeasurementUnity: 'litres',
      status: 'active',
      id: 5,
    },
    {
      fullName: null,
      name: 'Citation CJ3',
      email: '',
      role: null,
      currentPlan: null,
      company: 'Eagle Eye - France',
      contact: '',
      category: '4',
      manufacturer: 'Cessna',
      MTOW: '6.291',
      height: '4.6',
      length: '15.6',
      wingspan: '16.26',
      distanceMTOW: '969',
      maxFuel: '2344',
      measurementUnity: 'litres',
      registration: 'GO8TG65T675',
      callSign: 'F-GBPS',
      homebase: 'Paris le Bourget',
      numberSeat: '8',
      fireFighting: '4',
      AOC: 'FR6660799BD',
      typeFuel: 'JetFuel',
      fuelVolume: '34555',
      fuelMeasurementUnity: 'litres',
      status: 'active',
      id: 6,
    },
    {
      fullName: null,
      name: 'Falcon 8X',
      email: '',
      role: null,
      currentPlan: null,
      company: 'Eagle Eye - France',
      contact: '',
      category: 'Motor',
      manufacturer: 'Dassault Aviation ',
      MTOW: '33.113',
      height: '7.94',
      length: '24.46',
      wingspan: '26.29',
      distanceMTOW: '1829',
      maxFuel: '2344',
      measurementUnity: 'litres',
      registration: 'GO8TG65T675',
      callSign: 'G-GPAI',
      homebase: 'London Lutton',
      numberSeat: '14',
      fireFighting: '5',
      AOC: 'GB790566KB',
      typeFuel: 'JetFuel',
      fuelVolume: '34555',
      fuelMeasurementUnity: 'litres',
      status: 'active',
      id: 7,
    },
    {
      fullName: null,
      name: 'Falcon 900LX',
      email: '',
      role: null,
      currentPlan: null,
      company: 'Eagle Eye - France',
      contact: '',
      category: '4',
      manufacturer: 'Dassault Aviation',
      MTOW: '22.225',
      height: '7.55',
      length: '20.21',
      wingspan: '21.38',
      distanceMTOW: '1633',
      maxFuel: '2344',
      measurementUnity: 'litres',
      registration: 'GO8TG65T675',
      callSign: 'F-GPAP',
      homebase: 'Paris le Bourget',
      numberSeat: '6',
      fireFighting: '4',
      AOC: 'FR6660799BE',
      typeFuel: 'JetFuel',
      fuelVolume: '34555',
      fuelMeasurementUnity: 'litres',
      status: 'active',
      id: 8,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Aircrafts
// ------------------------------------------------
mock.onGet('/apps/aircraft/aircrafts').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
  } = config.params

  const queryLowered = q.toLowerCase()
  const filteredData = data.aircrafts.filter(
    aircraft =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (aircraft.name.toLowerCase().includes(queryLowered) || aircraft.name.toLowerCase().includes(queryLowered)),
  )

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      aircrafts: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new aircraft
// ------------------------------------------------
mock.onPost('/apps/aircraft/aircrafts').reply(config => {
  // Get event from post data
  const { aircraft } = JSON.parse(config.data)
  // Assign Status
  aircraft.status = 'active'

  const { length } = data.aircrafts
  let lastIndex = 0
  if (length) {
    lastIndex = data.aircrafts[length - 1].id
  }
  aircraft.id = lastIndex + 1

  data.aircrafts.push(aircraft)

  return [201, { aircraft }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/aircraft\/aircrafts\/\d+/).reply(config => {
  // Get event id from URL
  let aircraftId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  aircraftId = Number(aircraftId)

  const aircraftIndex = data.aircrafts.findIndex(e => e.id === aircraftId)
  const aircraft = data.aircrafts[aircraftIndex]

  if (aircraft) return [200, aircraft]
  return [404]
})
