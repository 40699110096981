import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/trip'

// MAIN
export const fetchTripsRequest = queryParams => globalApi.get(`${END_POINT}`, { params: queryParams })

export const fetchTripsCalendarRequest = queryParams => globalApi.get(`${END_POINT}/calendar`, { params: queryParams })

export const postTripRequest = ({ trip, message }) => globalApi.post(`${END_POINT}`, trip, { message })

export const deleteTripRequest = (tripId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${tripId}/delete`, { message }),
)

export const removeTripRequest = ({ tripId, message }) => globalApi.delete(`${END_POINT}/${tripId}/remove`, { message })

// INFOS
export const fetchTripInfosRequest = tripId => globalApi.get(`${END_POINT}/${tripId}/infos`)

export const fetchTripOrganizationInfosRequest = tripId => globalApi.get(`${END_POINT}/${tripId}/organization-infos`)

export const postTripInfosRequest = ({ trip, message }) => globalApi.post(`${END_POINT}/${trip.id}/infos`, trip, { message })

export const editTripInfosRequest = ({ trip, message }) => globalApi.patch(`${END_POINT}/${trip.id}/infos`, trip, { message })

// AIRCRAFT
export const postTripAircraftsRequest = ({ trip, message }) => globalApi.post(`${END_POINT}/${trip.id}/aircraft`, trip, { message })

export const editTripAircraftRequest = ({ trip, message }) => globalApi.patch(`${END_POINT}/${trip.id}/aircraft`, trip, { message })

export const fetchAircraftFilters = () => globalApi.get(`${END_POINT}-filters`)
