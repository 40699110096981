import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/enum'

export const fetchEnumerationsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchEnumerationRequest = enumClass => globalApi.get(`${END_POINT}/${enumClass}`)

export const postEnumerationRequest = (enumeration, message) => globalApi.post(END_POINT, enumeration, { message })

export const patchEnumerationRequest = (enumeration, message) => globalApi.patch(`${END_POINT}/${enumeration.enumClass}`, enumeration, { message })

export const deleteEnumerationRequest = (enumClass, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${enumClass}`, { message }))
