export default [
  {
    path: 'informations',
    name: 'offerInformations',
    children: [
      {
        path: 'duplicate',
        name: 'offerInformationsDuplicate',
        meta: {
          title: 'offer.title',
          pln: 1,
          action: 'OPERATOR_SUBSCRIPTION_ADD',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/offers',
            },
            {
              text: 'offer.information.title',
              pln: 0,
              active: true,
            },
            {
              text: 'action.duplicate',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: 'show',
        name: 'offerInformationsShow',
        meta: {
          title: 'offer.title',
          pln: 1,
          action: 'OPERATOR_SUBSCRIPTION_VIEW',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/offers',
            },
            {
              text: 'offer.information.title',
              pln: 0,
              active: true,
            },
            {
              text: 'action.show',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
      {
        path: 'edit',
        name: 'offerInformationsEdit',
        meta: {
          title: 'offer.title',
          pln: 1,
          action: 'OPERATOR_SUBSCRIPTION_EDIT',
          breadcrumb: [
            {
              text: 'action.list',
              to: '/offers',
            },
            {
              text: 'offer.information.title',
              pln: 0,
              active: true,
            },
            {
              text: 'action.edit',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
    ],
  },
]
