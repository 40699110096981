import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import RouterHelper from '@/helpers/routerHelper'

Vue.use(VueRouter)

const context = require.context('./routes', false, /^(?!.*Child).*\.js$/)
const routes = []
context.keys().forEach(file => {
  const route = context(file).default || context(file)
  routes.push(...route)
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach((to, _from, next) => {
  const publicRoutes = [
    'login',
    'contact',
    'forgot-password',
    'forgot-password-validation',
    'reset-password',
    'reset-password-validation',
    'registration',
    'register',
    'register-validation',
    'register-validate',
    'registration-validation',
    'registration-user',
    'registration-user-valid',
    'registration-user-validation',
    'legal-notice',
    'privacy-policy',
    'terms-of-use',
  ]

  // TODO
  // If we force to clear local storage and session storage, and refresh, the url is:
  // http://localhost:8890/login?redirect=%5Bobject%20Object%5D, and keep the login page after the login, who successed. How fix it?
  if (store.state.auth.isAuthorized) {
    if (RouterHelper.canNavigate(to)) next()
    else next({ name: 'not-authorized' })
  } else if (publicRoutes.includes(to.name)) {
    next()
  } else {
    next({ name: 'login', query: { redirect: to.path } })
  }
})

export default router
