/* eslint-disable import/no-cycle */
import axios from 'axios'
import config from '@/request/axiosBaseConfig'

import successHandler from '@/request/globalApi/interceptors/globalApiSucessInterceptor'
import errorHandler from '@/request/globalApi/interceptors/globalApiErrorInterceptor'
import error401Handler from '@/request/globalApi/interceptors/globalApiError401Interceptor'
import requestHandler from '@/request/globalApi/interceptors/globalApiRequestInterceptor'

const globalApi = axios.create(config)

globalApi.interceptors.response.use(
  response => successHandler(response),
  error => {
    const error401 = error401Handler(error)
    !error401 && errorHandler(error)
    return Promise.reject(error)
  },
)

globalApi.interceptors.request.use(
  requestConfig => requestHandler(requestConfig),
  error => error,
)

export default globalApi
