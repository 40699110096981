const getDefaultState = () => ({
  offerSubscriptionsShowed: [],
})

const defaultState = getDefaultState()

const getters = {
  offerSubscriptionById: state => id => {
    const offer = state.offerSubscriptionsShowed.find(item => item.id === id)
    if (offer) {
      return offer.offerSubscription
    }
    return null
  },
}

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  // AWAIT {id:1,subscribtion{}}
  SET_OFFER_SUBSCRIPTIONS_SHOWED: (state, offerSubscription) => {
    state.offerSubscriptionsShowed.push(offerSubscription)
  },

  CLEAR_OFFER_SUBSCRIPTIONS_SHOWED: state => {
    state.offerSubscriptionsShowed = []
  },

  UPDATE_OFFER_SUBSCRIPTIONS_SHOWED: (state, offerSubscription) => {
    const index = state.offerSubscriptionsShowed.findIndex(item => item.id === offerSubscription.id)
    state.offerSubscriptionsShowed[index] = offerSubscription
  },
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  // AWAIT {id:1,subscribtion{}}
  setOfferSubscriptionsShowed({ commit }, offerSubscription) {
    commit('SET_OFFER_SUBSCRIPTIONS_SHOWED', offerSubscription)
  },

  clearOfferSubscriptionsShowed({ commit }) {
    commit('CLEAR_OFFER_SUBSCRIPTIONS_SHOWED')
  },

  updateOfferSubscriptionsShowed({ commit }, offerSubscription) {
    commit('UPDATE_OFFER_SUBSCRIPTIONS_SHOWED', offerSubscription)
  },
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
