import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/price-management'

export const fetchPriceManagementsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchPriceManagementRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postPriceManagementRequest = (priceManagement, message) => globalApi.post(END_POINT, priceManagement, { message })

export const patchPriceManagementRequest = (priceManagement, message) => globalApi.patch(`${END_POINT}/${priceManagement.id}`, priceManagement, { message })

export const deletePriceManagementRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }))
