export default [
  {
    path: '/crews',
    name: 'crews',
    component: () => import('@/views/crew/CrewList.vue'),
    meta: {
      title: 'navigation.crew_management',
      action: 'CREW_VIEW',
      breadcrumb: [
        {
          text: 'navigation.crew_management',
          active: true,
        },
      ],
    },
  },
]
