/* eslint-disable import/no-cycle */
import globalApi from '@/request/globalApi/globalApi'
import authApi from '@/request/authApi/authApi'

const END_POINT = 'api'

export const postLoginCheckRequest = credentials => authApi.post(`${END_POINT}/login_check`, credentials)

export const postTokenRefreshRequest = refreshToken => authApi.post(`${END_POINT}/token/refresh`, refreshToken, {
  dontAlertUser: true,
})

export const postClientRegisterRequest = requirements => authApi.post('/client-register', requirements)

export const postSellerRegisterRequest = requirements => authApi.post('/seller-register', requirements)

export const patchRegisterNewValidationMailRequest = (id, redirectUrl) => authApi.patch(`/register-new-validation-mail/${id}`, redirectUrl)

export const postRegisterUserRequest = credentials => authApi.post(`${END_POINT}/register-user`, credentials)

export const postRegisterValidateRequest = token => authApi.patch('/register-validate', token)

// TODO check api/api uri
export const patchApiConfirmUserRequest = (token, message) => globalApi.patch(`/api/confirm-user/${token}`, { message })

// verify location in auth ?
export const patchChangePasswordRequest = credentials => globalApi.post(`${END_POINT}/change-password`, credentials)

export const patchResetPasswordMailRequest = credentials => authApi.patch(`${END_POINT}/reset-password-mail`, credentials)

export const patchResetPasswordRequest = credentials => authApi.patch(`${END_POINT}/reset-password`, credentials)
