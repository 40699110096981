import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faElementor } from '@fortawesome/free-brands-svg-icons'

import {
  fas,
  faComments,
  faUserCog,
  faStore,
  faStoreSlash,
  faCheck,
  faHome,
  faPhone,
  faExchangeAlt,
  faInfoCircle,
  faReceipt,
  faUsersCog,
  faFolderPlus,
  faSuitcaseRolling,
  faQuestion,
  faUserPlus,
  faMap,
  faFlag,
  faFlagCheckered,
  faTachometerAlt,
  faUsers,
  faBookmark,
  faGasPump,
  faThumbsUp,
  faExpandArrowsAlt,
  faPlaneDeparture,
  faPlaneArrival,
  faHelicopter,
  faSuitcase,
  faPlus,
  faMinus,
  faPercent,
  faList,
  faIdCardAlt,
  faFileAlt,
  faFilePdf,
  faRoute,
  faEdit,
  faArrowsAlt,
  faPrint,
  faDownload,
  faUpload,
  faPaperPlane,
  faHeart,
  faSave,
  faPlusCircle,
  faListAlt,
  faFolder,
  faDonate,
  faCopy,
  faCalendarAlt,
  faPlane,
  faExternalLinkAlt,
  faDollyFlatbed,
  faShoppingBag,
  faBan,
  faGlobeEurope,
  faHistory,
  faCogs,
  faEye,
  faEyeSlash,
  faCreditCard,
  faCheckCircle,
  faClock,
  faTimes,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faCog,
  faChevronUp,
  faChevronDown,
  faUserTie,
  faUser,
  faCalendarDay,
  faCalendarPlus,
  faMapMarkedAlt,
  faStream,
  faFileInvoiceDollar,
  faCommentDots,
  faCommentAlt,
  faCalendarCheck,
  faEraser,
  faLanguage,
  faDatabase,
  faPencilAlt,
  faServer,
  faMoneyCheckAlt,
  faHandshake,
  faWrench,
  faRuler,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component(FeatherIcon.name, FeatherIcon)

library.add(
  fas,
  faStore,
  faStoreSlash,
  faCheck,
  faHome,
  faPhone,
  faExchangeAlt,
  faHelicopter,
  faInfoCircle,
  faReceipt,
  faUsersCog,
  faSuitcaseRolling,
  faElementor,
  faFolderPlus,
  faCalendarCheck,
  faCommentDots,
  faCommentAlt,
  faComments,
  faFileInvoiceDollar,
  faStream,
  faMapMarkedAlt,
  faQuestion,
  faCalendarPlus,
  faCalendarDay,
  faUserTie,
  faUserCog,
  faUser,
  faUserPlus,
  faMap,
  faFlag,
  faFlagCheckered,
  faTachometerAlt,
  faInfoCircle,
  faGlobeEurope,
  faUsers,
  faBookmark,
  faGasPump,
  faThumbsUp,
  faExpandArrowsAlt,
  faPlaneDeparture,
  faPlaneArrival,
  faHelicopter,
  faUserCog,
  faSuitcase,
  faPlus,
  faMinus,
  faPercent,
  faList,
  faIdCardAlt,
  faFileAlt,
  faFilePdf,
  faRoute,
  faEdit,
  faArrowsAlt,
  faPrint,
  faDownload,
  faUpload,
  faPaperPlane,
  faHeart,
  faSave,
  faPlusCircle,
  faListAlt,
  faFolder,
  faDonate,
  faCopy,
  faCalendarAlt,
  faPlane,
  faExternalLinkAlt,
  faDollyFlatbed,
  faShoppingBag,
  faBan,
  faGlobeEurope,
  faHistory,
  faCogs,
  faEye,
  faEyeSlash,
  faCreditCard,
  faCheckCircle,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faCog,
  faClock,
  faTimes,
  faChevronUp,
  faChevronDown,
  faMoneyCheckAlt,
  faEraser,
  faLanguage,
  faDatabase,
  faPencilAlt,
  faServer,
  faHandshake,
  faWrench,
  faRuler,
)
